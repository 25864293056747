import { routePaths } from '@/infrastructure/constants';
import { FeatureFlagGuard, FeatureSetGuard, LtDialog, PageContainer } from '@/components';
import withNav from '@/infrastructure/hoc/withNav';
import { useEffect, useState } from 'react';
import {
  deleteSsoConnection,
  getSsoConnection,
  getThemeIdp,
  updateSsoConnection,
} from '@/infrastructure/apis/md/idp';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import teaserImage from '@/views/images/teaser/email-template-teaser.png';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import {
  EntraIdStrategyData,
  OktaStrategyData,
  SAMLStrategyData,
  StrategyName,
  StrategyOptions,
  ThemeDomain,
  ThemeIdentityProvider,
  ThemeSSO,
} from '@/shared/types/api';
import { Box, Divider, Grid, Typography, TextField, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useConnectionSettings } from './components/helpers/hooks';
import { getDomains } from '@/infrastructure/apis/md/customization';
import { isValidOptions } from './components/helpers/utils';
import { useStrategyFields } from './components/helpers/hooks/useStrategyFields';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

const mapOptions = (options, strategy: StrategyName) => {
  switch (strategy) {
    case 'samlp':
      return {
        certificate: options.cert,
        signInUrl: options.signInEndpoint,
        signOutUrl: options.signOutEndpoint,
      } as SAMLStrategyData;
    case 'okta':
      return {
        oktaDomain: options.domain,
        clientID: options.client_id,
        clientSecret: options.client_secret,
      } as OktaStrategyData;
    case 'waad':
      return {
        entraIdDomain: options.domain,
        clientID: options.client_id,
        clientSecret: options.client_secret,
        identityAPI: options.identity_api,
      } as EntraIdStrategyData;
    default:
      return {};
  }
};

const Section = ({ children, title, description = null, ...rest }) => {
  return (
    <Box {...rest}>
      <Typography variant='h2'>{title}</Typography>
      {description && (
        <Typography variant='body1' mt={1.5}>
          {description}
        </Typography>
      )}
      <Box mt={1.5}>{children}</Box>
    </Box>
  );
};

const getGridSm = (index, arr) => {
  return index === arr.length - 1 && arr.length % 2 === 1 ? 12 : 6;
};

const ThemeSSOSettings = () => {
  const { notify: toast } = useLtNotifications();

  const [themeSSO, setThemeSSO] = useState<ThemeSSO>(null);
  const [themeIdp, setThemeIdp] = useState<ThemeIdentityProvider>(null);
  const [domains, setDomains] = useState<ThemeDomain[]>([]);
  const [selectedDomains, setSelectedDomains] = useState<ThemeDomain[]>([]);
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);

  const [strategyOptionsValues, setStrategyOptionsValues] = useState<StrategyOptions>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const { getAccessTokenSilently } = useAuth0();

  const { copyFields } = useConnectionSettings(themeIdp, themeSSO?.strategy);
  const fields = useStrategyFields(
    themeSSO?.strategy,
    strategyOptionsValues,
    setStrategyOptionsValues,
  );
  const canSave = isValidOptions(strategyOptionsValues, 'samlp') && selectedDomains.length > 0;

  useDeferredLoader(!themeSSO || !themeIdp, 'themeSSO');

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteSsoConnection(getAccessTokenSilently, id);
      toast.success(t('idp.sso.deleteSuccess'));
      setDeletePopupOpened(false);
      history.push(routePaths.IDP.THEME_IDP_SETTINGS.replace(':id', id));
    } catch {
      toast.error(t('idp.sso.deleteError'));
    }
    setIsDeleting(false);
  };

  const handleSave = async () => {
    if (canSave) {
      setIsSaving(true);
      try {
        await updateSsoConnection(getAccessTokenSilently, id, {
          options: strategyOptionsValues,
          themeDomainIds: selectedDomains.map(({ id }) => id),
        });
        toast.success(t('idp.sso.updateSuccess'));
      } catch {
        toast.error(t('idp.sso.updateError'));
      }
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (themeSSO) {
      setSelectedDomains(themeSSO.domains);
    }
  }, [themeSSO]);

  useEffect(() => {
    const fetchSso = async () => {
      try {
        const [
          { data: _themeIdp },
          {
            data: { data: _themeSSO },
          },
          { data: _domains },
        ] = await Promise.all([
          getThemeIdp(getAccessTokenSilently, id),
          getSsoConnection(getAccessTokenSilently, id),
          getDomains(getAccessTokenSilently, { onlyVerified: true }),
        ]);

        setThemeSSO(_themeSSO);
        setThemeIdp(_themeIdp);
        setDomains(_domains);
        setStrategyOptionsValues(
          mapOptions(_themeSSO.providerResponse.options, _themeSSO.strategy),
        );
      } catch (error) {
        toast.error(t('error.general'), { id: 'themeSSO' });
        console.log(error);
      }
    };

    fetchSso();
  }, [getAccessTokenSilently, id, t, toast]);

  if (!themeSSO || !themeIdp) return null;

  return (
    <PageContainer
      bottomActionBar={{
        saveAction: {
          onClick: handleSave,
          loading: isSaving,
          disabled: !canSave,
        },
        deleteAction: {
          onClick: () => setDeletePopupOpened(true),
          text: t('idp.deleteConfiguration'),
        },
      }}
      helpSidebarConfigKey='help-sidebar-sso-settings'
    >
      <Typography variant='h1' textTransform={'none'}>
        {t('idp.sso.pageTitle')}
      </Typography>
      <Divider sx={{ mt: 1.5 }} />
      <Section
        mt={2.2}
        title={t('idp.sso.copySection.title')}
        // description={t('idp.sso.copySection.description')}
      >
        <Grid container spacing={1.5}>
          {copyFields.map((field, index) => (
            <Grid item xs={12} sm={getGridSm(index, copyFields)}>
              {field}
            </Grid>
          ))}
        </Grid>
      </Section>
      <Section
        mt={3}
        title={t('idp.sso.editSection.title')}
        // description={t('idp.sso.editSection.description')}
      >
        <Grid container spacing={1.5} mt={0.5}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={getGridSm(index, fields)} key={field.key}>
              {field}
            </Grid>
          ))}
        </Grid>
      </Section>
      <Section
        mt={3}
        title={t('idp.sso.domainSection.title')}
        // description={t('idp.sso.domainSection.description')}
      >
        <Autocomplete
          filterSelectedOptions
          multiple
          options={domains}
          getOptionLabel={(option: ThemeDomain) => option.domain}
          onChange={(event, newValue: ThemeDomain[]) => setSelectedDomains(newValue)}
          value={selectedDomains}
          renderInput={params => (
            <TextField {...params} label={t('idp.sso.selectDomains')} placeholder='example.com' />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Section>
      <LtDialog
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        title={t('requestDelete')}
        onDelete={handleDelete}
        loading={isDeleting}
      >
        <Typography>{t('idp.sso.deleteConfigurationConfirmation')}</Typography>
      </LtDialog>
    </PageContainer>
  );
};

export default withNav(
  () => (
    <FeatureFlagGuard privateFlags={['iam']}>
      <FeatureSetGuard feature='sso' text='upgradeTeaser.feature.idp' image={teaserImage}>
        <ThemeSSOSettings />
      </FeatureSetGuard>
    </FeatureFlagGuard>
  ),
  {
    tTitle: 'idp.sso.pageTitle',
    showBackButton: {
      linkTo: null,
    },
  },
  {
    activeScreen: routePaths.IDP.THEME_SSO_SETTINGS,
  },
);
