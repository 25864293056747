import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { ActionPreferences } from '../../shared/types/api';
import { getAuth0TokenScopeString } from '@/util';
import { withSilentAccessToken } from '../helper';
import { PERMISSIONS } from '../constants';
import { VCardPurpose } from '@/shared/business-logic/features/vcard/types';
import { BeprintAvailability } from '@/shared/business-logic/integrations/beprint/types';

export const checkDomain = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(config.API_BASE_URL + `account/checkDomain`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const getOwnAccount = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.get(config.API_BASE_URL + 'account', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getVcard = async (
  accountId: string,
  lang: string,
  purpose: VCardPurpose = 'plain',
) => {
  return await axios.get(config.API_BASE_URL + 'account/download/contact', {
    params: {
      accountId,
      lang,
      purpose,
      response: 'string',
    },
  });
};

export const postActionPreference = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  partialPrefs: Partial<ActionPreferences>,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  const response = await axios.post(
    `${config.API_BASE_URL}account/action-preferences`,
    {
      actionPreferences: partialPrefs,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response;
};

export const getWalletPass = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  qrType: 'online' | 'offline',
  wallet: 'apple' | 'google',
  uidForProfileLink?: number,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.get(
    config.API_BASE_URL +
      `account/pass/${wallet}?qrType=${qrType}` +
      (uidForProfileLink ? `&uid=${uidForProfileLink}` : ''),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // for `apple` wallet, we get a buffer response
      // for `google` wallet, we get a url string response
      ...(wallet === 'apple' && { responseType: 'blob' }),
    },
  );
};

export const deleteAccount = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  email,
) => {
  const token = await getAccessTokenSilently({
    audience: config.API_BASE_URL,
    scope: getAuth0TokenScopeString(),
  });

  return await axios.post(
    config.API_BASE_URL + `account/delete`,
    { authEmail: email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getAccountCodes = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + 'account/codes', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),

    [PERMISSIONS.READ.THEME_PROFILES],
  );
  return data;
};

export const deleteAccountCode = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  code: string,
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.delete(config.API_BASE_URL + `account/codes/${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
  if (!data.isSuccess) {
    throw new Error('Failed to delete account code');
  }
};

export const connectCode = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  code: string,
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + 'account/addcode',
        { code },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      ),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );

  if (!data.isSuccess) {
    throw new Error('Failed to connect code');
  }
  return data;
};

export const getBlockedFields = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { data } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(config.API_BASE_URL + 'theme/blocked-bio-fields', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
  if (!data.isSuccess) {
    throw new Error('Failed to fetch blocked fields');
  }
  return data;
};

export const getScimBlockedFields = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(`${config.API_BASE_URL}idps/scim/blockedFields`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
};

export const getBeprintSsoUrl = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  variant: 'product' | 'approval',
  accountId?: string, // only relevant if admin logs in as different user
) => {
  return await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get<{ data: { ssoUrl: string; ssoError: null | undefined | string } }>(
      `${config.API_BASE_URL}account/beprint-login`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { variant, accountId },
      },
    ),
  );
};

export const getIsBeprintAvailable = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
): Promise<BeprintAvailability> => {
  try {
    const res = await withSilentAccessToken(getAccessTokenSilently, token =>
      axios.get<{
        data: BeprintAvailability;
      }>(`${config.API_BASE_URL}account/beprint-available`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    );
    return res?.data?.data;
  } catch {
    return {
      isAvailable: false,
      isUserOrderAvailable: false,
      isAdminOrderAvailable: false,
      isApprovalAvailable: false,
    };
  }
};
