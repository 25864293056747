import { ActionType as Action } from '@/components/MenuButton';
import { TFunction } from 'i18next';
import {
  AccountBalanceWalletOutlined,
  FilePresentOutlined,
  Link,
  PhoneAndroidOutlined,
  QrCode2Outlined,
  VideoCameraFrontOutlined,
} from '@mui/icons-material';

interface Handlers {
  downloadEmployees: (type: string) => void;
  downloadProfileLinks: () => void;
  downloadQRCodes: (type: 'online' | 'offline') => void;
  downloadQRBg: (type: 'mobile' | 'videocall') => void;
  sendWalletEmails: (selectedIds: readonly string[]) => void;
}

export const DownloadableActions = (
  numSelected: number,
  selectedIds: readonly string[],
  handlers: Handlers,
  t: TFunction,
) => {
  const { downloadQRCodes, downloadQRBg, downloadEmployees, downloadProfileLinks } = handlers;

  // const exceededProfiles = () => toast.error(t('error.errorDownloadQr'));

  return [
    {
      icon: <QrCode2Outlined />,
      actionText: t('onlineQrCodes'),
      tooltipText: t('actionTooltips.onlineQrCodes'),
      onClick: () => downloadQRCodes('online'),
    },
    {
      icon: <QrCode2Outlined />,
      actionText: t('offlineQrCodes'),
      tooltipText: t('actionTooltips.offlineQrCodes'),
      onClick: () => downloadQRCodes('offline'),
    },
    {
      icon: <FilePresentOutlined />,
      actionText: t('employeesCsv'),
      tooltipText: t('actionTooltips.csv'),
      onClick: () => downloadEmployees('csv'),
    },
    {
      icon: <FilePresentOutlined />,
      actionText: t('employeesXlsx'),
      tooltipText: t('actionTooltips.xlsx'),
      onClick: () => downloadEmployees('xlsx'),
    },
    {
      icon: <Link />,
      actionText: t('profileLinks'),
      tooltipText: t('actionTooltips.links'),
      onClick: () => downloadProfileLinks(),
    },
    {
      icon: <PhoneAndroidOutlined />,
      actionText: t('mobileBg'),
      tooltipText: t('actionTooltips.mobileBg'),
      // onClick: () => (numSelected <= 10 ? downloadQRBg('mobile') : exceededProfiles()),
      onClick: () => downloadQRBg('mobile'),
    },
    {
      icon: <VideoCameraFrontOutlined />,
      actionText: t('videoBg'),
      tooltipText: t('actionTooltips.videoBg'),
      // onClick: () => (numSelected <= 10 ? downloadQRBg('videocall') : exceededProfiles()),
      onClick: () => downloadQRBg('videocall'),
    },
  ] as Action[];
};

export const EmailActions = (
  numSelected: number,
  selectedIds: readonly string[],
  handlers: Handlers,
  t: TFunction,
) => {
  const { sendWalletEmails } = handlers;

  // const exceededProfiles = () => toast.error(t('error.errorDownloadQr'));

  return [
    {
      icon: <AccountBalanceWalletOutlined />,
      actionText: 'Wallet',
      tooltipText: t('bulkActions.distribute.wallet.tooltip'),
      onClick: () => sendWalletEmails(selectedIds),
    },
  ] as Action[];
};
