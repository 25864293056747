import { useUDT } from '@/infrastructure/hooks/useUDT';
import { LEAD_CUSTOM_FIELDS } from '@/shared/constants';
import { LeadCustomFieldValueAPI, LeadCustomFieldAPI } from '@/shared/types/api';
import { LeadCustomFieldValueType } from '@/shared/types/global';
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  InputLabel,
  FormLabel,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import useId from '@mui/material/utils/useId';
import { useTranslation } from 'react-i18next';

const INPUT_LABELED_FIELD_TYPES = [
  LEAD_CUSTOM_FIELDS.TEXT,
  LEAD_CUSTOM_FIELDS.TEXTAREA,
  LEAD_CUSTOM_FIELDS.DROPDOWN,
];

type SharedProperties = {
  label?: string;
  labelId?: string;
};

type TextProps = SharedProperties & {
  onChange: (value: string) => void;
  value: string;
  meta: string;
};

const RenderText = ({ onChange, value, meta, label, labelId }: TextProps) => {
  return (
    <OutlinedInput
      label={label}
      fullWidth
      placeholder={meta}
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        'aria-labelledby': labelId,
      }}
    />
  );
};

const RenderTextarea = ({ onChange, value, meta, label, labelId }: TextProps) => {
  return (
    <OutlinedInput
      label={label}
      fullWidth
      placeholder={meta}
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
      multiline
      rows={4}
      inputProps={{
        'aria-labelledby': labelId,
      }}
    />
  );
};

type DropdownProps = SharedProperties & {
  onChange: (value: string | number) => void;
  value: number;
  meta: { options: Array<{ id: number; title: string }> };
};

const RenderDropDown = ({ onChange, value, meta, label, labelId }: DropdownProps) => {
  const { options = [] } = meta || {};

  return (
    <Select
      labelId={labelId}
      label={label}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    >
      {options.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.title}
        </MenuItem>
      ))}
    </Select>
  );
};

type RadioProps = SharedProperties & {
  onChange: (value: string) => void;
  value: number;
  meta: { options: Array<{ id: number; title: string }> };
};

const RenderRadio = ({ onChange, value, meta }: RadioProps) => {
  const { options = [] } = meta || {};

  const handleChange = (val: string) => onChange(val);

  return (
    <RadioGroup value={value} onChange={({ target: { value } }) => handleChange(value)}>
      {options.map(option => (
        <FormControlLabel value={option.id} label={option.title} control={<Radio />} />
      ))}
    </RadioGroup>
  );
};

type CheckboxProps = SharedProperties & {
  onChange: (value: number[]) => void;
  value: number[];
  meta: { options: Array<{ id: number; title: string }> };
};

const RenderCheckbox = ({ onChange, value, meta }: CheckboxProps) => {
  const { options = [] } = meta || {};

  const handleChange = option => {
    const currentValues = value || [];
    if (currentValues.includes(option.id)) {
      onChange(currentValues.filter(val => val !== option.id));
    } else {
      onChange([...currentValues, option.id]);
    }
  };

  return (
    <FormGroup>
      {options.map(option => (
        <FormControlLabel
          label={option.title}
          control={
            <Checkbox
              checked={(value || []).includes(option.id)}
              onChange={() => handleChange(option)}
            />
          }
        />
      ))}
    </FormGroup>
  );
};

type SingleCustomFieldRendererProps = {
  fieldType: LeadCustomFieldAPI;
  onChange: (value: LeadCustomFieldValueType, optionTypeId: number) => void;
  value: LeadCustomFieldValueType;
  required?: boolean;
  errorMessage?: string;
};
export const SingleCustomFieldRenderer = ({
  fieldType,
  onChange,
  value,
  required,
  errorMessage,
}: SingleCustomFieldRendererProps) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { fallbackLanguage } = useUDT();

  /**Find Label */
  const currentLanguageLabel = currentLanguage ? fieldType.translatedName?.[currentLanguage] : null;
  const fallbackLanguageLabel = fallbackLanguage
    ? fieldType.translatedName?.[fallbackLanguage]
    : null;

  const label = currentLanguageLabel || fallbackLanguageLabel || fieldType.name;

  /**Find meta */
  const currentLanguageMeta = fieldType.translatedMeta?.[currentLanguage];
  const fallbackLanguageMeta = fallbackLanguage
    ? fieldType.translatedMeta?.[fallbackLanguage]
    : null;

  const meta = currentLanguageMeta || fallbackLanguageMeta || fieldType.meta;

  let Component = null;
  switch (fieldType.type) {
    case LEAD_CUSTOM_FIELDS.TEXT:
      Component = RenderText;
      break;
    case LEAD_CUSTOM_FIELDS.TEXTAREA:
      Component = RenderTextarea;
      break;
    case LEAD_CUSTOM_FIELDS.DROPDOWN:
      Component = RenderDropDown;
      break;
    case LEAD_CUSTOM_FIELDS.CHECKBOX:
      Component = RenderCheckbox;
      break;
    case LEAD_CUSTOM_FIELDS.RADIO:
      Component = RenderRadio;
      break;
  }

  const labelId = useId();

  return Component ? (
    <FormControl fullWidth aria-describedby={labelId} required={required} error={!!errorMessage}>
      {INPUT_LABELED_FIELD_TYPES.some(x => x === fieldType.type) ? (
        <InputLabel id={labelId}>{label}</InputLabel>
      ) : (
        <FormLabel id={labelId}>{label}</FormLabel>
      )}
      <Component
        meta={meta}
        value={value}
        onChange={value => onChange(value, fieldType.id)}
        label={label}
        required={required}
        errorMessage={errorMessage}
        labelId={labelId}
      />
      {errorMessage && <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>}
    </FormControl>
  ) : null;
};

type Props = {
  leadCustomFields: Array<LeadCustomFieldAPI>;
  leadCustomFieldValues: Array<LeadCustomFieldValueAPI>;
  onChange: (value: Array<LeadCustomFieldValueAPI>) => void;
};

export const LeadCustomFieldsRenderer = ({
  leadCustomFields,
  onChange,
  leadCustomFieldValues,
}: Props) => {
  const handleChange = (value: LeadCustomFieldValueType, optionTypeId: number) => {
    const currentFieldValues = leadCustomFieldValues || [];
    const currentFieldValue = currentFieldValues.find(item => item.typeId === optionTypeId);

    if (currentFieldValue) {
      onChange(
        currentFieldValues.map(item =>
          item.typeId === optionTypeId ? { typeId: optionTypeId, value: value, id: item.id } : item,
        ),
      );
      return;
    } else {
      onChange([...currentFieldValues, { typeId: optionTypeId, value: value, id: null }]);
      return;
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={2} mb={2}>
      {leadCustomFields?.map(fieldType => (
        <SingleCustomFieldRenderer
          key={fieldType.id}
          value={
            leadCustomFieldValues?.find(option => {
              return option.typeId === fieldType.id;
            })?.value || null
          }
          onChange={handleChange}
          fieldType={fieldType}
        />
      ))}
    </Box>
  );
};
