import { dispatchTypes } from '../actions';

let initialState = {
  isLoading: false,
  errorMsg: undefined,
  apiResponse: {},
  lead: {},
  deleteResponse: {},
};

const leadGenReducer = (state = initialState, action) => {
  switch (action.type) {
    case dispatchTypes.LEAD_GEN.LOADING:
      return { ...state, isLoading: true };

    case dispatchTypes.LEAD_GEN.ADD_LEAD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiResponse: action.apiResponse,
      };
    case dispatchTypes.LEAD_GEN.ADD_LEAD.ERROR:
      return {
        ...state,
        isLoading: false,
        apiResponse: action.apiResponse,
      };
    case dispatchTypes.LEAD_GEN.EDIT_LEAD.SUCCESS:
      return {
        ...state,
        isLoading: false,
        apiResponse: action.apiResponse,
      };
    case dispatchTypes.LEAD_GEN.EDIT_LEAD.ERROR:
      return {
        ...state,
        isLoading: false,
        apiResponse: action.apiResponse,
      };
    case dispatchTypes.LEAD_GEN.DELETE_LEAD.INIT:
      return { ...state, isLoading: true };
    case dispatchTypes.LEAD_GEN.DELETE_LEAD.SUCCESS:
      return { ...state, isLoading: false, deleteResponse: action.deleteResponse };
    case dispatchTypes.LEAD_GEN.DELETE_LEAD.ERROR:
      return { ...state, isLoading: false, deleteResponse: action.deleteResponse };
    case dispatchTypes.LEAD_GEN.RESET_SINGLE_LEAD:
      return {
        ...state,
        lead: {},
      };
    case dispatchTypes.LEAD_GEN.RESET:
      return {
        isLoading: false,
        errorMsg: undefined,
        apiResponse: {},
        lead: {},
        deleteResponse: {},
        leadCustomFields: [],
      };
    default:
      return state;
  }
};

export default leadGenReducer;
