import { Box, Button, Typography } from '@mui/material';
import { VIDEOS } from '../../../../../infrastructure/constants';
import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { usePublicFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import useUpgradeAction from '@/infrastructure/hooks/useUpgradeAction';
import { ArrowCircleUp } from '@mui/icons-material';
import LtVideo from '@/components/LtVideo';

const VideoSectionExpired = () => {
  const { t, activeLanguage } = useAppTranslation();
  const { src } = activeLanguage.startsWith('de') ? VIDEOS.MD.DE : VIDEOS.MD.EN;
  const { upgradeAction, upgradeRequestPopup } = useUpgradeAction();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');
  return (
    <Box py={3}>
      <Box display={'flex'} justifyContent='space-evenly' alignItems={'center'}>
        <Box>
          <LtVideo embedUrl={src} sx={{ minWidth: '40rem', height: '23rem', maxWidth: '100%' }} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography variant='h1'>{t('teaser.expired')}</Typography>

          <Box>
            <Button startIcon={<ArrowCircleUp />} onClick={upgradeAction} size='large'>
              {flag_hidePrices ? t('contactSales') : t('upgradeNow')}
            </Button>
          </Box>
          {upgradeRequestPopup}
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSectionExpired;
