import { LEAD_DEFAULT_FIELDS } from '@/shared/constants';
import { LeadCustomFieldAPI, ThemeLanguage } from '@/shared/types/api';
import { ContactFormField, InternalContactFormField } from '@/shared/types/global';
import { getContactFormTranslationKeyByField as _getContactFormTranslationKeyByField } from '@/shared/util';
import { TFunction } from 'i18next';

import { PROFILE_PAGE_LABELS } from '@/shared/constants/system-labels/profile-page';
import { SCANNABLE_FIELDS } from '@/views/pages/lead-gen/BusinessCardScanner/helpers';
import { TranslatableField } from './types';

/**Wrap original function to override `businessCardUrls` title */
const getContactFormTranslationKeyByField = (field: string) => {
  if (field === 'businessCardUrls') {
    return 'contactFormEdit.businessCardUrls';
  }
  return _getContactFormTranslationKeyByField(field);
};

const EDITABLE_SYSTEM_FIELDS = Object.keys(PROFILE_PAGE_LABELS.attributeLabels);
export const isSystemFieldEditable = (field: string) =>
  EDITABLE_SYSTEM_FIELDS.some(x => x === field);

export const isScannableField = (field: string) => SCANNABLE_FIELDS.some(x => x === field);

export const getCustomFieldLabel = (
  field: InternalContactFormField | ContactFormField,
  leadCustomFields: LeadCustomFieldAPI[],
  lang: string = 'en',
  fallbackLang?: string,
) => {
  if (!field.isCustom) {
    return '';
  }
  const customField = leadCustomFields.find(x => x.id === field.name);
  if (!customField) return '';
  return (
    customField.translatedName?.[lang] ||
    (fallbackLang && customField.translatedName?.[fallbackLang]) ||
    customField.name
  );
};

export const getFieldLabel = (
  field: InternalContactFormField | ContactFormField,
  leadCustomFields: LeadCustomFieldAPI[],
  t: TFunction,
  systemLabels: Record<string, Record<string, string>> = {},
  lang: string = 'en',
  fallbackLanguage?: string,
) => {
  if (field.isCustom) {
    return getCustomFieldLabel(field, leadCustomFields, lang, fallbackLanguage);
  }
  const systemLabel = systemLabels?.[`attributeLabels.${field.name}`];
  return (
    systemLabel?.[lang] ||
    systemLabel?.[fallbackLanguage] ||
    t(getContactFormTranslationKeyByField(field.name as string))
  );
};

export const getShownAndNotShownFields = (
  fields: InternalContactFormField[],
  leadCustomFields: LeadCustomFieldAPI[],
) => {
  const shownCustomFields = fields.filter(x => x.isCustom);

  const notShownCustomFields = leadCustomFields.filter(
    x => !shownCustomFields.some(y => y.name === x.id),
  );
  const mappedNotShownFields = LEAD_DEFAULT_FIELDS.filter(x => !fields.some(y => y.name === x)).map(
    x => ({
      name: x,
    }),
  );

  const mappedNotShownCustomFields = notShownCustomFields.map(x => ({
    name: x.id,
    isCustom: true,
  }));
  return {
    shown: fields,
    notShown: [...mappedNotShownFields, ...mappedNotShownCustomFields],
  };
};

export const getTitlesMapFromTitlesArr = (titles: TranslatableField[]) => {
  return titles.reduce((acc, curr) => {
    return { ...acc, [curr.lang]: curr.value };
  }, {});
};

export const getTranslatableInitialValues = (languages: ThemeLanguage[]) => {
  return languages.map(x => ({ lang: x.lang, value: '' }));
};
