import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DragStart,
  DragUpdate,
} from 'react-beautiful-dnd';
import { Box, Stack, styled } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { useTranslation } from 'react-i18next';
import { DragIndicator } from '@mui/icons-material';
import { flexCenterProps } from '@/util';

type Props<T> = {
  onEnd?: (items: T[], oldIndex: number, newIndex: number) => void;
  items: T[];
  getItemKey: (item: T, index: number) => string | number;
  renderer: (item: T, index: number) => JSX.Element;
  getItemAriaLabel: (item: T, index: number) => string;
  dense?: boolean;
  useFullAreaForDrag?: boolean;
  divider?: JSX.Element;
};

export function LtSorter<T extends object>({
  onEnd,
  items,
  getItemKey,
  renderer,
  dense,
  getItemAriaLabel,
  useFullAreaForDrag,
  divider,
}: Props<T>) {
  const { t } = useTranslation();
  const handler = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;

    const coppiedArray = [...items];
    const [removed] = coppiedArray.splice(source.index, 1);
    coppiedArray.splice(destination.index, 0, removed);

    if (result.source && result.destination) {
      provided.announce(
        t('ariaDnd.onDragEnd', {
          itemName: getItemAriaLabel(items[result.source.index], result.source.index),
          fromPosition: result.source.index + 1,
          toPosition: result.destination.index + 1,
        }),
      );
    }
    onEnd(coppiedArray, source.index, destination.index);
  };

  const announceDragStart = (start: DragStart, provided: ResponderProvided) => {
    if (start.source) {
      provided.announce(
        t('ariaDnd.onDragStart', {
          itemName: getItemAriaLabel(items[start.source.index], start.source.index),
          position: start.source.index + 1,
        }),
      );
    }
  };
  const announceDragUpdate = (update: DragUpdate, provided: ResponderProvided) => {
    if (update.source && update.destination) {
      provided.announce(
        t('ariaDnd.onDragUpdate', {
          itemName: getItemAriaLabel(items[update.source.index], update.source.index),
          fromPosition: update.source.index + 1,
          toPosition: update.destination.index + 1,
        }),
      );
    }
  };

  const dragDescriptionId = useId();

  return (
    <>
      <DragDropContext
        onDragStart={announceDragStart}
        onDragUpdate={announceDragUpdate}
        onDragEnd={handler}
      >
        <Droppable droppableId='items'>
          {provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {items?.map((item: T, index: number) => (
                <>
                  {index > 0 && divider}
                  <Draggable
                    key={getItemKey(item, index)}
                    draggableId={getItemKey(item, index).toString()}
                    index={index}
                  >
                    {(provided, _) => (
                      <ItemWrapper
                        component='li'
                        sx={!dense && { pb: 2 }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...(useFullAreaForDrag
                          ? {
                              ...provided.dragHandleProps,
                              'aria-roledescription': t('ariaDnd.draggableItem'),
                              'aria-describedby': dragDescriptionId,
                              role: 'button',
                            }
                          : {})}
                      >
                        {useFullAreaForDrag ? (
                          <div>{renderer(item, index)}</div>
                        ) : (
                          <Stack
                            direction='row'
                            alignItems='center'
                            spacing={{ xs: 0.25, md: 0.5 }}
                          >
                            <Box
                              {...flexCenterProps}
                              {...provided.dragHandleProps}
                              sx={{ '&:focus-visible': { outline: '3px solid currentColor' } }}
                              aria-label={
                                getItemAriaLabel(item, index) + ' - ' + t('ariaDnd.draggableItem')
                              }
                              aria-describedby={dragDescriptionId}
                            >
                              <DragIndicator />
                            </Box>
                            <Box flex={1}>{renderer(item, index)}</Box>
                          </Stack>
                        )}
                      </ItemWrapper>
                    )}
                  </Draggable>
                </>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
        <div id={dragDescriptionId} style={{ display: 'none' }}>
          {t('ariaDnd.draggableDescription')}
        </div>
      </DragDropContext>
    </>
  );
}

export default LtSorter;

const ItemWrapper: typeof Box = styled(Box)(({ theme }) => ({
  '&:focus-visible': {
    '> div': {
      outline: `3px solid ${theme.palette.primary.main}`,
    },
  },
}));
