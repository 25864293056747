import { toOnlyNumbersAndSpacesAndDashes } from '@/infrastructure/helper';
import { LeadAPI, LeadCustomFieldAPI, StaticProfileConfig } from '@/shared/types/api';
import { LeadCustomFieldValueType, ThemeBoxStyle } from '@/shared/types/global';
import { getContactFormTranslationKeyByField, validateEmail } from '@/shared/util';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ThemeOverride from './ThemeOverride';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import ProfileThemeProvider from './ProfileThemeProvider';
import LtLoadingButton from './LtLoadingButton';
import { SingleCustomFieldRenderer } from './LeadGen/LeadForm/components/LeadCustomFieldsRenderer';

type Props = {
  onSubmit?: (values: Partial<LeadAPI>) => Promise<boolean>;
  boxStyle?: ThemeBoxStyle;
  leadCustomFields: LeadCustomFieldAPI[];
} & Omit<StaticProfileConfig['leadGen'], 'flow'>;

export const ContactForm = ({
  fields,
  labels,
  privacyPolicyUrl,
  submitButtonColor,
  boxStyle,
  onSubmit,
  leadCustomFields,
}: Props) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<Partial<LeadAPI>>({});
  const [acknowledged, setAcknowledged] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Partial<Record<keyof LeadAPI | 'acknowledgement', string>>
  >({});

  const updateValidations = useCallback(
    (
      updatedValues: null | typeof values = null,
      acknowledgedLocal: null | boolean = null,
      forceAll = false,
    ) => {
      const valuesToUse = updatedValues ?? values;
      const acknowledgedToUse = acknowledgedLocal ?? acknowledged;
      let hasValidationErrors = false;
      setValidationErrors(prevValidationErrors => {
        const fieldsToIterate = forceAll
          ? fields.map(f => f.name)
          : Object.keys(prevValidationErrors);
        const updatedValidationErrors = {};
        for (const field of fieldsToIterate) {
          // required fields validations
          const isRequired = fields.find(f => f.name === field)?.required;
          if (isRequired && !valuesToUse[field]?.trim()) {
            updatedValidationErrors[field] = t('error.requiredFieldGeneral');
          }

          // field format validations
          switch (field) {
            case 'email':
            case 'workEmail':
              if (valuesToUse[field] && !validateEmail(valuesToUse[field])) {
                updatedValidationErrors[field] = t('error.invalidFieldGeneral');
              }
              break;
            case 'postCode':
            case 'mobilePhone':
            case 'workPhone':
              if (valuesToUse[field] && typeof Number(valuesToUse[field]) !== 'number') {
                updatedValidationErrors[field] = t('error.invalidFieldGeneral');
              }
              break;
          }
        }

        // acknowledgement is required
        if ((forceAll || prevValidationErrors['acknowledgement']) && !acknowledgedToUse) {
          updatedValidationErrors['acknowledgement'] = t('error.requiredFieldGeneral');
        }

        hasValidationErrors = Object.values(updatedValidationErrors).some(Boolean);
        return updatedValidationErrors;
      });
      return hasValidationErrors;
    },
    [acknowledged, fields, t, values],
  );

  const handleInputChange = (key: keyof LeadAPI, value: string) => {
    let val = value;
    if (key === 'workPhone' || key === 'mobilePhone') {
      val = toOnlyNumbersAndSpacesAndDashes(value);
    }

    const updatedValues = { ...values, [key]: val };
    setValues(updatedValues);
    updateValidations(updatedValues);
  };

  const handleSubmit = useCallback(async () => {
    if (!onSubmit) return;

    const hasValidationErrors = updateValidations(values, acknowledged, true);

    if (Object.values(values).every(val => !val) || hasValidationErrors) {
      return;
    }

    setSubmitLoading(true);
    const success = await onSubmit(values);
    if (success) {
      setValues({});
      setAcknowledged(false);
      setValidationErrors({});
    }
    setSubmitLoading(false);
  }, [acknowledged, onSubmit, updateValidations, values]);

  const handleCustomFieldChange = (value: LeadCustomFieldValueType, typeId: number) => {
    const currentFieldValues = values.leadCustomFieldValues || [];
    const currentFieldValue = currentFieldValues.find(item => item.typeId === typeId);
    let newValue = null;
    if (currentFieldValue) {
      newValue = currentFieldValues.map(item =>
        item.typeId === typeId ? { typeId, value: value, id: item.id } : item,
      );
    } else {
      newValue = [...currentFieldValues, { typeId, value: value, id: null }];
    }
    setValues({ ...values, leadCustomFieldValues: newValue });
  };

  const { theme: baseTheme } = useMuiTheme();
  const submitButtonColorTheme = useMemo(
    () => ({
      palette: {
        primaryButton: baseTheme.palette.augmentColor({
          color: { main: submitButtonColor },
        }),
      },
    }),
    [baseTheme.palette, submitButtonColor],
  );

  const metaTheme = useMemo(() => ({ boxStyle }), [boxStyle]);

  return (
    <ProfileThemeProvider metaTheme={metaTheme}>
      <Box>
        <Typography variant='h4'>{labels.title}</Typography>
        <Box display='flex' flexDirection='column' mt={2}>
          {fields.map(({ name: _name, required, isCustom }) => (
            <Box key={_name} mb={1.6}>
              {(() => {
                if (isCustom) {
                  const fieldType = leadCustomFields.find(field => field.id === _name);
                  if (!fieldType) return null;
                  return (
                    <SingleCustomFieldRenderer
                      required={required}
                      key={fieldType.id}
                      value={
                        values.leadCustomFieldValues?.find(option => {
                          return option.typeId === fieldType.id;
                        })?.value || null
                      }
                      onChange={handleCustomFieldChange}
                      fieldType={fieldType}
                      errorMessage={validationErrors[fieldType.id]}
                    />
                  );
                }

                const name = _name as keyof LeadAPI;
                const isDate = name === 'birthday';
                const isMultiline = name === 'notes';
                const commonProps = {
                  key: name,
                  label: `${labels.field[name] || t(getContactFormTranslationKeyByField(name))}`,
                  fullWidth: true,
                  required,
                };
                if (isDate) {
                  return (
                    <DatePicker
                      {...commonProps}
                      value={values[name] || null}
                      inputFormat='DD.MM.YYYY'
                      onChange={newVal => handleInputChange(name, newVal)}
                      renderInput={inputProps => (
                        <TextField
                          {...inputProps}
                          error={!!validationErrors[name]}
                          helperText={validationErrors[name]}
                        />
                      )}
                    />
                  );
                }
                return (
                  <TextField
                    {...commonProps}
                    value={values[name] || ''}
                    onChange={e => handleInputChange(name, e.target.value)}
                    multiline={isMultiline}
                    rows={isMultiline ? 4 : 1}
                    error={!!validationErrors[name]}
                    helperText={validationErrors[name]}
                  />
                );
              })()}
            </Box>
          ))}
        </Box>
        <Box position='relative' mt={2}>
          <FormControl required error={!!validationErrors['acknowledgement']}>
            <FormControlLabel
              sx={{ textIndent: '2.5rem', margin: '0', position: 'relative' }}
              control={
                <Checkbox
                  checked={acknowledged}
                  onChange={() => {
                    setAcknowledged(!acknowledged);
                    updateValidations(values, !acknowledged);
                  }}
                  sx={{ position: 'absolute', top: '-1.2rem', left: '-1.2rem' }}
                  disableRipple
                  color='secondary'
                  aria-label={t('ariaGdprConsent')}
                  required
                />
              }
              label={
                <Typography variant='body2' color='secondary'>
                  <Trans
                    i18nKey={labels.gdprConsent}
                    components={[
                      privacyPolicyUrl ? (
                        <Link href={privacyPolicyUrl} target='_blank' rel='noreferrer' />
                      ) : (
                        <span style={{}} />
                      ),
                    ]}
                  />
                </Typography>
              }
            />
            {validationErrors['acknowledgement'] && (
              <FormHelperText>{t('consentToData')}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box width='60%' m='auto' mt={2.5}>
          <ThemeOverride overrides={submitButtonColorTheme}>
            <LtLoadingButton fullWidth onClick={handleSubmit} loading={submitLoading}>
              {labels.submitButton}
            </LtLoadingButton>
          </ThemeOverride>
        </Box>
      </Box>
    </ProfileThemeProvider>
  );
};
