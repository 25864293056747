import { useAppTranslation } from '../../../../../infrastructure/hooks/useAppTranslation';
import { Box, Button } from '@mui/material';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { upgradeLink } from '@/infrastructure/constants';

const FullFeatureComparisonSection = () => {
  const { t } = useAppTranslation();

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Button
        variant='text'
        startIcon={<ArrowForwardOutlined />}
        target='_blank'
        href={upgradeLink}
      >
        {t('teaser.comparison')}
      </Button>
    </Box>
  );
};

export default FullFeatureComparisonSection;
