import {
  ThemeBoxStyle,
  ThemeContactExchangeFlow,
  ThemeFileEditMode,
  ThemeLinkEditMode,
  GlobalDataPosition,
} from '../global';
import { Account, AccountImageState } from './account.type';
import { LeadAPI, LeadCustomFieldValueAPI } from './lead.type';
import { Link } from './link.type';
import { ApiFile } from './file.type';
import { EmailTemplatesEnum, THEME_CONFIG_KEYS } from '../../constants';
import { StrategyName, StrategyOptions } from './sso.type';

export enum HierarchyLevel {
  Unit = 'unit',
  Theme = 'theme',
  ThemeDefault = 'theme-default',
  Fallback = 'fallback',
}

export interface Theme {
  id: number;
  uuid: string;
  name: string;
  iconUrl?: string | null;
  themeLinkTypes: any;
  font?: FontType | null;
  domain: string;
  files?: Array<ApiFile>;
  links?: Array<Link>;
  qrBg?: {
    images: {
      mobile: { default?: string; additional?: string[] };
      videocall: { default?: string; additional?: string[] };
    };
  };
  themeInternal?: ThemeInternal;
  identityProviders: ThemeIdentityProvider[];
  domains: ThemeDomain[];
  featureSet?: FeatureSet;
}

export type FontType = {
  url: string;
  name: string;
};

export type ThemeInternal = {
  logoWideUrl?: string | null;
  logoWideInvert?: boolean;
  logoWideBrightUrl?: string | null;
  primaryColor?: string | null;
  sidebarColor: string;
  sidebarColorText?: string | null;
  sidebarColorActive?: string | null;
  sidebarColorActiveText?: string | null;
  niceName: string;
  lt2ltInternal?: boolean;
  uniqueId: string | null;
  onlySingle?: boolean;
  authConnection: string;
  tier: string;
  amountLicences?: number;
  csvImporterLicence?: string | null;
  csvUpdaterLicence?: string | null;
  csvDeleterLicence?: string | null;
  trialEndDate?: any; //TODO: remove any
  trialStatus?: string;
  mainAccountId?: string | null;
  mainAccount?: Account | null;
  hideNfcCardUI: boolean;
  ssoDomains?: string[] | null;
  multiUnitsAllowed: boolean;
  languages: Array<ThemeLanguage>;
  contactPersons?: ContactPerson[] | null;
  mdGuideState: { checked: string[] };
};

export type ThemeLanguage = {
  lang: string;
  isInput: boolean;
  isFallback: boolean;
};

export type FeatureSet = {
  theme?: Theme;
  multiUser: boolean;
  leadGenIndividual: boolean;
  leadGenMd: boolean;
  leadGenFormConfig: boolean;
  units: boolean;
  blockedBioFields: boolean;
  linkTypeConfig: boolean;
  internalDesign: boolean;
  globalData: boolean;
  dataTemplates: boolean;
  emailTemplates: boolean;
  analyticsIndividual: boolean;
  analyticsMd: boolean;
  multiAdmin: boolean;
  bulkExport: boolean;
  bulkCsvActions: boolean;
  editRights: boolean;
  businessCardScanner: boolean;
  emailEditorFull: boolean;
  crmIntegration: boolean;
  academy: boolean;
  customDomain: boolean;
  vcardConfig: boolean;
  sso: boolean;
  profileSync: boolean;
  visibilityConfig: boolean;
  leadGenInternalFormConfig: boolean;
};

export type LeadAccount = {
  leadId: string;
  lead?: MDLead;
  accountId: string;
  firstName?: string;
  lastName?: string;
  notes?: string;
  company?: string;
  position?: string;
  role?: string;
  academicDegree?: string;
  namePrefix?: string;
  profileImageUrl?: string;
  isDeleted?: boolean;
  leadCustomFieldValues?: Array<LeadCustomFieldValueAPI>;
  createdOn?: string;
};

export type MDLead = LeadAPI & {
  ltId: string | null;
  connectedAccounts: Array<LeadAccount>;
};

export type ThemeAccount = Pick<
  Account,
  'id' | 'firstName' | 'lastName' | 'email' | 'profileImageUrl'
>;

export const NonThemeConfigUnitOverridableSettingsKeys = [
  'profile-design',
  'edit-rights',
  'organization',
  'data-templates',
  'qr-backgrounds',
  'user-defined-translations',
] as const;
export type NonThemeConfigUnitOverridableSettings =
  typeof NonThemeConfigUnitOverridableSettingsKeys[number];

export type ProfileDesignConfig = {
  boxStyle: ThemeBoxStyle;
  leadGenColor: string;
  fileBoxColor: string;
  genericWebsiteColor: string;
  defaultBannerImageUrl: string;
  defaultProfileImageUrl: string;
  logoHeaderUrl: string | null;
  privacyUrl: string | null;
  imprintUrl: string | null;
  showCopyright: boolean;
  legalName: string | null;
  leadGenFormShown: boolean;
  contactExchangeFlow: ThemeContactExchangeFlow;
  bannerImgOptions: string[];
  logoImgOptions: string[];
  themeLinksPosition: GlobalDataPosition;
  themeFilesPosition: GlobalDataPosition;
  themeAddressesPosition: GlobalDataPosition;
  defaultBannerImageState: AccountImageState;
  defaultProfileImageState: AccountImageState;
  logoHeaderState: AccountImageState;
};

export type EditRightsConfig = {
  detailsEditable: boolean;
  profileImageEditable: boolean;
  bannerImageEditable: boolean;
  logoImageEditable: boolean;
  linkEditMode: ThemeLinkEditMode;
  fileEditMode: ThemeFileEditMode;
  allowOwnQrBgImage: boolean;
  rebuyCards: boolean;
  singleUserCrmPush: boolean;
  profileShare_uidParam: boolean;
};

export type UnitOverrideRightsKey =
  | `${THEME_CONFIG_KEYS}`
  | `${EmailTemplatesEnum}`
  | NonThemeConfigUnitOverridableSettings
  | 'new-test-string-key-to-prevent-typescript-type-error';

export type UnitOverrideSettings = {
  [key in UnitOverrideRightsKey]?: boolean;
};

export type ThemeSSO = {
  id: number;
  themeIdentityProviderId: number;
  strategy: StrategyName;
  connectionId: string;
  connectionName: string;
  enabled: boolean;
  providerResponse?: {
    id: 'string';
    options: StrategyOptions;
  };
  domains: ThemeDomain[];
};

export type ThemeIdentityProvider = {
  id: number;
  themeId: number;
  name: string;
  niceName: string;
  isActive: boolean;
  themeSSO?: ThemeSSO;
};

export type ThemeDomain = {
  id: number;
  themeId: number;
  domain: string;
  verified: boolean;
};

export interface ThemeIdentityProviderDetailed extends ThemeIdentityProvider {
  themeSSO: ThemeSSO;
  scim: {
    isActive: boolean;
    inviteUponProvisioning: boolean;
    syncedUsersCount: number;
  };
}
export type WalletCardColors = {
  backgroundColor: string;
  foregroundColor: string;
  labelColor: string;
};

export type HelpSidebarConfig = {
  content?: string;
  videos?: Array<{ url: string; title: string }>;
};
export type CSVActionSettings = { update?: { useEmptyColumns: boolean } };

export type NFCCard = {
  key: 'sampleCards' | 'firstTimeOrder' | 'reorder';
  disableDuringTrial: boolean;
  link?: {
    de: string;
    en: string;
  };
  linksByCountry?: Array<{
    de: string;
    en: string;
    label: {
      de: string;
      en: string;
    };
  }>;
  staticPopup?: {
    title?: {
      de: string;
      en: string;
    };
    content: {
      de: string;
      en: string;
    };
  };
};

export enum EmailEditorFixedContentTypeEnum {
  PreContent = 'pre-content',
  PostContent = 'post-content',
}

export type EmailEditorFixedContent = {
  templates: EmailTemplatesEnum[];
  condition?: {
    tiers?: string[];
    onlyNonFullAccess?: boolean;
  };
  type: EmailEditorFixedContentTypeEnum;
  html: string;
};

export type ContactPerson = {
  name?: string;
  email?: string;
  text?: string;
};

export type QuotaTimeRestriction = 'day' | 'month' | 'year' | 'alltime' | number;

type QuotaEntry = {
  amount: number;
  timeRestriction: QuotaTimeRestriction;
};

export type Quota = {
  theme?: QuotaEntry;
  unit?: QuotaEntry;
  account?: QuotaEntry;
  startingTime?: string; //Iso string
};

export type RemainingQuota = {
  theme?: number;
  unit?: number;
  account?: number;
};
