import { Alert, Box, Button, Card, FormControlLabel, IconButton, Switch } from '@mui/material';
import { FullScreenContainer } from './FullScreenContainer';
import { BottomActionContainer } from './BottomActionContainer';
import { useTranslation } from 'react-i18next';
import WestIcon from '@mui/icons-material/West';
import { getLeadCustomFields } from '@/infrastructure/apis/leadGen';
import { LeadCustomFieldAPI, LeadAPI } from '@/shared/types/api';
import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '@/application/hooks';
import { ImagesSlider } from '../../components/ImagesSlider';
import { LeadForm } from '@/components/LeadGen/LeadForm';
import { InternalContactFormField } from '@/shared/types/global';
import { LoadingButton } from '@mui/lab';

type Props = {
  businessCardUrls: string[];
  values: Partial<LeadAPI>;
  onChange: (values: Partial<LeadAPI>) => void;
  onFinish: () => void;
  onBackClick: () => void;
  onRetakeClick: () => void;
  internalContactFormFields: InternalContactFormField[];
  sendContactToEmail: boolean;
  setSendContactToEmail: (value: boolean) => void;
  isNextScreenAvailable: boolean;
  isSaving: boolean;
};

export const EditScreen = ({
  values,
  onChange,
  onFinish,
  onBackClick,
  onRetakeClick,
  internalContactFormFields,
  businessCardUrls,
  sendContactToEmail,
  setSendContactToEmail,
  isNextScreenAvailable,
  isSaving,
}: Props) => {
  const [leadCustomFields, setLeadCustomFields] = useState<Array<LeadCustomFieldAPI>>([]);
  const account = useAppSelector(state => state.account);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (!account.id) return;

      try {
        const data = await getLeadCustomFields(getAccessTokenSilently, account.id);
        setLeadCustomFields(data);
      } catch {
        //
      }
    };
    fetchData();
  }, [account.id, getAccessTokenSilently]);

  const leadFormRef = useRef(null);

  /**
   * Filter out `businessCardUrls`, `profileImageUrl` and `gdprLegalBasis` fields
   * `businessCardUrls` component is already rendered separately
   * `profileImageUrl` was not rendered previously, if we want to render we will need to handle that separately
   * `gdprLegalBasis` is rendered in a separate screen
   */
  const fieldsToRender = internalContactFormFields.filter(
    ({ name }) =>
      !['businessCardUrls', 'profileImageUrl', 'gdprLegalBasis'].includes(name as string),
  );

  const [allValuesEmpty, setIsAllValuesEmpty] = useState(false);

  const alertRef = useRef(null);
  useEffect(() => {
    if (!allValuesEmpty) return;
    alertRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [allValuesEmpty]);

  const onNextClick = () => {
    if (leadFormRef.current.isAllFieldsEmpty()) {
      setIsAllValuesEmpty(true);
      return;
    } else {
      setIsAllValuesEmpty(false);
    }
    const { leadData } = leadFormRef.current.getValues();
    onChange({ ...values, ...leadData });
    onFinish();
  };

  return (
    <FullScreenContainer>
      <IconButton
        sx={{ p: 1.6, width: 'max-content' }}
        onClick={onBackClick}
        size='large'
        aria-label={t('ariaGoBackToPreviewsScreen')}
      >
        <WestIcon />
      </IconButton>
      <Box pb={2.1} px={1.6}>
        <Card sx={{ mb: 2 }}>
          <ImagesSlider images={businessCardUrls} />
        </Card>
        <LeadForm
          ref={leadFormRef}
          initialLead={values}
          fields={fieldsToRender}
          leadCustomFields={leadCustomFields || []}
        />

        {allValuesEmpty && (
          <div ref={alertRef}>
            <Alert sx={{ mt: 4 }} severity='warning' variant='outlined'>
              {t('businessCardScanner.enterMinOne')}
            </Alert>
          </div>
        )}
      </Box>
      <BottomActionContainer>
        {values['email'] && (
          <FormControlLabel
            sx={{ mb: 2, mx: 0.5 }}
            label={t('businessCardScanner.sendContactToEmail', { email: values['email'] })}
            control={<Switch checked={sendContactToEmail} />}
            onChange={() => setSendContactToEmail(!sendContactToEmail)}
          />
        )}
        <Box display='flex' gap='1.3rem'>
          <Button fullWidth size='large' color='primary' variant='outlined' onClick={onRetakeClick}>
            {t('businessCardScanner.retake')}
          </Button>

          <LoadingButton
            fullWidth
            size='large'
            onClick={onNextClick}
            loading={isSaving}
            aria-busy={isSaving}
          >
            {t(isNextScreenAvailable ? 'businessCardScanner.next' : 'save')}
          </LoadingButton>
        </Box>
      </BottomActionContainer>
    </FullScreenContainer>
  );
};
