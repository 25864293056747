import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardHeader, Divider, styled } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

type Fields<T> = {
  shown: T[];
  notShown: T[];
};

const titleMapT = {
  shown: 'contactFormEdit.shown',
  notShown: 'contactFormEdit.notShown',
};

type SingleDroppableColumnProps<T> = {
  fieldKey: keyof typeof titleMapT;
  fields: T[];
  renderItem: (field: T) => React.ReactNode;
  getName: (field: T) => string;
};

function SingleDroppableColumn<T extends object>({
  fieldKey,
  fields,
  renderItem,
  getName,
}: SingleDroppableColumnProps<T>) {
  const { t } = useTranslation();
  return (
    <Droppable droppableId={fieldKey}>
      {(provided, snapshot) => (
        <Card sx={{ height: '100%' }}>
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title={t(titleMapT[fieldKey as keyof typeof titleMapT])}
          />
          <Divider />
          <DroppableContainer ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map((field, index) => {
              const name = getName(field);
              return (
                <Draggable key={name} draggableId={name} index={index}>
                  {(provided, snapshot) => (
                    <StyledListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onMouseDown={e => e.target.focus()}
                      id={`dnd-item-${name}`}
                    >
                      <DragIndicatorIcon sx={{ mr: 1 }} />
                      {renderItem(field)}
                    </StyledListItem>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </DroppableContainer>
        </Card>
      )}
    </Droppable>
  );
}

interface Props<T> {
  fields: Fields<T>;
  onSortEnd: (fields: Fields<T>) => void;
  renderItem: (field: T) => React.ReactNode;
  getName: (field: T) => string;
}

export function ContactFieldsSorter<T extends object>({
  fields,
  onSortEnd,
  renderItem,
  getName,
}: Props<T>) {
  const handleDragEnd = (result: any) => {
    if (!result.source || !result.destination) {
      // dragged from/to wrong pane or to outside any pane
      return;
    }
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    const sourceArr = fields[result.source.droppableId as keyof typeof fields].slice();
    const sourceIndex = result.source.index;
    const [removed] = sourceArr.splice(sourceIndex, 1);

    const destinationArr =
      result.source.droppableId === result.destination.droppableId
        ? sourceArr
        : fields[result.destination.droppableId as keyof typeof fields].slice();
    const destinationIndex = result.destination.index;
    destinationArr.splice(destinationIndex, 0, removed);

    const newFields = {
      ...fields,
      [result.source.droppableId]: sourceArr,
      [result.destination.droppableId]: destinationArr,
    };

    onSortEnd(newFields);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box display='flex' gap={2}>
        <Box flex={1}>
          <SingleDroppableColumn
            fieldKey='shown'
            fields={fields.shown}
            renderItem={renderItem}
            getName={getName}
          />
        </Box>
        <Box>
          <ChevronLeft sx={{ display: 'block', mt: 2 }} />
          <ChevronRight sx={{ display: 'block', mt: 2 }} />
        </Box>
        <Box flex={1}>
          <SingleDroppableColumn
            fieldKey='notShown'
            fields={fields.notShown}
            renderItem={renderItem}
            getName={getName}
          />
        </Box>
      </Box>
    </DragDropContext>
  );
}

export default ContactFieldsSorter;

const DroppableContainer = styled(Box)({
  width: '100%',
  height: '100%',
});

const StyledListItem = styled('li', {
  shouldForwardProp: () => true,
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  minHeight: '4.8rem',
  padding: '0 1.6rem',
  cursor: 'grab',
  wordWrap: 'break-word',
  hyphens: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 2px)',
    top: '-1px',
    left: '-1px',
    border: `1px solid ${theme.palette.divider}`,
    pointerEvents: 'none',
  },
  '&:focus': {
    backgroundColor: theme.palette.action.hover,
  },
}));
