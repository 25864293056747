import { useTranslation } from 'react-i18next';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { useCallback, useState } from 'react';
import { Account } from '@/shared/types/api';
import { useAppSelector } from '@/application/hooks';
import { withSilentAccessToken } from '@/infrastructure/helper';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import config from '@/config/config';
import { useHistory, useLocation } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { POTENTIAL_USER_SIZES } from '@/shared/constants';
import { LtDialog } from '@/components';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useNotificationsStore } from '@/zustand/notificationsStore';
import Loader from '@/components/Loader';

interface Props {
  onClosed(): void;
  open: boolean;
  plan: string;
  planNiceName: string;
}

export default function StartTrialPopup(props: Props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { isStarter } = useTierInfo();
  const account = useAppSelector<Account>(state => state.account);
  const [showLoader, setShowLoader] = useState(false);

  const [company, setCompany] = useState(account.company);
  const [potentialUsers, setPotentialUsers] = useState<string>(
    account?.accountMeta?.potentialUsers?.[0]?.key,
  );

  const upgradeDisabled = !company || !company.trim().length || !potentialUsers;

  const doUpgrade = useCallback(() => {
    if (upgradeDisabled) return;

    setShowLoader(true);
    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.post(
        config.API_BASE_URL + 'account/upgrade',
        {
          tier: 'pro',
          trial: true,
          plan: props.plan,
          pathname,
          params: {
            niceName: company?.trim(),
            potentialUsers: potentialUsers,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
      .then(() => {
        history.push(routePaths.HOME + '?lt-upgraded-now=true');
        window.location.reload();
      })
      .catch(e => {
        console.error(e);
        setShowLoader(false);
        useNotificationsStore.getState().notify.error(t('unexpectedError'));
      });
  }, [
    company,
    getAccessTokenSilently,
    history,
    pathname,
    potentialUsers,
    props.plan,
    t,
    upgradeDisabled,
  ]);

  if (!isStarter) return null;

  return (
    <>
      {showLoader && <Loader />}
      <LtDialog
        open={!!props.open}
        title={t('upgradePopup.title', { plan: props.planNiceName })}
        onClose={props.onClosed}
        onCancel={props.onClosed}
        confirmAction={{
          ttext: 'upgradePopup.submit',
          onClick: () => doUpgrade(),
          disabled: upgradeDisabled,
        }}
        size='md'
      >
        <Box mb={4}>
          <Typography mb={2}>{t('upgradePopup.q1')}</Typography>
          <TextField
            label={t('company')}
            name='company'
            value={company}
            multiline={true}
            onChange={e => setCompany(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          <Typography mb={1}>{t('upgradePopup.q2')}</Typography>
          <FormControl>
            <RadioGroup
              value={potentialUsers}
              onChange={({ target: { value } }) =>
                value === potentialUsers ? setPotentialUsers('') : setPotentialUsers(value)
              }
              row
            >
              {POTENTIAL_USER_SIZES.map((size, index) => {
                return (
                  <FormControlLabel key={index} value={size} control={<Radio />} label={t(size)} />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      </LtDialog>
    </>
  );
}
