import React, { useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonProps,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
import TooltipInfo from '@/components/TooltipInfo';

export interface ActionType {
  icon?: JSX.Element;
  actionText: string;
  tooltipText?: string;
  onClick: () => void;
  dividerAbove?: boolean;
}

type Props = {
  label: string;
  actionGroups: { title?: string; actions: ActionType[] }[];
  icon?: React.ReactNode;
} & ButtonProps;

const MenuButton = ({
  actionGroups,
  label,
  icon,
  variant: _variant,
  color: _color,
  ...rest
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const variant = _variant || 'outlined';
  const color = _color || 'primary';

  return (
    <>
      <Button
        variant={variant}
        color={color}
        startIcon={icon}
        endIcon={<ArrowDropDown />}
        onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
        aria-haspopup='listbox'
        {...rest}
      >
        {label}
      </Button>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          minWidth: '25rem',
          p: 0,
          pt: '0.4rem',
        }}
      >
        {actionGroups.map((group, i) => {
          const id = 'action-label-'.concat(i.toString());
          return (
            <>
              {group.title && (
                <Typography
                  id={id}
                  variant='subtitle1'
                  sx={{
                    py: '0.5rem',
                    px: '1rem',
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  {group.title}
                </Typography>
              )}
              <MenuList aria-labelledby={id} autoFocus={i === 0}>
                {group.actions.map(action => (
                  <>
                    {action.dividerAbove && <Divider />}
                    <MenuItem
                      onClick={() => {
                        action.onClick();
                        setAnchorEl(null);
                      }}
                    >
                      {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                      <ListItemText>{action.actionText}</ListItemText>

                      {action.tooltipText && (
                        <TooltipInfo onHover={true} text={action.tooltipText} placement='right' />
                      )}
                    </MenuItem>
                  </>
                ))}
              </MenuList>
            </>
          );
        })}
      </Popover>
    </>
  );
};

export default MenuButton;
