import { useCallback, useMemo, useState } from 'react';
import useTierInfo from './useTierInfo';
import UpgradeRequestPopup from '@/views/pages/md/teaser/upgrade-request-popup';
import { usePublicFeatureFlag } from './useFeatureFlags';
import { upgradeLink } from '../constants';
import { FEATURE } from '@/shared/constants';

export default function useUpgradeAction() {
  const { isTrial, isStarter, isMinEssentials, isFeatureAllowed } = useTierInfo();

  const flag_hidePrices = usePublicFeatureFlag('sales_hidePrices');

  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const upgradeAction = useCallback(() => {
    if (
      !flag_hidePrices &&
      (isStarter || (isMinEssentials && isTrial) || !isFeatureAllowed(FEATURE.MULTI_USER))
    ) {
      window.open(upgradeLink, '_blank');
    } else {
      setShowUpgradePopup(true);
    }
  }, [flag_hidePrices, isFeatureAllowed, isMinEssentials, isStarter, isTrial]);

  const upgradeRequestPopup = useMemo(
    () =>
      showUpgradePopup ? (
        <UpgradeRequestPopup open onClosed={() => setShowUpgradePopup(false)} />
      ) : null,
    [showUpgradePopup],
  );

  return { upgradeAction, upgradeRequestPopup, setShowUpgradePopup };
}
