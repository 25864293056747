import { CloseOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import React, { useEffect } from 'react';

export const useDismissableAlert = ({
  title,
  body,
  key,
}: {
  title: string;
  body?: string;
  key: string;
}) => {
  const localStorageKey = `dismissed-${key}`;

  const [dismissed, setDismissed] = React.useState(
    localStorage.getItem(localStorageKey) === 'true',
  );

  useEffect(() => {
    if (dismissed) {
      localStorage.setItem(localStorageKey, 'true');
    }
  }, [localStorageKey, dismissed]);

  const alert = (
    <Alert
      severity='info'
      variant='outlined'
      action={
        <IconButton onClick={() => setDismissed(true)}>
          <CloseOutlined />
        </IconButton>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {body}
    </Alert>
  );

  return { AlertElement: dismissed ? null : alert };
};
