import { Auth0ContextInterface } from '@auth0/auth0-react';
import axios from 'axios';
import config from './config/config';
import { withSilentAccessToken } from './infrastructure/helper';
import { THEME_BOX_STYLES } from './shared/constants';
import { ThemeBoxStyle } from './shared/types/global';

export const themeCss = (boxStyle: ThemeBoxStyle): string => {
  switch (boxStyle) {
    case THEME_BOX_STYLES.ANGULAR:
      return 'theme-angular';
    case THEME_BOX_STYLES.ROUNDED:
      return 'theme-rounded';
    default:
      return 'theme-rounded';
  }
};

export const removePrefix = (val: string, pref: string): string => {
  if (val.startsWith(pref)) {
    return val.substring(pref.length);
  } else {
    return val;
  }
};

export const getFileName = (type, file) => {
  const ext = file.name.split('.').pop();
  if (type === 'profile') {
    return 'profile-picture.' + ext;
  } else if (type === 'banner') {
    return 'banner.' + ext;
  } else if (type === 'logoHeader') {
    return 'logo.' + ext;
  }

  return file.name;
};

// src: https://betterprogramming.pub/loading-third-party-scripts-dynamically-in-reactjs-458c41a7013d
export const loadJsScript = (
  url: string,
  scriptId: string,
  config?: {
    cb?: Function;
    attributes?: Array<[string, string]>;
  },
) => {
  const { cb, attributes } = config || {};
  const script = document.getElementById(scriptId);
  if (!script) {
    const script = document.createElement('script');
    script.src = url;
    script.id = scriptId;
    if (attributes) {
      for (const attrs of attributes) {
        script.setAttribute(attrs[0], attrs[1]);
      }
    }
    document.body.appendChild(script);
    script.onload = () => {
      if (cb) cb();
    };
  }
  if (script && cb) cb();
};

// check if time diff is smaller then maxDiff
export const isTimeDiffSmaller = (val0: any, val1: number, maxDiff: number): boolean => {
  const parsed = parseInt(val0);
  if (typeof parsed === 'number') {
    return val1 - parsed < maxDiff;
  } else {
    return true;
  }
};

const events = [
  'email-verified',
  'onboarding-completed',
  'qr-bg-download',
  'qr-page-skipped',
  'meta-page-skipped',
  'qr-page-completed',
  'add-to-wallet-clicked',
  'add-to-wallet-skipped',
  'add-to-wallet-completed',
  'how-to-tap-completed',
] as const;
export const onboardingNotify = (
  event: typeof events[number],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) =>
  withSilentAccessToken(getAccessTokenSilently, token =>
    axios.post(
      config.API_BASE_URL + 'onboarding/notify',
      {
        key: event,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  ).catch(err => {});

export const showCookieSidebar = () => {
  window.UC_UI?.showSecondLayer?.();
};

export const getAuth0TokenScopeString = (...additionalScopes: string[]) =>
  window.location.origin === config.REACT_APP_URL
    ? [...additionalScopes, 'https://lemontaps.com/claim/'].join(' ')
    : 'https://lemontaps.com/claim/';

export const lexicalSortObject =
  (attributeName: string) => (a: Record<string, any>, b: Record<string, any>) => {
    var textA = a[attributeName].toUpperCase();
    var textB = b[attributeName].toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };

export const openLink = (url: string, target = '_self') => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.rel = 'noopener noreferrer';
  link.href = url;
  link.target = target;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => {
      console.log(error);
      resolve('');
    };
  });
};

export const flexCenterProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
} as const;

export const getValueByPath = (obj: object, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};
