import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import {
  getBeprintSsoUrl,
  getIsBeprintAvailable as getIsBeprintAvailable_api,
} from '../apis/account';
import { BeprintAvailability } from '@/shared/business-logic/integrations/beprint/types';

export default function useBeprint(variant?: 'product' | 'approval', accountId?: string) {
  const { getAccessTokenSilently } = useAuth0();

  const [ssoUrl, setSsoUrl] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    getBeprintSsoUrl(getAccessTokenSilently, variant || 'product', accountId)
      .then(res => {
        if (res.data.data.ssoUrl) setSsoUrl(res.data.data.ssoUrl);
        if (res.data.data.ssoError) setError(res.data.data.ssoError);
        else setError('');
      })
      .catch(() => setError('Error'));
  }, [accountId, getAccessTokenSilently, variant]);

  return { ssoUrl, error };
}

// poor solution, should use SWR or similar for caching
const KEY = 'lt_beprintAvailability_v2';
export const getIsBeprintAvailable = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
): Promise<BeprintAvailability> => {
  let fromSessionStorage: { date: number; data: BeprintAvailability } | null = null;
  try {
    fromSessionStorage = JSON.parse(sessionStorage.getItem(KEY));
    if (!('date' in fromSessionStorage) || !('data' in fromSessionStorage)) throw Error();
    const now = Date.now();
    const then = new Date(fromSessionStorage.date).getTime();
    if (now - then > 1000 * 30) throw Error(); // cache for 30s
  } catch {
    fromSessionStorage = null;
  }
  if (fromSessionStorage) {
    return fromSessionStorage.data;
  } else {
    try {
      const val = await getIsBeprintAvailable_api(getAccessTokenSilently);
      sessionStorage.setItem(KEY, JSON.stringify({ date: Date.now(), data: val }));
      return val;
    } catch {
      return { isAvailable: false };
    }
  }
};
export function useIsBeprintAvailable() {
  const { getAccessTokenSilently } = useAuth0();
  const [beprintAvailability, setBeprintAvailability] = useState<BeprintAvailability>({});
  const [isBeprintLoading, setIsBeprintLoading] = useState(true);
  useEffect(() => {
    getIsBeprintAvailable(getAccessTokenSilently)
      .then(res => {
        setBeprintAvailability(res);
        setIsBeprintLoading(false);
      })
      .catch(() => setIsBeprintLoading(false));
  }, [getAccessTokenSilently]);
  return { beprintAvailability, isBeprintLoading };
}
