import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useAppSelector } from '../../../application/hooks';
import { RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import PageNotFound from '../page-not-found';
import { Account, StaticProfileConfig } from '../../../shared/types/api';
import { PUBLIC_EVENTS } from '../../../shared/constants/global/analytics.constants';

import { trackPublicEvent } from '../../../infrastructure/apis/analytics';
import { useHistory } from 'react-router-dom';
import useQuery from '../../../infrastructure/hooks/useQuery';
import { fetchProfile as fetchProfile_api } from '@/infrastructure/apis/profile';
import StaticProfileSkeleton from '@/components/Profile/components/StaticProfileSkeleton';
import MetaTags from './components/MetaTags';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { I18N_LOCAL_STORAGE_KEY, QUERY_KEY_LANG, getNewI18nInstance } from '@/config/i18n';
import { i18n } from 'i18next';
import PrivateAlert from './PrivateAlert';
import { base64UrlDecode } from '@/shared/util';
import { ThemeProvider } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import BottomAuthBar from './components/BottomAuthBar';
import StaticProfileUI from '@/components/Profile/StaticProfile';
import { addLead } from '@/infrastructure/apis/leadGen';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

// TODO: profile page optimizations
// consolidate logic, universalize logic, make parts re-usable

interface TParams {
  username: string;
}

export default function UserProfile(props: RouteComponentProps<TParams>): JSX.Element {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const usernameOrCode = props.match.params.username;

  const query = useQuery();
  const history = useHistory();
  const { theme: muiTheme } = useMuiTheme();

  const forceProfile = !!query.get('forceProfile');
  const goBackTarget = query.get('backTo');

  // i18n instance logic
  const [i18nProfile, setI18nProfile] = useState<null | i18n>(null);

  // initiate language to fetch profile for
  const [activeLanguage, setActiveLanguage] = useState(
    query.get(QUERY_KEY_LANG)?.substring(0, 2) ||
      localStorage.getItem(I18N_LOCAL_STORAGE_KEY.PROFILE) ||
      navigator.language,
  );

  // used to initiate the i18n instance one the profile information has been fetched
  const initI18nProfile = useCallback(
    (profile: StaticProfileConfig) => {
      if (!i18nProfile) {
        setI18nProfile(
          getNewI18nInstance({
            fallbackLng: 'en',
            supportedLngs: profile.meta.supportedLangs,
            lng: profile.meta.lang,
            detection: {
              order: ['localStorage', 'navigator'],
              lookupLocalStorage: I18N_LOCAL_STORAGE_KEY.PROFILE,
            },
          }),
        );
      }
    },
    [i18nProfile],
  );

  // query params to forward to the server
  const paramsFromQuery = useMemo(
    () => ({
      uid: query.get('uid')
        ? parseInt(query.get('uid')) || parseInt(base64UrlDecode(query.get('uid'))) || undefined
        : undefined,
    }),
    [query],
  );

  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();

  // logged-in user
  const account = useAppSelector<Account>(state => state.account);

  // profile fetching
  const [profile, setProfile] = useState<StaticProfileConfig>(null);
  const cachedProfiles = useRef<Record<string, StaticProfileConfig>>({}); // if lang is switched back and forth multiple times
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileReloading, setProfileReloading] = useState(false);
  const fetchProfile = useCallback(
    (setLoaderFct = setProfileLoading) => {
      if (cachedProfiles.current[activeLanguage]) {
        setProfile(cachedProfiles.current[activeLanguage]);
        return;
      }

      setLoaderFct(true);
      fetchProfile_api(usernameOrCode, activeLanguage, paramsFromQuery)
        .then(res => {
          const { status, name, code, profile } = res;
          if (status === 200 && name === 'profile') {
            if (profile.forward && !forceProfile) {
              window.open(profile.forward, '_self');
            } else if (profile.meta.domain !== window.location.origin) {
              window.location.replace(
                `${profile.meta.domain}/${profile.meta.username}` + window.location.search,
              );
            } else {
              cachedProfiles.current[activeLanguage] = profile;
              setProfile(profile);
              initI18nProfile(profile);
              setLoaderFct(false);
            }
          } else if (status === 200 && name === 'code') {
            history.push(`/landing/check/${code}`);
          }
        })
        .catch(() => setLoaderFct(false));
    },
    [activeLanguage, forceProfile, history, initI18nProfile, paramsFromQuery, usernameOrCode],
  );
  useEffect(() => {
    if (!profile) {
      // don't refetch when forwarded due to code
      fetchProfile();
    }
  }, [fetchProfile, profile]);
  // do re-fetch on language change
  const prevLang = useRef<string>(activeLanguage);
  useEffect(() => {
    if (prevLang.current && activeLanguage !== prevLang.current) {
      prevLang.current = activeLanguage;
      fetchProfile(setProfileReloading);
    }
  }, [activeLanguage, fetchProfile]);

  useDeferredLoader(profileReloading, 'profile-reloader');

  // once profile is available, attach listener to lang changed event
  // to update the state "activeLanguage". Otherwise no re-render would be triggered
  useEffect(() => {
    if (i18nProfile) {
      const listener = (lang: string) => {
        setActiveLanguage(lang);
        if (query.get(QUERY_KEY_LANG)) {
          query.set(QUERY_KEY_LANG, lang);
          history.replace('/' + profile.meta.username + '?' + query.toString());
        }
      };
      i18nProfile.on('languageChanged', listener);
      return () => i18nProfile.off('languageChanged', listener);
    }
  }, [history, i18nProfile, profile?.meta.username, query]);

  // replace path with username
  useEffect(() => {
    if (profile && profile?.meta.username !== usernameOrCode) {
      history.replace('/' + profile.meta.username + window.location.search);
    }
  }, [history, profile, usernameOrCode]);

  const renderTryForFree = !isAuth0Loading && profile?.config.tryForFreeBanner && !isAuthenticated;

  useEffect(() => window.scrollTo(0, 0), []);

  const utmParams = useMemo(() => {
    const out: Record<string, Record<string, string>> = {};
    for (const key of Array.from(query.keys())) {
      if (key.startsWith('utm_')) {
        if (!out.utm) out.utm = {};
        out.utm[key] = query.get(key);
      } else if (key.startsWith('lt_')) {
        if (!out.lt) out.lt = {};
        out.lt[key] = query.get(key);
      }
    }
    return out;
  }, [query]);

  const thArg = useMemo(
    () => ({
      username: account?.username,
      isAuthenticated,
      isAuth0Loading,
    }),
    [account?.username, isAuth0Loading, isAuthenticated],
  );
  useEffect(() => {
    if (profile) {
      trackPublicEvent(thArg, PUBLIC_EVENTS.PROFILE_VIEW, profile.meta.username, null, null, {
        ...utmParams,
      });
    }
  }, [profile, thArg, utmParams]);

  // favicon
  useEffect(() => {
    if (profile?.config?.iconUrl) {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = profile.config.iconUrl;
    }
  }, [profile?.config?.iconUrl]);

  const isOwnProfile = useMemo(
    () => isAuthenticated && account?.username === profile?.meta.username,
    [account?.username, isAuthenticated, profile?.meta.username],
  );
  const isOwnTheme = useMemo(
    () => isAuthenticated && account?.theme?.uuid === profile?.meta?.themeUuid,
    [account?.theme?.uuid, isAuthenticated, profile?.meta?.themeUuid],
  );

  const forcedLeadValues = useMemo(() => ({ sendContactToEmail: true }), []);

  if (profileLoading) {
    return (
      <ThemeProvider theme={muiTheme}>
        <StaticProfileSkeleton />
      </ThemeProvider>
    );
  } else if (!profile || !i18nProfile) {
    return <PageNotFound />;
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <I18nextProvider i18n={i18nProfile}>
        <MetaTags bio={profile.bio} />

        {profile.forward && isOwnProfile && (
          <PrivateAlert ttext='publicProfile.profileForced' tobj={{ url: profile.forward }} />
        )}

        <StaticProfileUI
          profile={profile}
          renderTryForFreeBanner={renderTryForFree}
          renderCookieSettings={!isAuthenticated}
          onContactFormSubmit={async values => {
            const toastId = 'createLeadToast';
            let success = false;
            try {
              const { data: result } = await addLead(
                { ...values, preferredLang: activeLanguage, ...(forcedLeadValues || {}) },
                profile.meta.username,
                true,
              );

              if (result.isSuccess) {
                toast.success(t('leadSuccess'), { id: toastId });
                success = true;
              } else {
                toast.error(t('leadError'), { id: toastId });
              }
            } catch (error) {
              toast.error(t('leadError'), { id: toastId });
            }

            return success;
          }}
          trackHandlers={{
            contactSaveTrackHandler: () =>
              trackPublicEvent(thArg, PUBLIC_EVENTS.CONTACT_SAVE_CLICK, profile.meta.username),
            linkClickTrackHandler: meta =>
              trackPublicEvent(
                thArg,
                PUBLIC_EVENTS.LINK_CLICK,
                profile.meta.username,
                null,
                meta.itemId,
              ),
            fileClickTrackHandler: meta =>
              trackPublicEvent(
                thArg,
                PUBLIC_EVENTS.FILE_CLICK,
                profile.meta.username,
                meta.itemId,
                null,
                meta.pageCount ? { pageCount: meta.pageCount } : undefined,
              ),
            contactSubmitTrackHandler: () =>
              trackPublicEvent(thArg, PUBLIC_EVENTS.CONTACT_SUBMIT_CLICK, profile.meta.username),
            profileImageClickTrackHandler: () =>
              trackPublicEvent(thArg, PUBLIC_EVENTS.PROFILE_PIC_CLICK, profile.meta.username),
          }}
        />

        {/* <StaticProfile
          profile={profile}
          activeLanguage={activeLanguage}
          authAccount={!isAuth0Loading ? account : null}
          renderTryForFree={renderTryForFree}
          noCookieSettings={isAuthenticated}
          forcedLeadValues={forcedLeadValues}
        /> */}
      </I18nextProvider>

      <BottomAuthBar
        account={account}
        profileUsername={profile.meta.username}
        isAuthenticated={isAuthenticated}
        isOwnProfile={isOwnProfile}
        isOwnTheme={isOwnTheme}
        goBackTarget={goBackTarget}
      />
    </ThemeProvider>
  );
}
