import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';
import { GroupAddOutlined } from '@mui/icons-material';
import { Theme, Typography } from '@mui/material';
import { Box, alpha } from '@mui/system';
import { useTranslation } from 'react-i18next';
const sizeSet = {
  sm: {
    iconWrapperSize: '8rem',
    textVariant: 'h4',
    iconSize: '3rem',
    marginSize: '2rem',
  },
  md: {
    iconWrapperSize: '15rem',
    textVariant: 'h1',
    iconSize: '5rem',
    marginSize: '5rem',
  },
} as const;

type Props = {
  actions: React.ReactNode;
  size?: keyof typeof sizeSet;
  hideText?: boolean;
};

export const NoLeads = ({ actions, size = 'md', hideText }: Props) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktopMui();

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      mt={size === 'md' && (isDesktop ? '10rem' : '3rem')}
      px={2}
    >
      <Box
        sx={(theme: Theme) => ({
          border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
          bgcolor: alpha(theme.palette.primary.main, 0.04),
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: isDesktop ? sizeSet[size].iconWrapperSize : '8rem',
          height: isDesktop ? sizeSet[size].iconWrapperSize : '8rem',
        })}
      >
        <GroupAddOutlined
          sx={{ fontSize: isDesktop ? sizeSet[size].iconSize : '3rem', color: 'primary.main' }}
        />
      </Box>
      {!hideText && (
        <Typography
          sx={{ mt: sizeSet[size].marginSize }}
          variant={sizeSet[size].textVariant}
          component='p'
          textAlign='center'
        >
          {t('leads.noLeadsCollected')}
        </Typography>
      )}
      <Box gap={2} display='flex' mt={sizeSet[size].marginSize}>
        {actions}
      </Box>
    </Box>
  );
};
