import { routePaths } from '@/infrastructure/constants';
import {
  FeatureFlagGuard,
  FeatureSetGuard,
  LtDialog,
  PageContainer,
  SettingsMenu,
} from '@/components';
import { Typography, Grid, Link, Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTranslation } from 'react-i18next';
import withNav from '@/infrastructure/hoc/withNav';
import { ThemeDomain, ThemeIdentityProviderDetailed } from '@/shared/types/api';
import { useCallback, useEffect, useState, useRef } from 'react';
import {
  ThemeIdpUpdatePayload,
  deleteThemeIdp,
  getThemeIdp,
  updateThemeIdp,
} from '@/infrastructure/apis/md/idp';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ScimCard } from './components/ScimCard';
import EditIcon from '@mui/icons-material/Edit';
import { IdpEditPopup } from './components/IdpEditPopup';
import { IdpDisconnectPopup } from './components/IdpDisconnectPopup';
import { ScimSyncedUsersCount } from './components/ScimSyncedUsersCount';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { SSOCard } from './components/SSOCard';
import { getDomains } from '@/infrastructure/apis/md/customization';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { FEATURE, THEME_CONFIG_KEYS } from '@/shared/constants';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { MenuItemType } from '@/components/SettingsMenu';

const ThemeIdpSettingsPage = () => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const [themeIdp, setThemeIdp] = useState<ThemeIdentityProviderDetailed>(null);
  const [domains, setDomains] = useState<ThemeDomain[]>([]);
  const [editIdpPopupOpened, setEditIdpPopupOpened] = useState(false);
  const [disconnectIdpPopupOpened, setDisconnectIdpPopupOpened] = useState(false);
  const [deleteIdpPopupOpened, setDeleteIdpPopupOpened] = useState(false);
  const [idpSaving, setIdpSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { getAccessTokenSilently } = useAuth0();
  const [isIdpDeleting, setIsIdpDeleting] = useState(false);

  const history = useHistory();

  const { config: helpUrls } = usePrivateThemeConfig<Record<string, string>>(
    THEME_CONFIG_KEYS.HELP_RESOURCE_URLS,
  );
  const helpArticleUrl = helpUrls?.['idps-root'];

  const scimSetupPopupRef = useRef(null);

  useDeferredLoader(!themeIdp, 'themeIdp');

  const fetchIdpSettings = useCallback(async () => {
    try {
      const { data } = await getThemeIdp(getAccessTokenSilently, id);
      setThemeIdp(data);
    } catch (error) {
      toast.error(t('error.general'), { id: 'themeIdp' });
      console.log(error);
    }
  }, [getAccessTokenSilently, id, t, toast]);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const { data: _domains } = await getDomains(getAccessTokenSilently, { onlyVerified: true });
        setDomains(_domains);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDomains();
    fetchIdpSettings();
  }, [fetchIdpSettings, getAccessTokenSilently]);

  if (!themeIdp) return null;

  const { scim } = themeIdp;
  const isSsoCreated = Boolean(themeIdp.themeSSO);

  const handleIdpUpdate = async (payload: ThemeIdpUpdatePayload) => {
    setIdpSaving(true);
    try {
      const { data: updatedThemeIdp } = await updateThemeIdp(getAccessTokenSilently, id, payload);
      setThemeIdp({ ...themeIdp, ...updatedThemeIdp });
      setEditIdpPopupOpened(false);
      toast.success(t('idp.updateSucess'));
    } catch (error) {
      toast.error(t('idp.updateError'));
    }
    setIdpSaving(false);
  };

  const handleIdpEditSave = async (value: string) => {
    await handleIdpUpdate({
      isActive: themeIdp.isActive,
      niceName: value,
    });
  };

  const handleIdpEdit = () => {
    setEditIdpPopupOpened(true);
  };

  const handleIdpDisconnect = () => {
    if (isSsoCreated || scim.isActive) {
      setDisconnectIdpPopupOpened(true);
    } else {
      setDeleteIdpPopupOpened(true);
    }
  };

  const handleIdpDelete = async () => {
    setIsIdpDeleting(true);
    try {
      await deleteThemeIdp(getAccessTokenSilently, id);
      history.push(routePaths.IDP.LIST);
      toast.success(t('idp.deleteSuccess'), { id: 'idpDelete' });
    } catch (error) {
      toast.error(t('idp.deleteError'));
    }
    setIsIdpDeleting(false);
  };

  const openScimSetupPopup = () => scimSetupPopupRef.current?.openSetupPopup();

  const pageSettingsMenuItems: MenuItemType[] = [
    {
      icon: <EditIcon />,
      label: t('rename'),
      onClick: handleIdpEdit,
    },
    {
      icon: <RemoveCircleOutlineIcon />,
      label: t('disconnect'),
      onClick: handleIdpDisconnect,
    },
  ];

  return (
    <>
      <PageContainer maxWidth='xl' helpSidebarConfigKey='help-sidebar-idp-settings'>
        <Box display='flex' justifyContent='space-between' alignItems='flex-start' mb={2}>
          <Box flex='1'>
            <Typography variant='h2'>{themeIdp.niceName}</Typography>
            <Typography variant='body1' color='secondary' sx={{ mt: 1 }}>
              {t('idp.settingsPageDescription')}{' '}
              {helpArticleUrl && (
                <Typography
                  underline='none'
                  component={Link}
                  href={helpArticleUrl}
                  target='_blank'
                  variant='body1'
                >
                  {t('learnMore')}.
                </Typography>
              )}
            </Typography>
          </Box>
          <SettingsMenu
            iconButtonProps={{
              'aria-label': t('ariaOpenIdpSettings'),
              'aria-haspopup': 'menu',
            }}
            menuItems={pageSettingsMenuItems}
            icon={<SettingsIcon />}
          />
        </Box>
        {scim.isActive && (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ScimSyncedUsersCount count={scim.syncedUsersCount} />
            </Grid>
          </Grid>
        )}
        <Typography mt={4.4} mb={2} variant='h3'>
          {t('idp.settings')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SSOCard
              domains={domains}
              refetchIdpSettings={fetchIdpSettings}
              themeIdp={themeIdp}
              isSsoCreated={isSsoCreated}
              openScimSetupPopup={openScimSetupPopup}
              isScimActive={scim.isActive}
            />
          </Grid>
          <Grid item xs={6}>
            <ScimCard
              themeIdp={themeIdp}
              ref={scimSetupPopupRef}
              refetchIdpSettings={fetchIdpSettings}
              isScimActive={scim.isActive}
            />
          </Grid>
        </Grid>
      </PageContainer>
      <IdpEditPopup
        loading={idpSaving}
        idpName={themeIdp.niceName}
        onSave={handleIdpEditSave}
        open={editIdpPopupOpened}
        onClose={() => setEditIdpPopupOpened(false)}
      />
      <IdpDisconnectPopup
        open={disconnectIdpPopupOpened}
        onClose={() => setDisconnectIdpPopupOpened(false)}
      />
      <LtDialog
        open={deleteIdpPopupOpened}
        onClose={() => setDeleteIdpPopupOpened(false)}
        onCancel={() => setDeleteIdpPopupOpened(false)}
        title={t('requestDelete')}
        size='sm'
        onDelete={handleIdpDelete}
        loading={isIdpDeleting}
        withActionDivider
      >
        <Typography>{t('idp.deleteMessage')}</Typography>
      </LtDialog>
    </>
  );
};

export default withNav(
  () => (
    <FeatureFlagGuard privateFlags={['iam']}>
      <FeatureSetGuard
        gatedCondition={tierInfo =>
          !tierInfo.isFeatureAllowed(FEATURE.SSO) &&
          !tierInfo.isFeatureAllowed(FEATURE.PROFILE_SYNC)
        }
        text='upgradeTeaser.feature.idp'
      >
        <ThemeIdpSettingsPage />
      </FeatureSetGuard>
    </FeatureFlagGuard>
  ),
  {
    tTitle: 'idp.identityProvider',
  },
  {
    activeScreen: routePaths.IDP.THEME_IDP_SETTINGS,
  },
);
