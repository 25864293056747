export const PROFILE_PAGE_LABELS = {
  header: {
    saveContactButton: { en: 'Save contact', de: 'Kontakt speichern' },
  },
  customBioTextBox: {
    boxLabel: { en: 'About me', de: 'Über mich' },
  },
  contactDetailsBox: {
    saveContactButton: { en: 'vcard', de: 'vcard' },
    boxLabel: { en: 'My Contact Details', de: 'Meine Kontaktdaten' },
    mobilePhone: { en: 'Mobile', de: 'Mobil' },
    homePhone: { en: 'Home', de: 'Festnetz' },
    workPhone: { en: 'Work', de: 'Arbeit' },
    hotlinePhone: { en: 'Hotline', de: 'Hotline' },
    assistance: { en: 'Assistance', de: 'Assistenz' },
    fax: { en: 'Fax', de: 'Fax' },
  },
  linksBox: {
    boxLabel: { en: 'My Links', de: 'Meine Links' },
  },
  filesBox: {
    boxLabel: { en: 'My Files', de: 'Meine Dateien' },
  },
  leadGen: {
    bottomCardStatic: {
      title: { en: 'Leave me your contact', de: 'Hinterlasse Deinen Kontakt' },
      submitButton: { en: 'Submit', de: 'Absenden' },
      gdprConsent: {
        en: 'I confirm that I have read the <0>privacy policy</0> and agree to the processing of my personal data by the user or his company for the stated purposes. I can revoke this consent at any time.',
        de: 'Ich bestätige, dass ich die <0>Datenschutzerklärung</0> zur Kenntnis genommen habe und mit der Verarbeitung meiner personenbezogenen Daten durch den Nutzer oder dessen Firma zu den genannten Zwecken einverstanden bin. Ich kann meine Einwilligung jederzeit widerrufen.',
      },
    },
    popup: {
      title: {
        en: 'Send me your contact and\nreceive mine by email:',
        de: 'Sende Deine Kontaktdaten und\nerhalte meine per E-Mail:',
      },
      submitButton: { en: "Let's connect", de: 'Jetzt vernetzen' },
      gdprConsent: {
        en: 'By submitting I confirm that I have read the <0>privacy policy</0> and that I consent to the processing of my contact details in the contact management of the Lemontaps user, as well as that of their company, if applicable<1> (privacy policy)</1>. You can revoke this consent at any time.',
        de: 'Durch Absenden bestätige ich, dass ich die <0>Datenschutzerklärung</0> zur Kenntnis genommen habe und mit der Verarbeitung meiner Daten im Kontakt-Management des Lemontaps Nutzers und gegebenfalls seiner Firma<1> (Datenschutzerklärung)</1> zu den genannten Zwecken einverstanden bin. Du kannst jederzeit Deine Einwilligung widerrufen.',
      },
    },
  },
  attributeLabels: {
    firstName: { en: 'First Name', de: 'Vorname' },
    lastName: { en: 'Last Name', de: 'Nachname' },
    academicDegree: {
      en: 'Academic Degree (e.g. Ing., B.Sc.)',
      de: 'Akademischer Grad (z.B. Ing., B.Sc.)',
    },
    namePrefix: { en: 'Name Prefix', de: 'Namenspräfix' },
    birthday: { en: 'Birthday', de: 'Geburtstag' },
    position: { en: 'Position', de: 'Position' },
    role: { en: 'Role', de: 'Rolle' },
    company: { en: 'Company', de: 'Unternehmen' },
    division: { en: 'Division', de: 'Abteilung' },
    email: { en: 'E-Mail', de: 'E-Mail' },
    workEmail: { en: 'Work E-Mail', de: 'E-Mail Arbeit' },
    workPhone: { en: 'Telephone - Work', de: 'Telefon - Arbeit' },
    mobilePhone: { en: 'Telephone - Mobile', de: 'Telefon - Mobil' },
    addressee: { en: 'Addressee', de: 'Adressat' },
    addressLine1: { en: 'Address Line 1', de: 'Adresszeile 1' },
    addressLine2: { en: 'Address Line 2', de: 'Adresszeile 2' },
    postCode: { en: 'Postcode', de: 'PLZ' },
    city: { en: 'City', de: 'Stadt' },
    country: { en: 'Country', de: 'Land' },
    notes: { en: 'Notes', de: 'Notizen' },
    preferredLang: { en: 'Language', de: 'Sprache' },
    website: { en: 'Website', de: 'Webseite' },
    createdOn: { en: 'Created on', de: 'Erstellt am' },
  },
  footer: {
    imprint: { en: 'Imprint', de: 'Impressum' },
    privacyPolicy: { en: 'Privacy', de: 'Datenschutz' },
  },
} as const;

/**
 * Default Values
"header.saveContactButton","Kontakt speichern","Save contact"
"contactDetailsBox.saveContactButton","vcard","vcard"
"contactDetailsBox.boxLabel","Meine Kontaktdaten","My Contact Details"
"contactDetailsBox.mobilePhone","Mobil","Mobile"
"contactDetailsBox.homePhone","Festnetz","Home"
"contactDetailsBox.workPhone","Arbeit","Work"
"contactDetailsBox.hotlinePhone","Hotline","Hotline"
"contactDetailsBox.assistance","Assistenz","Assistance"
"contactDetailsBox.fax","Fax","Fax"
"linksBox.boxLabel","Meine Links","My Links"
"filesBox.boxLabel","Meine Dateien","My Files"
"leadGen.bottomCardStatic.title","Hinterlasse Deinen Kontakt","Leave me your contact"
"leadGen.bottomCardStatic.showMore","Mehr anzeigen","Show more"
"leadGen.bottomCardStatic.showLess","Weniger anzeigen","Show less"
"leadGen.bottomCardStatic.submitButton","Absenden","Submit"
"leadGen.bottomCardStatic.gdprConsent","Durch Absenden bestätige ich, dass ich mit der Verarbeitung meiner Daten im Kontakt-Management von Lemontaps, und gegebenfalls der Firma des*der Benutzer*in, <0>(Datenschutzerklärung)</0> zu den genannten Zwecken einverstanden bin. Ich kann meine Einwilligung jederzeit widerrufen.","By submitting I confirm that I consent to the processing of my contact details in the contact management of the Lemontaps user, as well as that of their company, if applicable <0>(privacy policy)</0>. I can revoke this consent at any time."
"leadGen.popup.title","Sende Deine Kontaktdaten und
erhalte meine per E-Mail:","Send me your contact and
receive mine by email:"
"leadGen.popup.showMore","Mehr anzeigen - optional","Show more - optional"
"leadGen.popup.showLess","Weniger anzeigen","Show less"
"leadGen.popup.submitButton","Jetzt vernetzen","Let's connect"
"leadGen.popup.gdprConsent","Durch Absenden bestätige ich, dass ich die <0>Datenschutzerklärung</0> zur Kenntnis genommen habe und mit der Verarbeitung meiner Daten im Kontakt-Management des Lemontaps Nutzers und gegebenfalls seiner Firma<1> (Datenschutzerklärung)</1> zu den genannten Zwecken einverstanden bin. Du kannst jederzeit Deine Einwilligung widerrufen.","By submitting I confirm that I have read the <0>privacy policy</0> and that I consent to the processing of my contact details in the contact management of the Lemontaps user, as well as that of their company, if applicable<1> (privacy policy)</1>. You can revoke this consent at any time."
"attributeLabels.firstName","Vorname","First Name"
"attributeLabels.lastName","Nachname","Last Name"
"attributeLabels.academicDegree","Akademischer Grad (z.B. Ing, B.Sc.)","Academic Degree (e.g. Ing, B.Sc.)"
"attributeLabels.namePrefix","Namenspräfix","Name Prefix"
"attributeLabels.birthday","Geburtstag","Birthday"
"attributeLabels.position","Position","Position"
"attributeLabels.role","Rolle","Role"
"attributeLabels.company","Unternehmen","Company"
"attributeLabels.division","Abteilung","Division"
"attributeLabels.email","E-Mail","E-Mail"
"attributeLabels.workEmail","E-Mail Arbeit","Work E-Mail"
"attributeLabels.workPhone","Telefon - Arbeit","Telephone - Work"
"attributeLabels.mobilePhone","Telefon - Mobil","Telephone - Mobile"
"attributeLabels.addressee","Adressat","Addressee"
"attributeLabels.addressLine1","Adresszeile 1","Address Line 1"
"attributeLabels.addressLine2","Adresszeile 2","Address Line 2"
"attributeLabels.postCode","PLZ","Postcode"
"attributeLabels.city","Stadt","City"
"attributeLabels.country","Land","Country"
"attributeLabels.notes","Notizen","Notes"
"footer.imprint","Impressumg","Imprint"
"footer.privacyPolicy","Datenschutz","Privacy"
 */
