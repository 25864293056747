import { useAppSelector } from '@/application/hooks';
import StaticProfile from '@/components/Profile/StaticProfile';
import { trackPublicEvent } from '@/infrastructure/apis/analytics';
import { fetchProfile } from '@/infrastructure/apis/profile';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { PUBLIC_EVENTS } from '@/shared/constants';
import { Account, StaticProfileConfig } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  usernameOrCode: string;
  lt2ltAccount: Account;
  profileDesign: any;
}

export const LTProfileInfo = ({ usernameOrCode, lt2ltAccount, profileDesign }: Props) => {
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const { activeLanguage } = useAppTranslation();

  const account: Account = useAppSelector(state => state.account);

  const [error, setError] = useState(false);
  const [profile, setProfile] = useState<StaticProfileConfig>(null);
  useEffect(() => {
    if (!profile && !error) {
      fetchProfile(usernameOrCode, activeLanguage, { crossDomain: true })
        .then(res => {
          if (res.profile) {
            setProfile(res.profile);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [activeLanguage, error, profile, usernameOrCode]);

  const linkTH = useCallback(
    (meta: { itemId: number }) => {
      if (usernameOrCode) {
        trackPublicEvent(
          { username: account?.username, isAuthenticated, isAuth0Loading },
          PUBLIC_EVENTS.LINK_CLICK,
          usernameOrCode,
          null,
          meta.itemId,
        );
      }
    },
    [account, isAuth0Loading, isAuthenticated, usernameOrCode],
  );

  const fileTH = useCallback(
    ({ itemId, pageCount }: { itemId: number; pageCount?: number }) => {
      trackPublicEvent(
        { username: account?.username, isAuthenticated, isAuth0Loading },
        PUBLIC_EVENTS.FILE_CLICK,
        usernameOrCode,
        itemId,
        null,
        pageCount ? { pageCount } : undefined,
      );
    },
    [account, isAuth0Loading, isAuthenticated, usernameOrCode],
  );

  if (error) {
    return <Typography>Error</Typography>;
  } else if (!profile) {
    return null; // loading
  }

  return (
    <StaticProfile
      profile={profile}
      suppressLeadGen
      renderFooter={false}
      renderBoxes={['contactDetailsBox', 'customBioTextBox', 'urlsBox_links', 'urlsBox_files']}
      trackHandlers={{ linkClickTrackHandler: linkTH, fileClickTrackHandler: fileTH }}
    />
  );
};
