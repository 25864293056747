import { StaticProfileConfig } from '@/shared/types/api';
import { PropsWithChildren, useMemo } from 'react';
import ThemeOverride from './ThemeOverride';

interface Props {
  metaTheme: StaticProfileConfig['config']['metaTheme'];
}

export default function ProfileThemeProvider({ children, metaTheme }: PropsWithChildren<Props>) {
  const themeOverrides = useMemo(() => {
    const boxStyleCss = metaTheme.boxStyle === 'angular' ? { borderRadius: 0 } : {};
    return {
      palette: {
        text: {
          primary: 'rgb(85, 89, 94) !important',
          ':not(.Mui-error)': {
            secondary: 'rgb(85, 89, 94) !important',
          },
          disabled: 'rgb(85, 89, 94) !important',
        },
      },
      // didn't find a better way fast to apply these colors
      typography: {
        h1: {
          color: 'rgb(85, 89, 94) !important',
        },
        h2: {
          color: 'rgb(85, 89, 94) !important',
        },
        h3: {
          color: 'rgb(85, 89, 94) !important',
        },
        h4: {
          color: 'rgb(85, 89, 94) !important',
        },
        h5: {
          color: 'rgb(85, 89, 94) !important',
        },
        h6: {
          color: 'rgb(85, 89, 94) !important',
        },
        body1: {
          ':not(.Mui-error)': {
            color: 'rgb(85, 89, 94) !important',
          },
        },
        body2: {
          color: 'rgb(85, 89, 94) !important',
        },
        subtitle1: {
          color: 'rgb(85, 89, 94) !important',
        },
        subtitle2: {
          color: 'rgb(85, 89, 94) !important',
        },
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              ...boxStyleCss,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              ...boxStyleCss,
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              ...boxStyleCss,
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              ...boxStyleCss,

              '&.lt-url-button': {
                borderRadius: metaTheme.boxStyle === 'angular' ? undefined : '3rem',
              },
            },
          },
        },
      },
    };
  }, [metaTheme.boxStyle]);

  return <ThemeOverride overrides={themeOverrides}>{children}</ThemeOverride>;
}
