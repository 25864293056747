import { Box, IconButton, TextField } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddEditFieldFormValues } from './types';
import { DeleteOutline } from '@mui/icons-material';
import { ThemeLanguage } from '@/shared/types/api';
import ISO6391 from 'iso-639-1';

type Props = {
  onRemoveClick?: (index: number) => void;
  index: number;
  languages: ThemeLanguage[];
};

export const OptionsFormFields = ({ languages, onRemoveClick, index }: Props) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<AddEditFieldFormValues>();

  const { fields } = useFieldArray({ control, name: `options.${index}.titles` });

  const isLanguageFallback = (lang: string) => {
    return Boolean(languages.find(x => x.lang === lang)?.isFallback);
  };

  return (
    <Box display='flex' borderColor='divider' pb={2} mt={2} alignItems='start'>
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        width='100%'
        ml={onRemoveClick && 1.2}
        mr={onRemoveClick && 1.2}
      >
        {fields.map((subField, subIndex) => (
          <Controller
            control={control}
            name={`options.${index}.titles.${subIndex}.value`}
            rules={
              isLanguageFallback(subField.lang) && {
                required: {
                  message: t('error.required'),
                  value: true,
                },
              }
            }
            render={({ field }) => (
              <TextField
                inputProps={field}
                label={`${t('option')} (${ISO6391.getNativeName(subField.lang)})`}
                error={Boolean(errors.options?.[index]?.titles?.[subIndex]?.value)}
                aria-invalid={Boolean(errors.options?.[index]?.titles?.[subIndex]?.value)}
                helperText={errors.options?.[index]?.titles?.[subIndex]?.value?.message}
                fullWidth
                required={isLanguageFallback(subField.lang)}
              />
            )}
          />
        ))}
      </Box>
      {onRemoveClick && (
        <IconButton onClick={() => onRemoveClick(index)}>
          <DeleteOutline />
        </IconButton>
      )}
    </Box>
  );
};
