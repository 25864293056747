/**UDT - User Defined Translations */

import React, { useCallback, useEffect, useState } from 'react';
import { getLanguages, getTranslationsJSON } from '../apis/udt';
import { useAuth0 } from '@auth0/auth0-react';
import { UdtCategory } from '@/shared/business-logic/features/user-defined-translations/types';
import { useAppTranslation } from './useAppTranslation';
import { ThemeLanguage } from '@/shared/types/api';
import { PROFILE_PAGE_LABELS } from '@/shared/constants/system-labels/profile-page';
import { getValueByPath } from '@/util';

type SingleUdtCategoryMap = Record<string, Record<string, string>>;

export const initialValues = {
  translations: {},
  languages: [],
  isLoading: false,
  refetchUDT: () => {},
  refetchLanguages: () => {},
  mutateSingleCategory: () => {},
};

const UDTContext = React.createContext<{
  translations: Record<string, SingleUdtCategoryMap>;
  languages: ThemeLanguage[];
  fallbackLanguage?: string;
  isLoading: boolean;
  refetchUDT: () => void;
  refetchLanguages: () => void;
  mutateSingleCategory: (category: UdtCategory, payload: SingleUdtCategoryMap) => void;
}>({
  translations: {},
  languages: [],
  isLoading: false,
  refetchUDT: () => {},
  refetchLanguages: () => {},
  mutateSingleCategory: () => {},
});

export const UDTProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [translations, setTranslations] = useState<Record<string, SingleUdtCategoryMap>>({});
  const [languages, setLanguages] = useState<ThemeLanguage[]>([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const fallbackLanguage = languages.find(lang => lang.isFallback)?.lang || null;

  const mutateSingleCategory = useCallback(
    (category: UdtCategory, payload: SingleUdtCategoryMap) => {
      setTranslations(prev => ({
        ...prev,
        [category]: {
          ...payload,
        },
      }));
    },
    [],
  );

  const fetchUDT = useCallback(async () => {
    try {
      const data = await getTranslationsJSON(getAccessTokenSilently);
      setTranslations(data);
    } catch (error) {
      console.log('error:', error);
      //
    }
  }, [getAccessTokenSilently]);

  const fetchLanguages = useCallback(async () => {
    try {
      const data = await getLanguages(getAccessTokenSilently);
      setLanguages(data);
    } catch (error) {
      console.log('error:', error);
      //
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchUDT(), fetchLanguages()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchUDT, fetchLanguages]);

  return (
    <UDTContext.Provider
      value={{
        translations: translations || {},
        languages: languages || [],
        fallbackLanguage,
        isLoading: loading,
        refetchLanguages: fetchLanguages,
        refetchUDT: fetchUDT,
        mutateSingleCategory,
      }}
    >
      {children}
    </UDTContext.Provider>
  );
};

export const useUDT = () => {
  const context = React.useContext(UDTContext);
  if (context === undefined) {
    throw new Error('useUDT must be used within a UDTProvider');
  }
  return context;
};

export const useUDTByCategory = (category: UdtCategory) => {
  const context = React.useContext(UDTContext);
  if (context === undefined) {
    throw new Error('useUDTByCategory must be used within a UDTProvider');
  }

  const { translations, isLoading, mutateSingleCategory, fallbackLanguage } = context;

  const mutateLabels = useCallback(
    (payload: SingleUdtCategoryMap) => {
      mutateSingleCategory(category, payload);
    },
    [category, mutateSingleCategory],
  );

  const { activeLanguage } = useAppTranslation();

  const translate = useCallback(
    (key: string) => {
      return (
        translations[category]?.[key]?.[activeLanguage] ||
        (fallbackLanguage && translations[category]?.[key]?.[fallbackLanguage]) ||
        getValueByPath(PROFILE_PAGE_LABELS, key)?.[activeLanguage] ||
        key
      );
    },
    [translations, category, activeLanguage, fallbackLanguage],
  );

  return {
    isLoading,
    translations: translations[category] || {},
    mutateLabels,
    translate,
  };
};
