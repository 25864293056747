import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as ScannerIcon } from '../../../images/nav/scanner.svg';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import {
  Box,
  Button,
  Divider,
  Paper,
  TablePagination,
  Theme,
  alpha,
  IconButton,
  Typography,
} from '@mui/material';
import { Table } from './components/Table';
import { AddCircleOutline, AddOutlined, Close, FilterList } from '@mui/icons-material';
import { LeadFormDrawer } from './components/LeadFormDrawer';
import { NoLeads } from './components/NoLeads';
import { CONTACTS_TABLE_COLUMNS, initColumnsDef, saveContactsTableColumns } from './utils/columns';
import { SelectedCountCounter } from './components/SelectedCountCounter';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useAppSelector } from '@/application/hooks';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { Account, LeadAPI, LeadSortOption, SyncType } from '@/shared/types/api';
import {
  deleteAccountLead,
  editLead,
  exportLeadsToCrm,
  fetchDownloadLeads,
  getAccountLeads,
} from '@/infrastructure/apis/leadGen';
import { ColumnDefinition } from '../../md/profiles/table/utils/constants';
import { CSS_VARS, LOCAL_STORAGE, routePaths } from '@/infrastructure/constants';
import { MobileLeadRows } from './components/MobileLeadRows';
import { MobileSortDrawer } from './components/MobileSortDrawer';
import { useDispatch } from 'react-redux';
import { updateActionPreferences } from '@/application/actions/account';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { useCrmExport } from '../../md/contacts/hooks/useCrmExport';
import { ExportButton } from '../../md/contacts/components/ExportButton';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { ColSortMenu, SearchField } from '@/components';
import { CustomTableToolbar } from '@/components/Table';
import { useTableSelection } from '@/infrastructure/hooks/useTableSelection';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { ExportConfirmationDialog } from '../components/ExportConfirmationDialog';
import { InternalContactFormField } from '@/shared/types/global';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { useVisibility } from '@/infrastructure/hooks/useVisibility';
import { getLeadInternalSystemFieldKeys } from './utils/helpers';

const ROWS_PER_PAGE = [25, 50, 100];
const DEFAULT_PER_PAGE = 25;

const MyLeads = React.memo((): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const { notify: toast } = useLtNotifications();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const [leads, setLeads] = useState<LeadAPI[]>([]);
  const [leadIds, setLeadIds] = useState<{ id: number }[]>([]);
  const [total, setTotal] = useState(0);
  const [leadsLoading, setLeadsLoading] = useState(true);
  const [selectedLeadForEdit, setSelectedLeadForEdit] = useState<LeadAPI>(null);
  const [addEditDrawerOpened, setAddEditDrawerOpened] = useState(false);
  const [focusNotes, setFocusNotes] = useState(false);

  const [searchWord, setSearchWord] = useState('');
  const account = useAppSelector<Account>(state => state.account);
  const accountId = account.id;
  const sortPreferences = account?.actionPreferences?.myLeads_SortOptions;

  const [sortOptions, setSortOptions] = useState<LeadSortOption>({
    orderBy: 'createdOn',
    sort: 'DESC',
    ...(sortPreferences || {}),
  });

  const [selectedCrmConnectorToExport, setSelectedCrmConnectorToExport] = useState<{
    serviceId: string;
    serviceName: string;
  }>(null);
  const [exporting, setExporting] = useState(false);

  const [mobileFilterDrawerOpened, setMobileFilterDrawerOpened] = useState(false);

  const { t } = useAppTranslation();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktopMui();

  const { config: internalContactFormConfig, loading: internalContactFormConfigLoading } =
    usePrivateThemeConfig<{ fields: InternalContactFormField[] }>(
      THEME_CONFIG_KEYS.INTERNAL_CONTACT_FORM,
    );

  const allowedKeys = useMemo(() => {
    return getLeadInternalSystemFieldKeys(internalContactFormConfig?.fields);
  }, [internalContactFormConfig]);

  const loading = internalContactFormConfigLoading || leadsLoading;

  useEffect(() => {
    if (!allowedKeys) return;
    setColumns(initColumnsDef(allowedKeys));
  }, [allowedKeys]);

  const [columns, setColumns] = useState([]);
  const visibleColumns = columns.filter(x => !x.hidden);

  useDeferredLoader(loading, 'lead-loading-toast');

  const history = useHistory();

  const mountedRef = useRef(false);

  useEffect(() => {
    setLeads([]);
    setTotal(0);
  }, [isDesktop]);

  const fetchLeads = useCallback(async () => {
    try {
      let { leads, total, leadIds } = await getAccountLeads(accountId, getAccessTokenSilently, {
        pageSize: rowsPerPage,
        page,
        searchWord,
        ...sortOptions,
      });
      setLeads(isDesktop ? leads : prev => [...prev, ...leads]);
      setTotal(total);
      setLeadIds(leadIds);
    } catch (error) {
      toast.error(t('error.general'));
    }
  }, [
    accountId,
    getAccessTokenSilently,
    t,
    page,
    rowsPerPage,
    searchWord,
    sortOptions,
    isDesktop,
    toast,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLeadsLoading(true);
      await fetchLeads();
      mountedRef.current = true;
      setLeadsLoading(false);
    };

    fetchData();
  }, [fetchLeads]);

  const fakeDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDesktop || !('IntersectionObserver' in window) || total === 0) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (leads.length < total && !loading) {
          setPage(prev => prev + 1);
        }
      }
    });
    const fakeDiv = fakeDivRef.current;
    if (fakeDiv) {
      observer.observe(fakeDiv);
      return () => {
        observer.unobserve(fakeDiv);
      };
    }
  }, [leads, total, loading, isDesktop]);

  const { editRights } = useEditRights();
  const flag_crmIntegration = usePrivateFeatureFlag('crmIntegration');
  const singleUserCrmExportAllowed = flag_crmIntegration && editRights.singleUserCrmPush;

  const { crmConnectors, hasCallableApideckConnection, apideckConsumer, connectionsToList } =
    useCrmExport(singleUserCrmExportAllowed);

  const {
    selectedCount,
    isAllSelected,
    selectedItems,
    unselectAllItems,
    isAnySelectedOnCurrentPage,
    handleCheckboxClick,
    handleHeaderCheckboxClick,
    handleSelectAll,
    isSelectAllVisible,
  } = useTableSelection<{ id: number }>(leadIds, leads);

  const handleDelete = async (leadId: number) => {
    try {
      await deleteAccountLead(getAccessTokenSilently, accountId, `${leadId}`);
      if (isDesktop) {
        await fetchLeads();
        setAddEditDrawerOpened(false);
      } else {
        setLeads(prev => prev.filter(x => x.id !== leadId));
        setTotal(prev => prev - 1);
      }
      toast.success(t('deleteLeadSuccess'));
    } catch (error) {
      toast.error(t('deleteLeadError'));
    }
  };

  const handleNoteAddEdit = async (lead: LeadAPI, newNote: string) => {
    try {
      const { lt2lt, ...data } = lead;
      const payload = { ...data, notes: newNote };
      await editLead(payload, accountId, getAccessTokenSilently);
      await fetchLeads();
      toast.success(t('leads.leadNoteAddSuccess'));
    } catch (error) {
      toast.success(t('leads.leadNoteAddError'));
    }
  };

  const handleLeadEditClick = (leadId: number, shouldFocusNotes?: boolean) => {
    const lead = leads.find(x => x.id === leadId);
    setAddEditDrawerOpened(true);
    setSelectedLeadForEdit(lead);
    if (shouldFocusNotes) setFocusNotes(true);
  };

  const handleColumnChange = (newCols: ColumnDefinition[]) => {
    setColumns(newCols);
    saveContactsTableColumns(newCols, LOCAL_STORAGE.ltContactsTableColumns);
  };

  const handleDrawerClose = () => {
    setAddEditDrawerOpened(false);
    setFocusNotes(false);
    setSelectedLeadForEdit(null);
  };

  const handleSortChange = (newOptions: LeadSortOption) => {
    if (newOptions.orderBy === sortOptions.orderBy && newOptions.sort === sortOptions.sort) return;
    setPage(0);
    setSortOptions(newOptions);
    dispatch(
      updateActionPreferences(getAccessTokenSilently, {
        myLeads_SortOptions: newOptions,
      }),
    );

    //Reset leads on mobile only to make infinity scroll work correctly
    if (!isDesktop) {
      setLeads([]);
      setTotal(0);
    }
  };

  const handleSearch = (value: string) => {
    setLeadsLoading(true);
    setPage(0);
    setSearchWord(value);
    //Reset leads on mobile only to make infinity scroll work correctly
    if (!isDesktop) {
      setLeads([]);
    }
  };

  const handleCsvExport = () => {
    fetchDownloadLeads(account.firstName, 'csv', getAccessTokenSilently, {
      leadIds: (selectedItems || []).map(l => l.id),
    });
  };

  const handleExcelExport = () => {
    fetchDownloadLeads(account.firstName, 'xlsx', getAccessTokenSilently, {
      leadIds: (selectedItems || []).map(l => l.id),
    });
  };

  const handleCrmExport = useCallback(
    async (campaignId: string) => {
      if (!hasCallableApideckConnection) {
        history.push(routePaths.MD.CUSTOMISESETTINGS.CRM_INTEGRATION);
        return;
      }

      const leads = selectedItems;
      if (!leads || leads.length === 0) {
        toast.error(t('mdLeads.crmExport.emptySelection'));
        return;
      }

      try {
        setExporting(true);
        await exportLeadsToCrm(getAccessTokenSilently, {
          leadIds: leads.map(l => l.id),
          serviceId: selectedCrmConnectorToExport.serviceId,
          campaignId,
        });
        setSelectedCrmConnectorToExport(null); // close dialog
        fetchLeads();
        setTimeout(() => {
          fetchLeads();
        }, 3000);

        toast.success(t('mdLeads.crmExport.startSuccess'));
      } catch (error) {
        toast.error(t('mdLeads.crmExport.startFail'));
        console.log(error);
      }
      setExporting(false);
    },
    [
      fetchLeads,
      selectedItems,
      getAccessTokenSilently,
      hasCallableApideckConnection,
      history,
      t,
      toast,
      selectedCrmConnectorToExport?.serviceId,
    ],
  );

  const saveCallback = () => {
    fetchLeads();
    if (hasCallableApideckConnection && apideckConsumer.syncType === SyncType.AUTOMATIC) {
      setTimeout(() => {
        fetchLeads();
      }, 3000);
    }
  };

  const flag_businessCardScanner = usePrivateFeatureFlag('businessCardScanner');
  const { isVisible, isLoading: isVisibilityConfigLoading } = useVisibility();

  const filtersApplied = !!searchWord;

  const scanCardBtn =
    flag_businessCardScanner && isVisible('nonAdmin.singleUserContacts.businessCardScanner') ? (
      <Button
        sx={{ svg: { width: '2rem', height: '2rem' } }}
        variant='outlined'
        color='primary'
        startIcon={<ScannerIcon />}
        onClick={() => history.push(routePaths.CONTACTS.SCANNER)}
      >
        {t('scanCard')}
      </Button>
    ) : null;

  const addContactBtn = (
    <Button
      startIcon={<AddOutlined />}
      onClick={() =>
        isDesktop ? setAddEditDrawerOpened(true) : history.push(routePaths.CONTACTS.ADD)
      }
    >
      {t('addContact')}
    </Button>
  );

  if (isVisibilityConfigLoading || (loading && !filtersApplied && !mountedRef.current)) return null;

  const hideTable =
    (!loading && total === 0 && !filtersApplied) ||
    !isVisible('nonAdmin.singleUserContacts.contactsTable');

  const content = hideTable ? (
    <NoLeads
      actions={[scanCardBtn, addContactBtn]}
      hideText={!isVisible('nonAdmin.singleUserContacts.contactsTable')}
    />
  ) : (
    <Box
      p={isDesktop && 2}
      sx={isDesktop && { height: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})` }}
    >
      <Paper
        variant='outlined'
        sx={{
          borderTop: !isDesktop && 'none',
          bgcolor: !isDesktop && 'transparent',
          display: loading && !filtersApplied && !mountedRef.current ? 'none' : 'flex',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <CustomTableToolbar
          sx={{
            py: '1.6rem',
            zIndex: 2,
            position: isDesktop ? 'static' : 'sticky',
            top: CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR,
          }}
        >
          <SearchField
            placeholder={t('leads.searchPlaceholder')}
            sx={{ flexGrow: 1 }}
            onSearch={handleSearch}
          />
          {!isDesktop && (
            <IconButton
              size='large'
              onClick={() => setMobileFilterDrawerOpened(true)}
              aria-label={t('filter.title')}
              aria-haspopup='dialog'
            >
              <FilterList />
            </IconButton>
          )}
          {isDesktop && (
            <>
              <Box sx={{ flexGrow: 1 }} />
              {scanCardBtn}
              {addContactBtn}
            </>
          )}
        </CustomTableToolbar>
        {isDesktop && <Divider />}
        <CustomTableToolbar
          sx={(theme: Theme) => ({
            bgcolor: alpha(theme.palette.primary.main, 0.04),
            zIndex: 1,
            ...(selectedCount > 0 && !isDesktop
              ? {
                  position: 'sticky',
                  top: `calc(${CSS_VARS.LT_MOBILE_HEADER_HEIGHT_VAR} + 8.8rem)`,
                }
              : {}),
          })}
        >
          {selectedCount > 0 && (
            <>
              {isDesktop && (
                <SelectedCountCounter
                  total={total}
                  selectAllVisible={isSelectAllVisible}
                  onSelectAll={handleSelectAll}
                  selectedCount={selectedCount}
                />
              )}
              <Button
                variant='text'
                color='primary'
                startIcon={<Close />}
                onClick={unselectAllItems}
              >
                {t('deselectAll')}
              </Button>
              {!isDesktop && <Box flexGrow={1} />}
              <ExportButton
                crmList={singleUserCrmExportAllowed ? connectionsToList : []}
                onCrmExportClick={(serviceId, serviceName) => {
                  singleUserCrmExportAllowed &&
                    setSelectedCrmConnectorToExport({ serviceId, serviceName });
                }}
                onCsvExportClick={handleCsvExport}
                onExcelExportClick={handleExcelExport}
              />
            </>
          )}
          {isDesktop && <Box flexGrow={1} />}
          {isDesktop && (
            <ColSortMenu
              columnDefs={columns}
              setColumnDefs={handleColumnChange}
              reset={() => handleColumnChange(CONTACTS_TABLE_COLUMNS)}
            />
          )}
        </CustomTableToolbar>
        {Boolean(leads.length) ? (
          <>
            {isDesktop ? (
              <>
                <Table
                  leads={leads}
                  onCheckboxClick={handleCheckboxClick}
                  onHeaderCheckboxClick={handleHeaderCheckboxClick}
                  selectedItems={selectedItems}
                  isAllSelected={isAllSelected}
                  isAnySelectedOnCurrentPage={isAnySelectedOnCurrentPage}
                  onDelete={handleDelete}
                  onLeadEditClick={handleLeadEditClick}
                  columns={visibleColumns}
                  sortOptions={sortOptions}
                  onSortOptionsChange={handleSortChange}
                  crmConnectors={crmConnectors}
                  showCrmExport={singleUserCrmExportAllowed}
                />
                <TablePagination
                  rowsPerPageOptions={ROWS_PER_PAGE}
                  component='div'
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(_, page) => setPage(page)}
                  labelRowsPerPage={t('rowsPerPage')}
                  onRowsPerPageChange={({ target: { value } }) => {
                    setRowsPerPage(+value);
                    setPage(0);
                  }}
                  nextIconButtonProps={{ 'aria-label': t('showNextPage') }}
                  backIconButtonProps={{ 'aria-label': t('showPreviousPage') }}
                />
              </>
            ) : (
              <MobileLeadRows
                leads={leads}
                onCheckboxClick={handleCheckboxClick}
                selectedItems={selectedItems}
                onDelete={handleDelete}
                onNoteAddEdit={handleNoteAddEdit}
                crmConnectors={crmConnectors}
                showCrmExport={singleUserCrmExportAllowed}
              />
            )}
          </>
        ) : (
          <Typography variant='h2' textAlign='center' py={5}>
            {t('leads.noLeadsSearchResult')}
          </Typography>
        )}
      </Paper>
      {!isDesktop && (
        <>
          <div ref={fakeDivRef} />
          <LtActionButtonBar
            customActionsPost={[
              {
                startIcon: <AddCircleOutline />,
                onClick: () => history.push(routePaths.CONTACTS.ADD),
                text: t('addContact'),
              },
            ]}
            renderRelativeBox
          />
        </>
      )}
    </Box>
  );

  return (
    <>
      {content}
      <LeadFormDrawer
        opened={addEditDrawerOpened}
        onClose={handleDrawerClose}
        onSave={saveCallback}
        onDelete={handleDelete}
        focusNotes={focusNotes}
        lead={selectedLeadForEdit}
      />
      <MobileSortDrawer
        opened={mobileFilterDrawerOpened}
        onClose={() => setMobileFilterDrawerOpened(false)}
        options={sortOptions}
        onChange={handleSortChange}
      />
      <ExportConfirmationDialog
        exporting={exporting}
        onConfirm={handleCrmExport}
        isAllSelected={isAllSelected}
        onCancel={() => setSelectedCrmConnectorToExport(null)}
        selectedCount={selectedCount}
        selectedCrmConnectorToExport={selectedCrmConnectorToExport}
      />
    </>
  );
});

export default MyLeads;
