import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import {} from '@/infrastructure/apis/edit-profile';
import FeatureTeaser from '../../teaser/featureTeaser';
import { FEATURE, THEME_CONFIG_KEYS } from '@/shared/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import teaserImage from '@/views/images/teaser/contact-form.jpg';
import { fetchThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { writeThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import Sorter from './ContactFieldsSorter';
import { ContactFormField, InternalContactFormField, ThemeBoxStyle } from '@/shared/types/global';
import { Account, LeadAPI, StaticProfileConfig } from '@/shared/types/api';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { ContactForm } from '@/components/ContactForm';
import { fetchProfile } from '@/infrastructure/apis/profile';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { useAppSelector } from '@/application/hooks';
import { TFunction } from 'i18next';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { defaultPalette } from '@/config/theme/palette';
import { useLeadCustomFields } from '@/infrastructure/hooks/useLeadCustomFields';
import { getFieldLabel } from './utils';
import { SortableField } from './SortableField';
import { useUDT, useUDTByCategory } from '@/infrastructure/hooks/useUDT';

const FIELDS_TO_HIDE = ['gdprLegalBasis', 'businessCardUrls', 'profileImageUrl'];

type StaticLeadGenConfig = Omit<
  StaticProfileConfig['leadGen'],
  'fields' | 'flow' | 'leadCustomFields'
>;

const getDefaultLeadGenConfig = (t: TFunction): StaticLeadGenConfig => ({
  submitButtonColor: defaultPalette.primaryButton.main,
  labels: {
    title: t('leaveYourContact'),
    gdprConsent: t('gdprConsent.pt1'),
    submitButton: t('submit'),
    field: {},
  },
});

export const ExternalForm = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  useDeferredLoader(loading, 'md-settings-contact-form');

  if (isFeatureAllowed(FEATURE.LEAD_GEN_FORM_CONFIG)) {
    return <FeatureContent />;
  } else {
    return (
      <FeatureTeaser text={t('upgradeTeaser.feature.leadGenContactForm')} imageSrc={teaserImage} />
    );
  }
};

const fetchInternalFormConfig = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value: config } = await fetchThemeConfig<{ fields: InternalContactFormField[] }>(
    getAccessTokenSilently,
    THEME_CONFIG_KEYS.INTERNAL_CONTACT_FORM,
  );

  const fields = config?.fields || [];
  return fields.filter(({ name }) => !FIELDS_TO_HIDE.includes(name as string));
};

const fetchExternalFormConfig = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { value: contactForm } = await fetchThemeConfig<{
    fields: Array<ContactFormField>;
  }>(getAccessTokenSilently, THEME_CONFIG_KEYS.EXTERNAL_CONTACT_FORM);

  return contactForm?.fields || [];
};

const CardRenderer = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Card>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

const FeatureContent = () => {
  const { notify: toast } = useLtNotifications();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [contactFormFields, setContactFormFields] = useState<{
    shown: ContactFormField[];
    notShown: ContactFormField[];
  }>({
    shown: [],
    notShown: [],
  });
  const [staticLeadGenConfig, setStaticLeadGenConfig] = useState<
    StaticLeadGenConfig & { boxStyle: ThemeBoxStyle }
  >(null);

  useDeferredLoader(isDataLoading, 'data-templates-contact-form-loader-id');

  const { getAccessTokenSilently } = useAuth0();
  const { t, activeLanguage } = useAppTranslation();
  const account: Account = useAppSelector(state => state.account);

  const { leadCustomFields, loading: leadCustomFieldsLoading } = useLeadCustomFields(account.id);

  const {
    translations: systemLabels,
    translate,
    isLoading: systemFieldLabelsLoading,
  } = useUDTByCategory('profile--system-labels');

  const { fallbackLanguage } = useUDT();

  const systemFieldLabels = contactFormFields?.shown
    ?.filter(field => !field.isCustom)
    .reduce(
      (acc, field) => ({ ...acc, [field.name]: translate(`attributeLabels.${field.name}`) }),
      {},
    ) as Record<keyof LeadAPI, string>;

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const [internalFormConfig, externalFormConfig, staticProfileConfig] = await Promise.all([
          fetchInternalFormConfig(getAccessTokenSilently),
          fetchExternalFormConfig(getAccessTokenSilently),
          fetchProfile(account.username, activeLanguage || 'en'),
        ]);
        const { profile } = staticProfileConfig;
        setStaticLeadGenConfig({
          ...(profile.leadGen || getDefaultLeadGenConfig(t)),
          boxStyle: profile.config.metaTheme.boxStyle,
        });

        const notShownFields = internalFormConfig.filter(
          x => !externalFormConfig.some(({ name }) => name === x.name),
        );

        setContactFormFields({
          shown: externalFormConfig,
          notShown: notShownFields,
        });
      } catch (error) {
        toast.error(t('error.general'));
      }
      setIsDataLoading(false);
    };

    fetchData();
  }, [getAccessTokenSilently, t, activeLanguage, account.username, toast]);

  const handleSave = useCallback(
    async (newContactFormFields: { shown: ContactFormField[]; notShown: ContactFormField[] }) => {
      const shownFields = newContactFormFields.shown;
      if (!shownFields?.length) {
        toast.error(t('contactFormEdit.atLeastOneRequired'));
        return;
      }
      if (!newContactFormFields.shown?.some(({ required }) => required)) {
        toast.error(t('contactFormEdit.saveTooltip'));
        return;
      }
      setContactFormFields(newContactFormFields);

      const payload = {
        fields: shownFields,
      };
      try {
        await writeThemeConfig(getAccessTokenSilently, {
          key: THEME_CONFIG_KEYS.EXTERNAL_CONTACT_FORM,
          value: payload,
        });
        toast.success(t('changesSaved'), { id: 'contact-form-saving' });
      } catch (error) {
        toast.error(t('error.general'), { id: 'contact-form-saving' });
      }
    },
    [toast, getAccessTokenSilently, t],
  );

  const handleSortEnd = useCallback(
    (fields: { shown: ContactFormField[]; notShown: ContactFormField[] }) => {
      const newFields = {
        shown:
          fields.shown?.length > 1
            ? fields.shown
            : fields.shown.map(x => ({ ...x, required: true })),
        notShown: fields.notShown,
      };
      handleSave(newFields);
    },
    [handleSave],
  );

  const handleRequiredToggle = useCallback(
    (index: number) => {
      const field = contactFormFields.shown[index];
      if (!field) return;
      const newShownFields = contactFormFields.shown
        .slice(0, index)
        .concat({ ...field, required: !field.required })
        .concat(contactFormFields.shown.slice(index + 1));
      handleSave({ shown: newShownFields, notShown: contactFormFields.notShown });
    },
    [contactFormFields, handleSave],
  );

  const getTranslatedFieldLabel = useCallback(
    (field: ContactFormField) => {
      return getFieldLabel(
        field,
        leadCustomFields,
        t,
        systemLabels,
        activeLanguage,
        fallbackLanguage,
      );
    },
    [leadCustomFields, t, activeLanguage, systemLabels, fallbackLanguage],
  );

  const renderSortableItem = useCallback(
    (field: ContactFormField) => {
      const index = contactFormFields.shown.findIndex(({ name }) => name === field.name);
      const chips = [field.isCustom && t('custom')].filter(Boolean);
      return (
        <SortableField
          title={getTranslatedFieldLabel(field)}
          chips={chips}
          key={field.name}
          action={
            index > -1 && (
              <FormControlLabel
                control={
                  <Switch
                    onFocus={e => e.stopPropagation()}
                    checked={field.required}
                    onChange={() => handleRequiredToggle(index)}
                  />
                }
                label={t('contactFormEdit.required')}
              />
            )
          }
        />
      );
    },
    [contactFormFields, handleRequiredToggle, t, getTranslatedFieldLabel],
  );

  if (isDataLoading || leadCustomFieldsLoading || systemFieldLabelsLoading) return null;

  return (
    <>
      <Grid container spacing={1.6}>
        <Grid item xs={12} xl={8}>
          <CardRenderer>
            <Typography sx={{ mb: 1.6 }} variant='h2'>
              {t('contactFormEdit.title')}
            </Typography>
            {contactFormFields && (
              <Sorter
                fields={contactFormFields}
                onSortEnd={handleSortEnd}
                renderItem={renderSortableItem}
                getName={field => getTranslatedFieldLabel(field)}
              />
            )}
          </CardRenderer>
        </Grid>
        <Grid item xs={6} xl={4}>
          <Card>
            <CardHeader
              sx={theme => ({ bgcolor: theme.palette.action.hover })}
              title={t('livePreview')}
              titleTypographyProps={{
                component: 'h3',
                variant: 'h2',
                color: 'secondary',
              }}
            />
            <Divider />
            <CardContent>
              {staticLeadGenConfig && (
                <ContactForm
                  {...staticLeadGenConfig}
                  labels={{
                    ...(staticLeadGenConfig?.labels || getDefaultLeadGenConfig(t).labels),
                    field: systemFieldLabels,
                  }}
                  fields={contactFormFields.shown}
                  leadCustomFields={leadCustomFields}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
