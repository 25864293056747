import useTierInfo, { TierInfo } from '@/infrastructure/hooks/useTierInfo';
import { FeatureSet } from '@/shared/types/api';
import { RequireAtMostOne } from '@/shared/types/util';
import FeatureTeaser from '@/views/pages/md/teaser/featureTeaser';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

type BaseProps = RequireAtMostOne<
  {
    feature?: keyof FeatureSet;
    gatedCondition?: (tierInfo: TierInfo) => boolean;
    redirectToWhenStarter?: string;
    text?: string;
    image?: string;
    loadingComponent?: React.ReactNode | null;
  },
  'feature' | 'gatedCondition'
>;

type ComponentProps = BaseProps & {
  children?: React.ReactNode;
};

const FeatureSetGuard = ({
  children,
  feature,
  gatedCondition,
  redirectToWhenStarter,
  text,
  image,
  loadingComponent = null,
}: ComponentProps) => {
  const tierInfo = useTierInfo();
  const { loading, isFeatureAllowed, isStarter } = tierInfo;

  const { t } = useTranslation();

  if (loading) return <>{loadingComponent}</>;

  if ((feature && !isFeatureAllowed(feature)) || (gatedCondition && gatedCondition(tierInfo))) {
    if (isStarter && redirectToWhenStarter) {
      return <Redirect to={redirectToWhenStarter} />;
    } else {
      return <FeatureTeaser text={t(text)} imageSrc={image} mt={2} />;
    }
  }

  return <>{children}</>;
};

export default FeatureSetGuard;

//HOC
export function withFeatureSetGuard<T>(Component: React.ComponentType<T>, args: BaseProps) {
  return (props: T) => (
    <FeatureSetGuard {...args}>
      <Component {...props} />
    </FeatureSetGuard>
  );
}
