import { LeadCustomFieldAPI } from '@/shared/types/api';
import { Box, InputAdornment, TextField } from '@mui/material';
import { LeadAPI, LeadDataProps } from '@/shared/types/api';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { BusinessCardImage, BusinessCardImageType } from './components/BusinessCardImage';
import { NotesField } from './components/NotesField';
import { ProfileImage } from './components/ProfileImage';
import { SingleCustomFieldRenderer } from './components/LeadCustomFieldsRenderer';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import moment from 'moment';
import { InternalContactFormField, LeadCustomFieldValueType } from '@/shared/types/global';
import { removeLeading } from '@/shared/util';
import { LanguageSelector } from './components/LanguageSelector';
import { GDPRSelector } from './components/GDPRSelector';
import { isPhoneFaxField } from '@/views/pages/edit-bio/utils';
import { LEAD_MULTILINE_FIELDS, THEME_CONFIG_KEYS } from '@/shared/constants';
import { useUDTByCategory } from '@/infrastructure/hooks/useUDT';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

type Props = {
  focusNotes?: boolean;
  initialLead?: Partial<LeadAPI>;
  fields: InternalContactFormField[];
  leadCustomFields: LeadCustomFieldAPI[];
  isLt2Lt?: boolean;
  isPreview?: boolean;
};

export const LeadForm = forwardRef((props: Props, ref) => {
  const { focusNotes, leadCustomFields, fields, initialLead, isLt2Lt, isPreview } = props;

  const { withUnsavedSetter } = useUnsavedStatusSetter();

  const [leadData, _setLeadData] = useState<Partial<LeadAPI>>({});
  const setLeadData = isPreview ? _setLeadData : withUnsavedSetter(_setLeadData);

  const [businessCardImages, setBusinessCardImages] = useState<BusinessCardImageType[]>([]);
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);

  const { translate: translateSystemLabels } = useUDTByCategory('profile--system-labels');
  const translate = (key: string) => translateSystemLabels(`attributeLabels.${key}`);

  const { t } = useAppTranslation();

  useEffect(() => {
    if (!initialLead) return;

    const { businessCardUrls, ...restFields } = initialLead;
    _setLeadData(restFields);
    setBusinessCardImages(businessCardUrls?.map(url => ({ file: null, url })) || []);
  }, [initialLead]);

  useImperativeHandle(ref, () => ({
    getValues: () => {
      return {
        leadData,
        businessCardImages,
        profileImageFile,
      };
    },
    resetValues: () => {
      setProfileImageFile(null);
    },

    isAllFieldsEmpty: () => {
      return Object.values(leadData).every(value => !value);
    },
  }));

  const { config: gdprOptions } = usePrivateThemeConfig<string[]>(
    THEME_CONFIG_KEYS.GDPR_LEGAL_BASIS_OPTIONS,
  );

  const handleCustomFieldChange = (value: LeadCustomFieldValueType, typeId: number) => {
    const currentFieldValues = leadData.leadCustomFieldValues || [];
    const currentFieldValue = currentFieldValues.find(item => item.typeId === typeId);
    let newValue = null;
    if (currentFieldValue) {
      newValue = currentFieldValues.map(item =>
        item.typeId === typeId ? { typeId, value: value, id: item.id } : item,
      );
    } else {
      newValue = [...currentFieldValues, { typeId, value: value, id: null }];
    }
    setLeadData({ ...leadData, leadCustomFieldValues: newValue });
  };

  // For lt2lt show only custom fields + notes field, in the same order as in the theme config
  const fieldsToRender = isLt2Lt
    ? fields.filter(field => field.isCustom || field.name === 'notes')
    : fields;

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {fieldsToRender.map(({ name, isCustom }) => {
        if (isCustom) {
          const fieldType = leadCustomFields.find(field => field.id === name);
          if (!fieldType) return null;
          return (
            <SingleCustomFieldRenderer
              key={fieldType.id}
              value={
                leadData.leadCustomFieldValues?.find(option => {
                  return option.typeId === fieldType.id;
                })?.value || ''
              }
              onChange={handleCustomFieldChange}
              fieldType={fieldType}
            />
          );
        }

        const field = name as keyof LeadDataProps;
        const value = leadData[field];

        if (field === 'profileImageUrl') {
          return (
            <ProfileImage
              onChange={(file: File) => setProfileImageFile(file)}
              value={leadData.profileImageUrl}
            />
          );
        }

        if (field === 'businessCardUrls') {
          return (
            <BusinessCardImage
              onChange={(values: { file: File | null; url: string }[]) =>
                setBusinessCardImages(values)
              }
              businessCardImages={businessCardImages}
            />
          );
        }

        if (field === 'notes') {
          return (
            <NotesField
              focused={focusNotes}
              value={leadData.notes || ''}
              onChange={value => setLeadData({ ...leadData, notes: value })}
              title={translate('notes')}
            />
          );
        }

        if (field === 'gdprLegalBasis') {
          return (
            <GDPRSelector
              gdprOptions={gdprOptions || []}
              onGdprSelect={value => setLeadData({ ...leadData, gdprLegalBasis: value })}
              value={leadData.gdprLegalBasis || ''}
            />
          );
        }

        if (field === 'preferredLang') {
          return (
            <LanguageSelector
              onLangSelect={(value: string) => setLeadData({ ...leadData, preferredLang: value })}
              value={leadData.preferredLang || ''}
              labelOverride={translate('preferredLang')}
            />
          );
        }

        if (field === 'createdOn') {
          return (
            <TextField
              value={moment(leadData.createdOn || new Date())
                .format()
                .substring(0, 16)}
              type='datetime-local'
              label={t('createdOn')}
              onChange={e => setLeadData({ ...leadData, createdOn: e.target.value })}
              fullWidth
            />
          );
        }

        return (
          <TextField
            fullWidth
            key={field}
            label={translate(field)}
            value={
              isPhoneFaxField(field) && typeof value === 'string'
                ? removeLeading(value, '+')
                : value || ''
            }
            onChange={e => setLeadData({ ...leadData, [field]: e.target.value })}
            multiline={LEAD_MULTILINE_FIELDS.includes(field)}
            InputProps={{
              startAdornment: isPhoneFaxField(field) ? (
                <InputAdornment position='start'>+</InputAdornment>
              ) : null,
            }}
          />
        );
      })}
    </Box>
  );
});
