import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { CrmConnectorType, CrmExportEvent, CrmExportEventStatus } from '@/shared/types/api';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';
import { useState } from 'react';
import { WarningRounded } from '@mui/icons-material';

const getErrorMessage = (event: CrmExportEvent, lang: string) => {
  const errors = event.error?.errors;
  if (!errors) return '';
  return errors
    .map(error => error[lang])
    .filter(Boolean)
    .join(', ');
};

export const ExportEventRenderer = ({
  event,
  crmConnectors,
}: {
  event: CrmExportEvent;
  crmConnectors: CrmConnectorType[];
}) => {
  const [open, setOpen] = useState(false);
  const { t, activeLanguage } = useAppTranslation();
  const connector = crmConnectors.find(
    connector => connector.id === event.apideckConnection?.seviceId,
  );

  const isDesktop = useIsDesktopMui();

  if (!connector) return null;

  const crm = connector.name;
  const exportDate = moment(event.createdOn).locale(activeLanguage).format('lll');

  const getTooltipText = () => {
    switch (event.status) {
      case CrmExportEventStatus.ERROR:
        return `${t(`crmIntegration.exportFailed`, {
          crm,
          datetime: exportDate,
        })}\n${getErrorMessage(event, activeLanguage)}`;
      case CrmExportEventStatus.PENDING:
        return t(`crmIntegration.exportStarted`, { crm, datetime: exportDate });
      case CrmExportEventStatus.ERROR_DUPLICATE:
        return t(`crmIntegration.exportFailedDuplicate`, { crm, datetime: exportDate });
      default:
        return t(`crmIntegration.exported`, { crm, datetime: exportDate });
    }
  };

  const tooltipText = getTooltipText();

  const statusIconStyles = {
    width: '1.75rem',
    height: '1.75rem',
    position: 'relative',
    right: '.5rem',
  };

  const controlledProps = isDesktop
    ? {}
    : {
        open: open,
        onClick: e => {
          e.stopPropagation();
          setOpen(!open);
        },
        onClose: () => setOpen(false),
        onOpen: () => setOpen(true),
      };

  return (
    <Tooltip
      title={tooltipText}
      placement='top'
      PopperProps={{ sx: { whiteSpace: 'pre-wrap', textAlign: 'center' } }}
      {...controlledProps}
    >
      <Box position='relative' display='flex' alignItems='start'>
        <img
          style={{ width: '2.4rem', height: '2.4rem', padding: '.1rem' }}
          src={connector.iconUrl}
          alt={connector.name}
        />
        {event.status === CrmExportEventStatus.ERROR && (
          <ErrorIcon sx={statusIconStyles} color='error' />
        )}
        {event.status === CrmExportEventStatus.PENDING && (
          <PendingIcon sx={statusIconStyles} color='info' />
        )}
        {event.status === CrmExportEventStatus.ERROR_DUPLICATE && (
          <WarningRounded sx={statusIconStyles} color='warning' />
        )}
      </Box>
    </Tooltip>
  );
};
