import { PropsWithChildren, useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import config from '@/config/config';
import { setAuthCallbackState } from '@/application/actions/landing';
import { routePaths } from '../constants';
import i18nMain from '@/config/i18n';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const defaultClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const SESSION_STORAGE_KEY_AUTH0_CONFIG = 'lt-auth0-config';
const isCustomDomain = window.location.origin !== config.REACT_APP_URL;

export default function LtAuth0Provider(props: PropsWithChildren<{}>) {
  const dispatch = useDispatch();

  const [clientId, setClientId] = useState<string>(initClientId());
  useEffect(() => {
    const fetchClientId = async () => {
      let clientId: string;
      try {
        const fetchedConfig = await axios.get<{ clientId?: string }>('/auth0-config.json');
        clientId = fetchedConfig.data.clientId || defaultClientId;
      } catch {
        clientId = defaultClientId;
      } finally {
        sessionStorage.setItem(SESSION_STORAGE_KEY_AUTH0_CONFIG, JSON.stringify({ clientId }));
      }
      setClientId(clientId);
    };
    if (!clientId) fetchClientId();
  }, [clientId]);

  if (!clientId) {
    return <>{props.children}</>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={state => {
        if (
          state.signup &&
          (state.ltSignupCode ||
            state.signupToTier ||
            state.ltReferralKey ||
            state.ltSignupToTheme ||
            state.utmParams)
        ) {
          axios.interceptors.request.use(afterSignupInterceptor(state), error => {
            return Promise.reject(error);
          });
        }
        dispatch(setAuthCallbackState(state));
      }}
      useRefreshTokens={isCustomDomain}
      scope='https://lemontaps.com/claim/'
      audience={config.API_BASE_URL}
      redirectUri={window.location.origin + routePaths.HOME}
    >
      {props.children}
    </Auth0Provider>
  );
}

const initClientId = () => {
  if (!isCustomDomain) return defaultClientId;
  try {
    const storedConfig = sessionStorage.getItem(SESSION_STORAGE_KEY_AUTH0_CONFIG);
    const parsedConfig = JSON.parse(storedConfig);
    if (typeof parsedConfig.clientId === 'string') return parsedConfig.clientId;
  } catch {}
  return null;
};

let askedForUnitName = false;
const afterSignupInterceptor = state => config => {
  let newUnitName = state.ltUnitName || '';
  if (state.ltSignupToUnit && !askedForUnitName) {
    if (state.ltUnitName) {
      newUnitName = state.ltUnitName;
    } else {
      while (!newUnitName) {
        newUnitName = prompt(
          i18nMain.language?.startsWith('de')
            ? 'Gib hier Deinen Firmennamen ein:'
            : 'Please enter your company name here:',
        );
      }
    }
    askedForUnitName = true;
  }

  if (state.ltSignupToTheme && sessionStorage.getItem('lt-signup2theme-reload') !== 'reloaded') {
    sessionStorage.setItem('lt-signup2theme-reload', 'true');
  }
  if (config.params) {
    config.params = {
      ...config.params,
      'lt-signup-code': state.ltSignupCode,
      'lt-signup-to-tier': state.signupToTier?.tier,
      'lt-signup-to-trial': state.signupToTier?.trial,
      'lt-plan': state.signupToTier?.plan,
      'lt-referral-key': state.ltReferralKey,
      'lt-signup-to-theme': state.ltSignupToTheme ? state.ltThemeName : undefined,
      'lt-unit-name': state.ltSignupToUnit ? newUnitName : undefined,
      'lt-token': state.ltToken ? state.ltToken : undefined,
      'utm-params': state.utmParams ? state.utmParams : undefined,
    };
  } else {
    config.params = {
      'lt-signup-code': state.ltSignupCode,
      'lt-signup-to-tier': state.signupToTier?.tier,
      'lt-signup-to-trial': state.signupToTier?.trial,
      'lt-plan': state.signupToTier?.plan,
      'lt-referral-key': state.ltReferralKey,
      'lt-signup-to-theme': state.ltSignupToTheme ? state.ltThemeName : undefined,
      'lt-unit-name': state.ltSignupToUnit ? newUnitName : undefined,
      'lt-token': state.ltToken ? state.ltToken : undefined,
      'utm-params': state.utmParams ? state.utmParams : undefined,
    };
  }
  return config;
};
