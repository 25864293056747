import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActions,
  Button,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useState, forwardRef, useImperativeHandle } from 'react';
import { SetupScimPopup } from './SetupScimPopup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { RegenerateScimTokenPopup } from './RegenerateScimTokenPopup';
import { deleteScimConfiguration, toggleScimInvitation } from '@/infrastructure/apis/md/idp';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeIdentityProviderDetailed } from '@/shared/types/api';
import { LtDialog, SettingsMenu } from '@/components';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { MenuItemType } from '@/components/SettingsMenu';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';

type Props = {
  isScimActive: boolean;
  refetchIdpSettings: () => void;
  themeIdp: ThemeIdentityProviderDetailed;
};

const hidden = { opacity: 0, pointerEvents: 'none' };

export const ScimCard = forwardRef(({ themeIdp, isScimActive, refetchIdpSettings }: Props, ref) => {
  const { notify: toast } = useLtNotifications();

  const [setupPopupOpened, setSetupPopupOpened] = useState(false);
  const [regeneratePopupOpened, setRegeneratePopupOpened] = useState(false);
  const [scimDeletePopupOpened, setScimDeletePopupOpened] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const { isFeatureAllowed } = useTierInfo();

  useImperativeHandle(ref, () => ({
    openSetupPopup: () => {
      setSetupPopupOpened(true);
    },
  }));

  const handleDeleteScim = async () => {
    setIsDeleting(true);
    try {
      await deleteScimConfiguration(getAccessTokenSilently, id);
      toast.success(t('idp.scim.deleteConfigurationSuccess'));
      setScimDeletePopupOpened(false);
      refetchIdpSettings();
    } catch {
      toast.success(t('idp.scim.deleteConfigurationError'));
    }
    setIsDeleting(false);
  };

  const handleSetupClose = () => {
    setSetupPopupOpened(false);
    refetchIdpSettings();
  };

  const handleRegenerateClose = () => {
    setRegeneratePopupOpened(false);
    refetchIdpSettings();
  };

  const handleToggle = async () => {
    try {
      await toggleScimInvitation(getAccessTokenSilently, id);
      await refetchIdpSettings();
      toast.success(t('saved'));
    } catch (error) {
      toast.error(t('idp.scim.updateConfigurationError'));
    }
  };

  const settingsMenuItems: MenuItemType[] = [
    {
      icon: <EditIcon />,
      label: t('idp.scim.regenerateApiKey'),
      onClick: () => setRegeneratePopupOpened(true),
    },
    {
      icon: <DeleteOutlineIcon />,
      label: t('idp.deleteConfiguration'),
      onClick: () => setScimDeletePopupOpened(true),
    },
  ];

  if (!isFeatureAllowed(FEATURE.PROFILE_SYNC)) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader
          title={t('idp.scim.title')}
          titleTypographyProps={{ component: 'h4' }}
          sx={{ position: 'relative' }}
          action={
            isScimActive && (
              <Box sx={{ position: 'absolute', right: '1rem', top: '0.5rem' }}>
                <SettingsMenu
                  iconButtonProps={{
                    'aria-label': t('ariaOpenScimSettings'),
                    'aria-haspopup': 'menu',
                  }}
                  menuItems={settingsMenuItems}
                />
              </Box>
            )
          }
        />
        <CardContent>
          <Typography variant='body1' color='text.secondary'>
            {isScimActive ? t('idp.scim.isActive') : t('idp.scim.description')}
          </Typography>
        </CardContent>
        <CardActions sx={{ px: 1.6, pb: 2 }}>
          {isScimActive && (
            <FormControlLabel
              control={
                <Switch
                  checked={themeIdp.scim?.inviteUponProvisioning || false}
                  onChange={handleToggle}
                />
              }
              label={t('idp.scim.inviteUserUponProvisioning')}
            />
          )}
          <Button
            endIcon={<EastIcon />}
            sx={{ ml: 'auto', ...(isScimActive ? hidden : {}) }}
            onClick={() => setSetupPopupOpened(true)}
          >
            {t('idp.scim.setupProvisioning')}
          </Button>
        </CardActions>
      </Card>
      <SetupScimPopup open={setupPopupOpened} onClose={handleSetupClose} />
      <RegenerateScimTokenPopup open={regeneratePopupOpened} onClose={handleRegenerateClose} />
      <LtDialog
        open={scimDeletePopupOpened}
        onClose={() => setScimDeletePopupOpened(false)}
        onCancel={() => setScimDeletePopupOpened(false)}
        title={t('requestDelete')}
        onDelete={handleDeleteScim}
        loading={isDeleting}
      >
        <Typography>{t('idp.scim.deleteConfigurationConfirmation')}</Typography>
      </LtDialog>
    </>
  );
});
