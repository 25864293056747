import { getContactFormTranslationKeyByField } from '@/shared/util';
import { useTranslation } from 'react-i18next';
import { TextField, CardProps } from '@mui/material';
import { useEffect, useRef } from 'react';

type Props = CardProps & {
  value: string;
  onChange: (value: string) => void;
  focused?: boolean;
};

export const NotesField = ({ value, onChange, focused, title }: Props) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focused && inputRef.current) {
      const input = inputRef.current;
      input.focus();
      const length = input.value?.length || 0;
      input.setSelectionRange(length, length);
    }
  }, [focused]);

  return (
    <TextField
      label={title || t(getContactFormTranslationKeyByField('notes'))}
      value={value || ''}
      onChange={e => onChange(e.target.value)}
      multiline
      rows={4}
      fullWidth
      inputRef={inputRef}
    />
  );
};
