import { getLeadLTAccount } from '@/infrastructure/apis/leadGen';
import { Account, LeadAPI, MDLead } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LtDialog, MuiLoader, SettingsMenu } from '@/components';
import { Lt2ltProfileHeader } from './components/Lt2LtProfileHeader';
import { useExternalProfileDesignByUsername } from '@/infrastructure/hooks/useProfileDesign';
import { LTProfileInfo } from './components/LTProfileInfo';
import config from '@/config/config';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { openLink } from '@/util';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { useAppSelector } from '@/application/hooks';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { LeadForm } from '.';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import { InternalContactFormField } from '@/shared/types/global';
import { useLeadCustomFields } from '@/infrastructure/hooks/useLeadCustomFields';
import LtAccountsSelector from '@/components/LtAccountsSelector';

type Props = {
  mobileSettingAnchorEl?: HTMLElement;
  onMobileSettingClose?: () => void;
  onDelete?: (leadId: number) => Promise<void>;
  focusNotes?: boolean;
  lead?: LeadAPI | MDLead;
  loading?: boolean;
  isMd?: boolean;
  onAccountSelect?: (accountId: string) => void;
  selectedAccountId?: string;
};

export const LeadFormWrapped = forwardRef((props: Props, ref) => {
  const {
    mobileSettingAnchorEl,
    onMobileSettingClose,
    onDelete,
    focusNotes,
    lead,
    loading,
    onAccountSelect,
    selectedAccountId,
    isMd,
  } = props;

  const { notify: toast } = useLtNotifications();
  const [lt2ltLoading, setLt2LtLoading] = useState(false);

  const [deletePopupOpened, setDeletePopupOpened] = useState(false);

  const [lt2ltAccount, setlt2ltAccount] = useState<Account>(null);

  const { profileDesign } = useExternalProfileDesignByUsername(lt2ltAccount?.username);

  const account = useAppSelector<Account>(state => state.account);
  const accountId = account.id;

  const { getAccessTokenSilently } = useAuth0();

  const { t } = useTranslation();
  const history = useHistory();
  const { activeLanguage } = useAppTranslation();

  const isLt2Lt = Boolean(lt2ltAccount);

  useEffect(() => {
    if (!lead) return;

    const fetchData = async () => {
      setLt2LtLoading(true);
      try {
        const { lt2lt } = lead;
        if (lt2lt) {
          const leadAccount = await getLeadLTAccount(getAccessTokenSilently, lt2lt.username);
          setlt2ltAccount(leadAccount);
        }
      } catch (error) {
        console.log(error);
        history.goBack();
        toast.error(t('error.general'));
      }
      setLt2LtLoading(false);
    };
    fetchData();
  }, [lead, getAccessTokenSilently, t, history, setLt2LtLoading, toast]);

  const { config: internalContactFormConfig, loading: leadFormLoading } = usePrivateThemeConfig<{
    fields: InternalContactFormField[];
  }>(THEME_CONFIG_KEYS.INTERNAL_CONTACT_FORM);

  const { leadCustomFields, loading: leadCustomFieldsLoading } = useLeadCustomFields(accountId);

  const handleDownload = () => {
    let url = null;
    if (isLt2Lt && lt2ltAccount) {
      url = `${config.API_BASE_URL}account/download/contact?accountId=${lt2ltAccount.id}&lang=${activeLanguage}`;
    } else if (lead?.id) {
      url = `${config.API_BASE_URL}lead-gen/accounts/${lead.account?.id || account.id}/leads/${
        lead.id
      }/vcard`;
    }
    if (url) openLink(url);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    if (!lead) return;
    setDeleteLoading(true);
    await onDelete(lead.id);
    setDeletePopupOpened(false);
    setDeleteLoading(false);
  };

  const mobileSettingsMenuItems = [
    {
      icon: <DeleteOutlineIcon />,
      label: t('delete'),
      onClick: () => setDeletePopupOpened(true),
    },
    {
      icon: <DownloadIcon />,
      label: t('downloadContactFile'),
      onClick: handleDownload,
    },
  ];

  const showLoader =
    loading ||
    lt2ltLoading ||
    (isLt2Lt && !profileDesign) ||
    leadCustomFieldsLoading ||
    leadFormLoading;
  if (showLoader) {
    return <MuiLoader />;
  }

  return (
    <>
      <Box p={2}>
        {isLt2Lt && <Lt2ltProfileHeader profileDesign={profileDesign} account={lt2ltAccount} />}
        {isLt2Lt && (
          <LTProfileInfo
            usernameOrCode={lt2ltAccount.username}
            lt2ltAccount={lt2ltAccount}
            profileDesign={profileDesign}
          />
        )}

        <LeadForm
          fields={internalContactFormConfig.fields || []}
          initialLead={lead}
          leadCustomFields={leadCustomFields}
          focusNotes={focusNotes}
          ref={ref}
          isLt2Lt={isLt2Lt}
        />

        {isMd && (
          <Box mt={2}>
            <LtAccountsSelector
              label={t('mdLeads.metBy')}
              disabled={Boolean(lead)}
              value={
                selectedAccountId ? selectedAccountId : lead ? t('mdLeads.accountDeleted') : ''
              }
              onSelect={onAccountSelect}
            />
          </Box>
        )}
        {lead && (
          <Box
            display={{ xs: 'none', md: 'flex' }}
            gap={2}
            alignItems={'center'}
            justifyContent={'center'}
            mt={2}
          >
            <Button
              onClick={() => setDeletePopupOpened(true)}
              color='error'
              startIcon={<DeleteOutlineIcon />}
              aria-haspopup='dialog'
              aria-label={t('mdLeads.deleteContact')}
            >
              {t('delete')}
            </Button>
            <Button onClick={handleDownload} startIcon={<DownloadIcon />}>
              {t('downloadContactFile')}
            </Button>
          </Box>
        )}
        <Box height='9rem' />
      </Box>

      <LtDialog
        title={t('requestDelete')}
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        onDelete={handleDelete}
        loading={deleteLoading}
      >
        {t('deleteLeadMsg')}
      </LtDialog>

      {lead && (
        <SettingsMenu
          renderAnchor={false}
          anchorEl={mobileSettingAnchorEl}
          onClose={onMobileSettingClose}
          menuItems={mobileSettingsMenuItems}
        />
      )}
    </>
  );
});
