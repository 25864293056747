import { useCallback, useState } from 'react';

import { routePaths } from '../../../../infrastructure/constants';
import withNav from '../../../../infrastructure/hoc/withNav';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import StartTrialPopup from './start-trial-popup';
import { Redirect } from 'react-router-dom';
import { FullFeatureComparisonSection, VideoSection } from './Sections';
import VideoSectionExpired from './Sections/VideoSectionExpired';
import UpgradeRequestPopup from './upgrade-request-popup';
import { PageContainer } from '@/components';
import { Box } from '@mui/system';

const UpgradeLeadgen = () => {
  const { isStarter, canStartTrial } = useTierInfo();

  const [startFreeTrialPopupShown, setStartFreeTrialPopupShown] = useState(false);
  const [upgradeRequestPopupShown, setUpgradeRequestPopupShown] = useState(false);

  const popupOpenHandler = useCallback(() => {
    setStartFreeTrialPopupShown(true);
  }, []);

  if (!isStarter) {
    return <Redirect exact to={routePaths.MD.ROOT} />;
  }

  return (
    <>
      <StartTrialPopup
        open={startFreeTrialPopupShown}
        onClosed={() => setStartFreeTrialPopupShown(false)}
        plan='pro'
        planNiceName='Pro'
      />
      <UpgradeRequestPopup
        open={upgradeRequestPopupShown}
        onClosed={() => setUpgradeRequestPopupShown(false)}
      />
      <PageContainer maxWidth={'l'}>
        {!canStartTrial ? (
          <VideoSectionExpired />
        ) : (
          <VideoSection isLeadgen={true} onShowUpgradePopup={popupOpenHandler} />
        )}
        <Box height={32} />
        <FullFeatureComparisonSection />
      </PageContainer>
    </>
  );
};

export default withNav(
  UpgradeLeadgen,
  {
    tTitle: () =>
      window.location.pathname === routePaths.UPGRADE_LEADGEN ||
      window.location.pathname === routePaths.CONTACTS.ROOT
        ? 'teaser.leadRecording'
        : 'Admin Management',
  },
  {},
);
