import { SettingsMenu } from '@/components';
import { Box, styled, Card, CardHeader } from '@mui/material';
import { useState, useRef } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTranslation } from 'react-i18next';
import { fileToBase64 } from '@/util';
import { PhotoIcon } from '@/icons/PhotoIcon';
import { ImagesSlider } from '@/views/pages/lead-gen/components/ImagesSlider';
import { MAX_FILE_SELECT_SIZE } from '@/views/pages/lead-gen/BusinessCardScanner/helpers';
import ImageCropper from '@/components/ImageCropper';

export type BusinessCardImageType = {
  file: File | null;
  url: string;
};

type Props = {
  onChange: (values: BusinessCardImageType[]) => void;
  businessCardImages: BusinessCardImageType[];
};

export const BusinessCardImage = ({ onChange, businessCardImages }: Props) => {
  const [toCropImageUrl, setToCropImageUrl] = useState('');
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const imageSlidesRef = useRef(null);

  const clickOnInput = () => {
    inputRef.current?.click();
  };

  const handleChange = async e => {
    const [file] = e.target.files;
    if (!file) return;

    const res = await fileToBase64(file);
    setToCropImageUrl(res);
  };

  const handleCrop = async (url: string) => {
    const originalFile = inputRef.current.files[0];
    if (!originalFile) return;

    const blob = await fetch(url).then(r => r.blob());
    const file = new File([blob], originalFile.name);

    onChange([...businessCardImages, { file, url }]);
    setToCropImageUrl('');
    inputRef.current.value = '';
  };

  const handleCropperClose = () => {
    setToCropImageUrl('');
    inputRef.current.value = '';
  };

  const handleImageDelete = () => {
    const index = imageSlidesRef.current.getActiveIndex();
    const cpy = [...businessCardImages];
    cpy.splice(index, 1);
    onChange(cpy);
  };

  const menuItems = [
    {
      icon: <DeleteOutlineIcon />,
      label: t('delete'),
      onClick: handleImageDelete,
    },
    businessCardImages?.length < MAX_FILE_SELECT_SIZE && {
      icon: <UploadFileIcon />,
      label: t('leadGen.uploadNew'),
      onClick: clickOnInput,
    },
  ].filter(Boolean);

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title={t('leadGen.businessCardTitle')}
        subheader={t('leadGen.businessCardSubtitle')}
        titleTypographyProps={{ variant: 'h3', mb: 1 }}
        subheaderTypographyProps={{ variant: 'h5', color: 'primary' }}
        action={
          businessCardImages?.length > 0 && (
            <SettingsMenu
              menuItems={menuItems}
              iconButtonProps={{
                'aria-label': t('ariaBusinessCardImageSettings'),
                'aria-haspopup': 'menu',
              }}
            />
          )
        }
      />
      <Wrapper>
        {businessCardImages?.length > 0 ? (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <ImagesSlider images={businessCardImages.map(x => x.url)} ref={imageSlidesRef} />
          </Box>
        ) : (
          <Box
            width='100%'
            height='20rem'
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={{ cursor: 'pointer' }}
            onClick={clickOnInput}
          >
            <PhotoIcon />
          </Box>
        )}
        {toCropImageUrl && (
          <ImageCropper
            image={toCropImageUrl}
            onCropClick={handleCrop}
            onClose={handleCropperClose}
            // Adjust per needs
            cropOptions={{ width: 100, x: 0, y: 0, aspect: 16 / 9 }}
          />
        )}
        <input ref={inputRef} onChange={handleChange} style={{ display: 'none' }} type='file' />
        <Background />
      </Wrapper>
    </Card>
  );
};

const Background = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  opacity: '0.04',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
}));

const Wrapper = styled(Box)({
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '0.4rem',
});
