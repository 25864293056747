import { ThemeLanguage } from '@/shared/types/api';
import { Box, TextField } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddEditFieldFormValues } from './types';
import ISO6391 from 'iso-639-1';

type Props = {
  languages: ThemeLanguage[];
};

export const TitlesFormFields = ({ languages }: Props) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<AddEditFieldFormValues>();

  const { fields } = useFieldArray({ control, name: 'titles' });

  const isLanguageFallback = (lang: string) => {
    return Boolean(languages.find(x => x.lang === lang)?.isFallback);
  };

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {fields.map((singleField, index) => (
        <Box key={singleField.id}>
          <Controller
            control={control}
            name={`titles.${index}.value`}
            rules={
              isLanguageFallback(singleField.lang) && {
                required: {
                  message: t('error.required'),
                  value: true,
                },
              }
            }
            render={({ field }) => (
              <TextField
                inputProps={field}
                label={`${t('title')} (${ISO6391.getNativeName(singleField.lang)})`}
                error={Boolean(errors.titles?.[index]?.value)}
                aria-invalid={Boolean(errors.titles?.[index]?.value)}
                helperText={errors.titles?.[index]?.value?.message}
                fullWidth
                required={isLanguageFallback(singleField.lang)}
              />
            )}
          />
        </Box>
      ))}
    </Box>
  );
};
