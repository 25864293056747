import { CREDIT_TRANSACTION_TYPE, REFERRAL_KEY_TYPES, REWARDS_KEYS } from '../../constants';
import {
  ACCOUNT_ORIGINS,
  ACCOUNT_STATE_TRANSITION_TYPE,
  ACCOUNT_STATUSES,
  AUTH0_IMPORT_ERR_CODES,
  FILE_GEN_STATUS,
  LEAD_CUSTOM_FIELDS,
  LEAD_DEFAULT_FIELDS,
  LEAD_ORIGINS,
  LINK_CREATE_EDIT_MODES,
  LINK_TYPE_MODES,
  QR_META_TYPES,
  THEME_BOX_STYLES,
  THEME_CONTACT_EXCHANGE_FLOWS,
  THEME_FILE_EDIT_MODES,
  GLOBAL_DATA_POSITIONS,
  THEME_LINK_EDIT_MODES,
} from '../../constants/entities';
import { Account } from '../api';
import { UserData as Auth0User } from 'auth0';
import { FileGeneration } from '../api/file-generation.type';
import { GoogleFormPrefillConfig } from '../../business-logic/lib/google-forms';
export type ThemeBoxStyle = typeof THEME_BOX_STYLES[keyof typeof THEME_BOX_STYLES];
export type ThemeFileEditMode = typeof THEME_FILE_EDIT_MODES[keyof typeof THEME_FILE_EDIT_MODES];
export type ThemeLinkEditMode = typeof THEME_LINK_EDIT_MODES[keyof typeof THEME_LINK_EDIT_MODES];

export type AccountStatus = typeof ACCOUNT_STATUSES[keyof typeof ACCOUNT_STATUSES];
export type AccountOrigin = typeof ACCOUNT_ORIGINS[keyof typeof ACCOUNT_ORIGINS];

export type LinkTypeMode = typeof LINK_TYPE_MODES[keyof typeof LINK_TYPE_MODES];
export type LinkCreateEditMode = typeof LINK_CREATE_EDIT_MODES[keyof typeof LINK_CREATE_EDIT_MODES];

export type GlobalDataPosition = typeof GLOBAL_DATA_POSITIONS[keyof typeof GLOBAL_DATA_POSITIONS];

export type ThemeContactExchangeFlow =
  typeof THEME_CONTACT_EXCHANGE_FLOWS[keyof typeof THEME_CONTACT_EXCHANGE_FLOWS];

/*
##### LEAD TYPES #####
*/
export type TranslationType = {
  [lang: string]: string;
};

export type LeadCustomFieldType = typeof LEAD_CUSTOM_FIELDS[keyof typeof LEAD_CUSTOM_FIELDS];

export type LeadCustomFieldOption = {
  id: number;
  title: string;
};

export type LeadCustomField_TextMeta = {
  options: { placeholder: string };
};
export type LeadCustomField_TextareaMeta = {
  options: { placeholder: string };
};
export type LeadCustomField_DropdownMeta = {
  options: LeadCustomFieldOption[];
};
export type LeadCustomField_CheckBoxMeta = {
  options: LeadCustomFieldOption[];
};
export type LeadCustomField_RadioMeta = {
  options: LeadCustomFieldOption[];
};
export type LeadCustomField_Meta =
  | LeadCustomField_TextareaMeta
  | LeadCustomField_TextMeta
  | LeadCustomField_DropdownMeta
  | LeadCustomField_CheckBoxMeta
  | LeadCustomField_RadioMeta;

export type LeadCustomField_TranslatedMeta = {
  [lang: string]: LeadCustomField_Meta;
};

export type LeadOrigin = typeof LEAD_ORIGINS[keyof typeof LEAD_ORIGINS];

/*
##### LEAD OPTION #####
*/
export type LeadCustomFieldValueType = string | number | number[];

/*
##### Referrals #####
*/
export type ReferralKeyTypes = typeof REFERRAL_KEY_TYPES[keyof typeof REFERRAL_KEY_TYPES];

export type CreditTransactionType =
  typeof CREDIT_TRANSACTION_TYPE[keyof typeof CREDIT_TRANSACTION_TYPE];

export type CreditTransactionMeta = {
  item: typeof REWARDS_KEYS[keyof typeof REWARDS_KEYS];
};

/**
 * Accounts import data
 */
export type AccountImportData = Partial<
  Account & {
    admin?: string;
    unitAdmin?: string;
    unit?: string;
    //comma separated
    subUnits?: string;
    code?: string;
    orderCard: string;
    cardShippingAddress?: string;
    sendInvite?: string | boolean;
  } & {
    linkedin?: string;
    linkedinLinkName?: string;
    _unmapped_data?: {
      [key in `newlink${number | ''}_${'url' | 'name' | 'type'}`]?: string | undefined; // for supporting adding/updating links right here
    };
  }
>;

export type Auth0FailedUser = {
  user: Auth0User;
  errors: { code: Auth0ImportJobErrCodes; message: string; path: string }[];
};

export type Auth0ImportJobErrCodes =
  typeof AUTH0_IMPORT_ERR_CODES[keyof typeof AUTH0_IMPORT_ERR_CODES];

export type Auth0ImportJobFailedAccount = {
  email: string | undefined;
  errors: Array<string | undefined>;
};

export type AccountFieldsErr = { [key in keyof Partial<Account>]: boolean } & {
  user?: string | null | undefined;
};

/*
##### ACCOUNT STATE EVENT #####
*/
export type AccountStateTransitionType =
  typeof ACCOUNT_STATE_TRANSITION_TYPE[keyof typeof ACCOUNT_STATE_TRANSITION_TYPE];

/*
##### QR Background #####
*/
export type QRBGConfig = {
  mobile: {
    x: number;
    y: number;
    size: number;
  };
  videocall: {
    x: number;
    y: number;
    size: number;
  };
  fallback?: boolean;
};

export type FeatureFlags = Record<string, boolean>;

/**
 * File Generation
 */

export type FileGenerationStatus = typeof FILE_GEN_STATUS[keyof typeof FILE_GEN_STATUS];

// interface SharedMeta {
//   // type?: 'online' | 'offline';
// }

export interface FGRetryMeta {
  usernames: string[];
  variant?: 'online' | 'offline';
  size?: 'mobile' | 'videocall';
}

export interface FGMeta {
  amount: number;
  type: typeof QR_META_TYPES[keyof typeof QR_META_TYPES];
  variant?: 'online' | 'offline';
}

export type FGError = {
  key: string;
};

export type FileGenerationSingle = Pick<FileGeneration, 'status'> & { url?: string };

export const USER_SETTINGS_IDS = [
  'change-email',
  'change-password',
  'cookies',
  'change-language',
  'delete-self',
  'prefilled-form-link',
  'external-link',
] as const;

export const USER_MORE_SETTINGS_IDS = [
  'management-dashboard',
  'analytics',
  'nfc-cards',
  'shop-link',
  'add-to-homescreen',
  'help',
  'account-settings',
  'prefilled-form-link',
  'external-link',
] as const;

type IDs = typeof USER_SETTINGS_IDS[number] | typeof USER_MORE_SETTINGS_IDS[number];

export type UserSettingConfig<T extends IDs = IDs> = {
  id: T;
  labels?: Record<string, string>;
  captions?: Record<string, string>;
  googleFormConfig: T extends 'prefilled-form-link' ? GoogleFormPrefillConfig : never;
  linkConfig: T extends 'external-link' ? ExternalLinkConfig : never;
};

export type ExternalLinkConfig = {
  url: string;
};

export type UserSettingsConfig = {
  override?: boolean;
  items?: UserSettingConfig[];
  hiddenItemIds?: Array<UserSettingConfig['id']>;
};

export type Guide = {
  embedUrl: string;
  title: string;
  duration: number;
};

export type HomepageGuideBox =
  | {
      mode: 'academy';
      guides: Guide[];
      ltAcademyUrl?: string;
    }
  | {
      mode: 'contactPerson';
    };

export type SortOption<T = string> = {
  orderBy: T;
  sort: ('ASC' | 'asc') | ('DESC' | 'desc');
};

export type InternalContactFormField = {
  name: typeof LEAD_DEFAULT_FIELDS[number] | number /**custom field identifier */;
  isCustom?: boolean;
};

export type ContactFormField = InternalContactFormField & {
  required?: boolean;
};

export type CreateEmployeesForm = {
  accounts: Array<{
    firstName: string;
    lastName: string;
    email: string;
  }>;
  sendInvite: boolean;
  role: string;
  preferredLang: string;
  unit: {
    mainUnitId: number;
    subUnitIds: number[];
  };
};

export type CreateEmployeesPayload = Omit<CreateEmployeesForm, 'unit'> & {
  unitId?: string;
  subUnitIds?: string[];
};

export type LeadEmailExport = {
  recipient: string;
  cc?: string[];
  body: string;
  subject: string;
};
