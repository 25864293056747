import { LtDialog, LtSorter } from '@/components';
import { LeadCustomFieldPayload, ThemeLanguage } from '@/shared/types/api';
import { LeadCustomFieldOption, LeadCustomFieldType } from '@/shared/types/global';
import { isLeadCustomFieldOptionType } from '@/shared/util';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { AddEditFieldFormValues, TranslatableField } from './types';
import { TitlesFormFields } from './TitlesFormFields';
import { getTitlesMapFromTitlesArr, getTranslatableInitialValues } from './utils';
import { OptionsFormFields } from './OptionsFormFields';

type Props = {
  open: boolean;
  fieldType: LeadCustomFieldType;
  onClose: () => void;
  onSave: (values: LeadCustomFieldPayload) => Promise<void>;
  initialValues?: LeadCustomFieldPayload;
  languages: ThemeLanguage[];
};

const getOptions = (options: { titles: TranslatableField[] }[], languages: ThemeLanguage[]) => {
  const optionsWithId = options.map(o => ({ ...o, id: uuidv4() }));
  const result = languages.reduce((acc, { lang }) => {
    return {
      ...acc,
      [lang]: {
        options: optionsWithId.map(o => ({
          title: o.titles.find(t => t.lang === lang)?.value,
          id: o.id,
        })),
      },
    };
  }, {});

  return result;
};

const getDefaultValues = (languages: ThemeLanguage[]) => {
  return {
    titles: getTranslatableInitialValues(languages),
    options: [
      {
        titles: getTranslatableInitialValues(languages),
      },
    ],
  };
};

const transformPayloadToFormValues = (
  payload: LeadCustomFieldPayload,
  languages: ThemeLanguage[],
): AddEditFieldFormValues => {
  if (!payload) {
    return getDefaultValues(languages);
  }

  const titles = languages.map(({ lang }) => ({
    lang,
    value: payload.translatedName?.[lang] || payload.name || '',
  }));

  if (!isLeadCustomFieldOptionType(payload.type)) {
    return {
      titles,
    };
  }

  const metaOptions = payload.meta?.options as LeadCustomFieldOption[];
  const fallbackLang = languages.find(l => l.isFallback)?.lang;
  const fallbackLangOptions = (payload?.translatedMeta?.[fallbackLang]?.options ||
    metaOptions ||
    []) as LeadCustomFieldOption[];

  const options = [];

  for (let i = 0; i < fallbackLangOptions.length; i++) {
    const option = {
      titles: languages.map(({ lang }) => ({
        lang,
        value: payload?.translatedMeta?.[lang]?.options[i]?.title || metaOptions?.[i]?.title || '',
      })),
    };
    options.push(option);
  }

  return {
    titles,
    options,
  };
};

export const AddEditCustomFieldDialog = ({
  open,
  fieldType,
  onClose,
  onSave,
  initialValues,
  languages,
}: Props) => {
  const { t } = useTranslation();
  const methods = useForm<AddEditFieldFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      ...(initialValues
        ? transformPayloadToFormValues(initialValues, languages)
        : getDefaultValues(languages)),
    },
  });

  const { handleSubmit, reset, control } = methods;

  const { fields, append, remove, move } = useFieldArray({
    name: 'options',
    control,
  });

  const isOptionType = isLeadCustomFieldOptionType(fieldType);

  useEffect(() => {
    if (initialValues || open) {
      reset({
        ...(initialValues
          ? transformPayloadToFormValues(initialValues, languages)
          : getDefaultValues(languages)),
      });
    }
  }, [initialValues, reset, open, languages]);

  const [saving, setSaving] = useState(false);

  const handleSave = async (values: AddEditFieldFormValues) => {
    const translatedName = getTitlesMapFromTitlesArr(values.titles);

    const options = getOptions(values.options, languages);

    setSaving(true);

    /**Transform form values to LeadCustomFieldPayload */
    await onSave({
      name: '',
      translatedName,
      type: fieldType,
      meta: null,
      translatedMeta: isOptionType && options,
    });
    setSaving(false);
  };

  const title = `${initialValues ? t('mdLeads.edit') : t('add')} ${t(
    `contactFormEdit.internal.customFields.${fieldType}.title`,
  )}`;

  const handleSortEnd = (_, oldIndex: number, newIndex: number) => {
    move(oldIndex, newIndex);
  };

  return (
    <LtDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      saveAction={{
        text: t('save'),
        onClick: handleSubmit(handleSave),
        loading: saving,
      }}
      title={title}
      size='md'
      withActionDivider
    >
      {isOptionType && (
        <Typography sx={{ mb: 2 }} variant='h4'>
          {t('contactFormEdit.internal.questionTitle')}
        </Typography>
      )}
      <FormProvider {...methods}>
        <TitlesFormFields languages={languages} />

        {isLeadCustomFieldOptionType(fieldType) && (
          <>
            <Box mt={3.2} pt={2} borderBottom={1} borderTop={1} borderColor='divider'>
              <Typography variant='h4'>{t('contactFormEdit.internal.answerOptions')}</Typography>
              {fields.length <= 1 ? (
                fields.map((field, index) => (
                  <OptionsFormFields key={field.id} index={index} languages={languages} />
                ))
              ) : (
                <LtSorter
                  items={fields}
                  onEnd={handleSortEnd}
                  dense
                  getItemAriaLabel={() => t('ariaLeadCustomFieldOption')}
                  getItemKey={item => item.id}
                  useFullAreaForDrag={fields.length === 1}
                  renderer={(field, index) => (
                    <OptionsFormFields
                      languages={languages}
                      key={field.id}
                      index={index}
                      onRemoveClick={remove}
                    />
                  )}
                  divider={<Divider />}
                />
              )}
            </Box>

            <Button
              color='primary'
              variant='outlined'
              startIcon={<AddCircleOutline />}
              fullWidth
              size='large'
              sx={{ mt: 3.2 }}
              onClick={() => append({ titles: getTranslatableInitialValues(languages) })}
            >
              {t('contactFormEdit.internal.addOption')}
            </Button>
          </>
        )}
      </FormProvider>
    </LtDialog>
  );
};
