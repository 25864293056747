import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../application/hooks';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import {
  Box,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { extractNameFromEmail, getMainUnitSync } from '@/shared/util';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AddEmployeeAccountDetailsFields } from './table/components/AddEmployeeAccountDetailsFields';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { CreateEmployeesForm } from '@/shared/types/global';
import { createEmployees } from '@/infrastructure/apis/md/profiles';
import { Unit } from '@/shared/types/api/unit.type';
import { getUnits } from '@/infrastructure/apis/md/units';
import { FEATURE } from '@/shared/constants';
import { UnitSelectorForProfiles } from './common/UnitSelectorForProfiles';
import { LanguageSelector } from '@/components/LeadGen/LeadForm/components/LanguageSelector';

interface Props {
  onBackClick: () => void;
  setCreateEmployeeForm: Dispatch<SetStateAction<any>>;
  showLicensesWarning: () => void;
  open: boolean;
  refreshEmployees: () => void;
}

const defaultValues = {
  accounts: [
    {
      firstName: '',
      lastName: '',
      email: '',
    },
  ],
  sendInvite: false,
  role: '',
  unit: {
    mainUnitId: null,
    subUnitIds: [],
  },
  preferredLang: 'en',
};

const AddEmployee = ({
  onBackClick,
  open,
  setCreateEmployeeForm,
  showLicensesWarning,
  refreshEmployees,
}: Props) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { notify: toast } = useLtNotifications();

  const { setIsUnsaved } = useUnsavedStatusSetter();
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const account = useAppSelector(state => state.account);
  const { isUnitAdmin, isThemeAdmin, isFeatureAllowed } = useTierInfo();
  const isUnitsAllowed = isFeatureAllowed(FEATURE.UNITS);

  const { theme } = account;

  const { employees } = useAppSelector(state => state.md);

  const [fetchedUnits, setFetchedUnits] = useState<Unit[]>([]);

  useEffect(() => {
    if (!isThemeAdmin) return;
    const fetchData = async () => {
      try {
        const { data } = await getUnits(getAccessTokenSilently);
        setFetchedUnits(data.data.units);
      } catch (error) {}
    };
    fetchData();
  }, [getAccessTokenSilently, isThemeAdmin, account]);

  const units = !isThemeAdmin ? account.units : fetchedUnits;
  const isUnitsAvailable = Boolean(units?.length);

  const methods = useForm<CreateEmployeesForm>({
    mode: 'onSubmit',
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
    clearErrors,
  } = methods;

  useEffect(() => {
    if (!open) return;
    if (isUnitAdmin && !isThemeAdmin) {
      const mainUnit = getMainUnitSync(account);
      setValue('unit', { mainUnitId: mainUnit.id, subUnitIds: [] });
    }
  }, [setValue, isUnitAdmin, isThemeAdmin, account, open]);

  const role = watch('role');

  useEffect(() => {
    clearErrors('unit');
  }, [role, clearErrors]);

  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change') setIsUnsaved(true);
    });
    return () => subscription.unsubscribe();
  }, [watch, setIsUnsaved]);

  useEffect(() => {
    if (!open) return;
    const sub = watch(({ accounts }) => {
      let shouldUpdate = false;
      const newAccounts = [];
      for (let i = 0; i < accounts.length; i++) {
        const acc = accounts[i];
        if (acc.firstName || acc.lastName) {
          newAccounts.push(acc);
          continue;
        }
        const { firstName, lastName } = extractNameFromEmail(acc.email);
        if (firstName && lastName) {
          newAccounts.push({
            firstName,
            lastName,
            email: acc.email,
          });
          shouldUpdate = true;
          continue;
        }
        newAccounts.push(acc);
      }
      if (shouldUpdate) {
        setValue(
          'accounts',
          newAccounts as { firstName: string; lastName: string; email: string }[],
        );
      }
    });
    return () => sub.unsubscribe();
  }, [open, setValue, watch]);

  useEffect(() => {
    if (!open) reset({ ...defaultValues });
  }, [open, reset]);

  const [saving, setSaving] = useState(false);

  const handleSave = async (payload: CreateEmployeesForm) => {
    if (employees.length + payload.accounts?.length > (theme?.themeInternal.amountLicences || 0)) {
      setCreateEmployeeForm(payload);
      showLicensesWarning();
    } else {
      setSaving(true);
      try {
        await createEmployees(getAccessTokenSilently, {
          accounts: payload.accounts,
          sendInvite: payload.sendInvite,
          role: payload.role,
          subUnitIds: payload.unit?.subUnitIds?.map(x => x.toString()),
          unitId: payload.unit?.mainUnitId?.toString(),
          preferredLang: payload.preferredLang,
        });
        toast.success(t('createdSuccessMD'));
        setIsUnsaved(false);
        onBackClick();
        refreshEmployees();
      } catch (error) {
        toast.error(error.message || t('error.general'));
      }
      setSaving(false);
    }
  };

  const { theme: muiTheme } = useMuiTheme();

  const handeClose = () => openDialogIfUnsaved(onBackClick);

  return (
    <ThemeProvider theme={muiTheme}>
      <Drawer
        anchor='right'
        open={open}
        onClose={handeClose}
        PaperProps={{ sx: { width: '70rem', display: 'flex', flexDirection: 'column' } }}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center' px='2rem' py='1rem'>
          <Typography variant='h2'>{t('createProfile')}</Typography>
          <IconButton onClick={handeClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box px='2rem' py='2rem' flex='1'>
          <FormProvider<CreateEmployeesForm> {...methods}>
            <Box>
              <Typography sx={{ mb: 2 }} variant='h3'>
                {t('accountDetails')}
              </Typography>
              <AddEmployeeAccountDetailsFields control={control} />
            </Box>
            <Divider sx={{ my: 2 }} />

            <Box>
              <Typography sx={{ mb: 2 }} variant='h3'>
                {t('preferredLang')}
              </Typography>

              <Controller
                control={control}
                name='preferredLang'
                render={({ field }) => (
                  <LanguageSelector
                    labelOverride={t('select')}
                    value={field.value}
                    onLangSelect={field.onChange}
                  />
                )}
              />
            </Box>
            <Divider sx={{ my: 2 }} />

            <Box>
              <Typography sx={{ mb: 1 }} variant='h3'>
                {t('role')}
              </Typography>
              <Controller
                control={control}
                name='role'
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value={''}
                      control={<Radio />}
                      label={t('employeeType.employee')}
                    />
                    {isThemeAdmin && (
                      <FormControlLabel
                        value='theme_admin'
                        control={<Radio />}
                        label={t('employeeType.themeAdmin')}
                      />
                    )}
                    {isUnitsAllowed && isUnitsAvailable && (
                      <FormControlLabel
                        value={'unit_admin'}
                        control={<Radio />}
                        label={t('employeeType.unitAdmin')}
                      />
                    )}
                  </RadioGroup>
                )}
              />
            </Box>
            <Divider sx={{ my: 2 }} />

            {isUnitsAllowed && (
              <Controller
                control={control}
                name='unit'
                rules={{
                  validate: value => {
                    if (role === 'unit_admin' || (isUnitAdmin && !isThemeAdmin)) {
                      if (!value.mainUnitId) {
                        return t('error.required');
                      }
                    }
                  },
                }}
                render={({ field }) => {
                  const selectedMainUnit = units.find(u => u.id === field.value.mainUnitId);
                  const selectedOtherUnits = units.filter(u =>
                    field.value.subUnitIds.includes(u.id),
                  );
                  return (
                    <UnitSelectorForProfiles
                      units={units}
                      values={{
                        selectedMainUnit,
                        selectedOtherUnits,
                      }}
                      onChange={({ selectedMainUnit, selectedOtherUnits }) => {
                        field.onChange({
                          mainUnitId: selectedMainUnit ? selectedMainUnit.id : null,
                          subUnitIds: selectedOtherUnits?.map(u => u.id) || [],
                        });
                      }}
                      showWarning={false}
                      // @ts-ignore
                      errorMessage={errors.unit?.message}
                    />
                  );
                }}
              />
            )}
          </FormProvider>
        </Box>
        <LtActionButtonBar
          saveAction={{
            onClick: handleSubmit(handleSave),
            loading: saving,
            text: t('add'),
          }}
          onCancel={handeClose}
          position='sticky'
        />
      </Drawer>
    </ThemeProvider>
  );
};

export default AddEmployee;
