import { useRef, useState } from 'react';
import { isFileValid } from '../../../../../../infrastructure/helper';
import { useTranslation } from 'react-i18next';
import { ImageSettings } from '@/components/ImageSettings';
import { Add, DeleteOutlined, Upload } from '@mui/icons-material';
import { Box, Button, styled } from '@mui/material';
import { IconButton, Paper } from '@mui/material';
import { AccountImageState } from '@/shared/types/api';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import ImageCropper from '@/components/ImageCropper';
import { ImageSettingsMenu } from './ImageSettingsMenu';

interface Props {
  profileImageUrl?: string;
  defaultProfileImageUrl?: string;
  defaultProfileImageState?: AccountImageState;
  onChange?: (file?: File, url?: string, state?: AccountImageState) => void;
  profileImageState?: AccountImageState;
  hideDeleteOption?: boolean;
  isButton?: boolean;
}

const ProfilePicture = ({
  profileImageUrl,
  defaultProfileImageUrl,
  defaultProfileImageState,
  onChange,
  profileImageState,
  hideDeleteOption,
  isButton,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const [profileImage, setProfileImage] = useState('');

  const onImageSelection = async () => {
    const fileInput = fileInputRef.current;
    const uploadedFile = fileInput.files[0];
    const extension = uploadedFile.name.split('.').pop();

    if (uploadedFile) {
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        toast.error(errMsg);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);

      if (extension === 'gif') {
        reader.onload = () => {
          onChange(uploadedFile, reader.result as string, AccountImageState.DEFINED);
        };
      } else {
        reader.onload = () => setProfileImage(reader.result as string);
      }
    }
  };

  const onUploadClick = async croppedImageUrl => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    const fileInput = fileInputRef.current;
    var file: File = new File([blob], fileInput.files[0].name);
    setProfileImage('');
    onChange(file, croppedImageUrl, AccountImageState.DEFINED);
  };

  const items = [
    {
      icon: <Upload />,
      label: t('upload'),
      onClick: () => fileInputRef.current.click(),
    },
    !hideDeleteOption &&
      profileImageState !== AccountImageState.DEFAULT && {
        icon: <DeleteOutlined />,
        label: t('delete'), // always show delete, even if actually resetting to default
        onClick: () => onChange(null, defaultProfileImageUrl, AccountImageState.DEFAULT),
      },
  ].filter(Boolean);

  return (
    <>
      {profileImage && (
        <ImageCropper
          image={profileImage}
          onCropClick={url => onUploadClick(url)}
          onClose={() => setProfileImage('')}
          circularCrop
        />
      )}

      {profileImageUrl ? (
        <>
          <ProfilePicWrapper>
            <ProfilePicCropper>
              <ProfilePicImage component='img' src={profileImageUrl} alt='Profile Pic' />
            </ProfilePicCropper>
            <EditProfilePicWrapper>
              <ImageSettings actions={items} ariaLabel={t('editLogo')} />
            </EditProfilePicWrapper>
          </ProfilePicWrapper>
        </>
      ) : (
        <>
          {isButton ? (
            <ImageSettingsMenu
              el={
                <Button color='primary' variant='outlined' startIcon={<Add />}>
                  {t('addAvatar')}
                </Button>
              }
              actions={items}
            />
          ) : (
            <AddImageIcon>
              <ImageSettingsMenu
                el={
                  <IconButton sx={{ width: '10rem', height: '10rem' }} aria-label={t('addAvatar')}>
                    <Add />
                  </IconButton>
                }
                actions={items}
              />
            </AddImageIcon>
          )}
        </>
      )}

      <input
        type='file'
        ref={fileInputRef}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default ProfilePicture;

const ProfilePicWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  position: 'relative',
  marginRight: '2rem',
  width: '10rem',
  height: '10rem',
});

const ProfilePicCropper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '50%',
});

const ProfilePicImage = styled(Box)({
  width: '100%',
  height: 'auto',
});

const EditProfilePicWrapper = styled(Box)({
  position: 'absolute',
  boxSizing: 'border-box',
  borderRadius: '50%',
  zIndex: 10,
  bottom: 0,
  right: '-1.8rem',
});

const AddImageIcon = styled(Paper)`
  border-radius: 50%;
  cursor: pointer;
  margin-right: 2rem;
`;

// TODO: ADD MEDIA QUERIES
