import config from '../../config/config';
import axios from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';

import { withSilentAccessToken } from '../helper';
import { PERMISSIONS } from '../constants';
import { UdtCategory } from '@/shared/business-logic/features/user-defined-translations/types';
import { ThemeLanguage } from '@/shared/types/api';

export const getTranslationsJSON = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  options?: { categories?: UdtCategory[]; langs?: string[] },
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + `theme/translations/json`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { categories: options?.categories || [], langs: options?.langs || ['en', 'de'] },
      }),
    [PERMISSIONS.READ.THEME_PROFILES],
  );

  return data;
};

export const getTranslationsCsvOrXlsx = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  outputType: 'csv' | 'xlsx',
) => {
  const res = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + 'theme/translations', {
        params: { outputType },
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      }),
    [PERMISSIONS.READ.THEME_PROFILES],
  );

  return res;
};

export const updateTranslationsJSON = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: Record<string, Record<string, Record<string, string>>>,
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.put(config.API_BASE_URL + `theme/translations/json`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );

  return data;
};

export const getLanguages = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  const { data: res } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + 'theme/languages', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_GLOBAL],
  );
  return res.data;
};

export const updateLanguages = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  languages: ThemeLanguage[],
) => {
  const { data } = await withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(
        config.API_BASE_URL + 'theme/languages',
        { languages },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    [PERMISSIONS.WRITE.THEME_GLOBAL],
  );
  return data;
};
