import { Account, Theme, ThemeDomain, ThemeIdentityProvider, ThemeSSO } from '../types/api';
import {
  ACCOUNT_FIELDS_MAX_LENGTH,
  ROLES,
  TRIALSTATUS,
  SSO_STRATEGIES,
  LEAD_CUSTOM_FIELDS,
} from '../constants';
import { Unit } from '../types/api/unit.type';
import { StrategyData, StrategyOptions } from '../types/api';
import { AccountFieldsErr, AccountImportData, LeadCustomFieldType } from '../types/global';
import { nonNullable } from '../types/util';
import { Idp, idpStrategies } from '../constants/Idp';

export const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (value: string): boolean => {
  // SOURCE: https://community.auth0.com/t/email-validation-rules-please-make-them-public-knowledge/29532
  // SAME regExp THAT AUTH0 USES

  const result = emailRegexp.exec(value.toLowerCase());
  return !!result && result[0] !== null;
};

/*
  Auth0 fields rules:
  __email__ The maximum length is 64 characters for the user/local part and 256 characters for the domain part.
  https://auth0.com/docs/manage-users/user-accounts/create-users
  first
  __names__ The maximum length is 150 chars for each first & last name property.
   https://community.auth0.com/t/firstname-lastname-middlename-chracter-length/66157/5
*/

export const checkAccountFieldsLength = (acc: AccountImportData): AccountFieldsErr => {
  const err: AccountFieldsErr = {};
  const email_user = acc.email?.split('@').shift();
  const email_domain = acc.email?.split('@').pop();

  if (
    !!email_user &&
    !!email_domain &&
    (email_user.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAILUSER ||
      email_domain.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAILDOMAIN)
  )
    err.email = true;

  if (!!acc.workEmail && acc.workEmail.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAIL)
    err.workEmail = true;

  if (!!acc.workEmail2 && acc.workEmail2.length > ACCOUNT_FIELDS_MAX_LENGTH.EMAIL)
    err.workEmail2 = true;

  if (!!acc.id && acc.id.length > ACCOUNT_FIELDS_MAX_LENGTH.ID) err.id = true;

  if (!!acc.firstName && acc.firstName.length > ACCOUNT_FIELDS_MAX_LENGTH.FIRSTNAME)
    err.firstName = true;

  if (!!acc.lastName && acc.lastName.length > ACCOUNT_FIELDS_MAX_LENGTH.LASTNAME)
    err.lastName = true;

  if (!!acc.username && acc.username.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.username = true;

  if (!!acc.position && acc.position.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.position = true;

  if (!!acc.role && acc.role.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.role = true;

  if (!!acc.division && acc.division.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.division = true;

  if (!!acc.company && acc.company.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT) err.company = true;

  if (!!acc.mobilePhone && acc.mobilePhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.mobilePhone = true;

  if (!!acc.workPhone && acc.workPhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.workPhone = true;

  if (!!acc.homePhone && acc.homePhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.homePhone = true;

  if (!!acc.hotlinePhone && acc.hotlinePhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.hotlinePhone = true;

  if (!!acc.assistantPhone && acc.assistantPhone.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.assistantPhone = true;

  if (!!acc.workFax && acc.workFax.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER) err.workFax = true;

  if (!!acc.address1_addressee && acc.address1_addressee.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_addressee = true;
  if (
    !!acc.address1_addressLine1 &&
    acc.address1_addressLine1.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address1_addressLine1 = true;
  if (
    !!acc.address1_addressLine2 &&
    acc.address1_addressLine2.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address1_addressLine2 = true;
  if (!!acc.address1_postCode && acc.address1_postCode.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.address1_postCode = true;
  if (!!acc.address1_city && acc.address1_city.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_city = true;
  if (!!acc.address1_country && acc.address1_country.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_country = true;
  if (!!acc.address1_label && acc.address1_label.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address1_label = true;

  if (!!acc.address2_addressee && acc.address2_addressee.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_addressee = true;
  if (
    !!acc.address2_addressLine1 &&
    acc.address2_addressLine1.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address2_addressLine1 = true;
  if (
    !!acc.address2_addressLine2 &&
    acc.address2_addressLine2.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT
  )
    err.address2_addressLine2 = true;
  if (!!acc.address2_postCode && acc.address2_postCode.length > ACCOUNT_FIELDS_MAX_LENGTH.NUMBER)
    err.address2_postCode = true;
  if (!!acc.address2_city && acc.address2_city.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_city = true;
  if (!!acc.address2_country && acc.address2_country.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_country = true;
  if (!!acc.address2_label && acc.address2_label.length > ACCOUNT_FIELDS_MAX_LENGTH.TEXT)
    err.address2_label = true;

  if (
    !!acc.addressTemplate1Id &&
    (acc.addressTemplate1Id > ACCOUNT_FIELDS_MAX_LENGTH.INTEGER ||
      acc.addressTemplate1Id < -ACCOUNT_FIELDS_MAX_LENGTH.INTEGER)
  )
    err.addressTemplate1Id = true;

  if (
    !!acc.addressTemplate2Id &&
    (acc.addressTemplate2Id > ACCOUNT_FIELDS_MAX_LENGTH.INTEGER ||
      acc.addressTemplate2Id < -ACCOUNT_FIELDS_MAX_LENGTH.INTEGER)
  )
    err.addressTemplate2Id = true;

  if (!!acc.workUrl && acc.workUrl.length > ACCOUNT_FIELDS_MAX_LENGTH.URL) err.workUrl = true;

  Object.keys(err).length > 0 && (err.user = acc.email);

  return err;
};

export const isInFuture = (date?: Date | string | null): boolean =>
  !!(date && new Date(date).getTime() > new Date().getTime());

export const getContactFormTranslationKeyByField = (field: string): string => {
  if (field.toLowerCase().includes('phone')) return `${field}.text`;

  if (field === 'gdprLegalBasis') return `gdpr`;

  return field;
};

/**
 * alternative to String.prototype.replaceAll native helper which not supported in old browsers
 * @param str  the string to replace inside
 * @param find the string to be replaced
 * @param replace the string to be replaced with
 * @returns replaced string
 */
export const replaceAll = (str: string, find: string, replace: string) =>
  str.replace(new RegExp(escapeRegExp(find), 'g'), replace);

/**
 * escape string from regular expression special charachters
 * @param string
 * @returns escaped string
 */
const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export const getMainUnitSync = (account: Account): null | Unit =>
  account.accountToUnits?.find(atu => atu.isMain)?.unit || null;
export const getOtherUnitsSync = (account: Account): Array<Unit> =>
  account.accountToUnits
    ?.filter(atu => !atu.isMain)
    ?.map(atu => atu.unit)
    .filter(nonNullable) || [];
/*
 *
 * @param strategyData
 * @returns array of missed required fields
 */
export const checkRequiredStrategyFields = (
  strategyData: StrategyData,
): Array<keyof StrategyData | keyof StrategyOptions> => {
  const missed_fields: Array<keyof StrategyData | keyof StrategyOptions> = [];

  const strategy_options = strategyData.options;

  !strategyData.strategy && missed_fields.push('strategy');
  !strategyData.connectionName && missed_fields.push('connectionName');
  !strategy_options && missed_fields.push('options');

  if (!!strategyData.strategy && !!strategy_options) {
    switch (strategyData.strategy) {
      case SSO_STRATEGIES.SAMLP:
        // !strategy_options.certificate && missed_fields.push('certificate');
        // !strategy_options.signInUrl && missed_fields.push('signInUrl');
        // !strategy_options.metaDataUrl && missed_fields.push('metaDataUrl');
        break;
      case SSO_STRATEGIES.OKTA:
        !strategy_options.oktaDomain && missed_fields.push('oktaDomain');
        !strategy_options.clientID && missed_fields.push('clientID');
        !strategy_options.clientSecret && missed_fields.push('clientSecret');
        break;
      case SSO_STRATEGIES.ENTRA_ID:
        !strategy_options.entraIdDomain && missed_fields.push('entraIdDomain');
        !strategy_options.clientID && missed_fields.push('clientID');
        !strategy_options.clientSecret && missed_fields.push('clientSecret');
        break;
      default:
        break;
    }
  }

  return missed_fields;
};

export const getSSOStrategies = (themeIdp: ThemeIdentityProvider) => {
  return idpStrategies[themeIdp.name as Idp] || ['samlp'];
};

export const normalizeThemeName = (str: string): string => str.replace(/\s+/g, '-');

export const getSSODomains = (ssoConnections?: ThemeSSO[]) => {
  return (
    ssoConnections?.reduce((prev, { domains }) => [...prev, ...domains], [] as ThemeDomain[]) || []
  );
};

export const getIdpSettingsForTheme = (theme?: Theme) => {
  const idps = theme?.identityProviders || [];
  const ssoConnections = idps.map(x => x.themeSSO).filter(x => x?.enabled) as unknown as ThemeSSO[];
  const ssoDomains = getSSODomains(ssoConnections);

  return { idps, ssoConnections, ssoDomains };
};

export const themeHasSSO = (theme: Theme) => {
  const { ssoConnections } = getIdpSettingsForTheme(theme);
  return ssoConnections.length > 0;
};

export const isSSOUserEmail = (theme: Theme, authEmail?: string | null) => {
  const { ssoConnections, ssoDomains } = getIdpSettingsForTheme(theme);

  if (ssoConnections.length === 0) return false;

  const emailDomain = authEmail?.split('@').pop()?.toLowerCase();
  if (!emailDomain) return false;
  const domains = ssoDomains.map(x => x.domain);

  return domains.includes(emailDomain);
};

export const appendParamsToLink = (link: string, params: Record<string, string | number>) => {
  const url = new URL(link);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value.toString());
  });
  return url.toString();
};

export const CLAIMS = {
  ID_TOKEN: {
    ROLES: 'https://lemontaps.com/claim/roles',
  },
};
export const hasRole = (idTokenClaims: Record<string, any>, role: string[]): boolean => {
  return (
    idTokenClaims &&
    Array.isArray(idTokenClaims[CLAIMS.ID_TOKEN.ROLES]) &&
    idTokenClaims[CLAIMS.ID_TOKEN.ROLES].some((c: string) => role.includes(c))
  );
};

export const generateStaticTierInfo = (
  account: Account,
  theme: Theme,
  idToken: Record<string, any>,
) => {
  const now = new Date().getTime();
  const base = {
    loading: !theme?.themeInternal || !theme?.featureSet,
    isStarter: theme?.themeInternal?.tier === 'starter',
    isEssentials: theme?.themeInternal?.tier === 'essentials',
    isPro: theme?.themeInternal?.tier === 'pro',
    isEnterprise: theme?.themeInternal?.tier === 'enterprise',
    isMinEssentials: ['essentials', 'pro', 'enterprise'].includes(theme?.themeInternal?.tier || ''),
    isMinPro: ['pro', 'enterprise'].includes(theme?.themeInternal?.tier || ''),
    isTrial:
      theme?.themeInternal?.trialEndDate &&
      new Date(theme?.themeInternal?.trialEndDate).getTime() > now &&
      theme?.themeInternal?.trialStatus === TRIALSTATUS.CURRENT,
    isTrialFinished:
      theme?.themeInternal?.trialEndDate &&
      new Date(theme?.themeInternal?.trialEndDate).getTime() <= now &&
      theme?.themeInternal?.trialStatus === TRIALSTATUS.FINISHED,
    isThemeAdmin: hasRole(idToken, [ROLES.THEME_ADMIN]),
    isUnitAdmin: hasRole(idToken, [ROLES.UNIT_ADMIN]),
    isAnyAdmin: hasRole(idToken, [ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]),
    isOnlySingle: theme?.themeInternal?.onlySingle,
    isMainAccount: theme?.themeInternal?.mainAccountId === account.id,
    wasCreatedInTheme: typeof account.attachedThemeId === 'number',
    tier: theme?.themeInternal?.tier,
  };

  const derivedProps = {
    canStartTrial: !base.isTrialFinished && !base.wasCreatedInTheme,
  };
  return {
    ...base,
    ...derivedProps,
  };
};
export type StaticTierInfo = ReturnType<typeof generateStaticTierInfo>;

export const extractNameFromEmail = (email: string) => {
  if (!email) return { firstName: '', lastName: '' };
  const matches = email.match(/([a-z]+)\.([a-z]+)@/);
  if (matches && matches[1] && matches[1].length > 1 && matches[2] && matches[2].length > 1) {
    const firstName = matches[1].charAt(0).toUpperCase() + matches[1].substring(1);
    const lastName = matches[2].charAt(0).toUpperCase() + matches[2].substring(1);
    return { firstName, lastName };
  }
  return { firstName: '', lastName: '' };
};

export const isLeadCustomFieldOptionType = (fieldType: LeadCustomFieldType) => {
  return (
    fieldType === LEAD_CUSTOM_FIELDS.RADIO ||
    fieldType === LEAD_CUSTOM_FIELDS.CHECKBOX ||
    fieldType === LEAD_CUSTOM_FIELDS.DROPDOWN
  );
};
