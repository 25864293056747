import { Box, Chip, ListItemText } from '@mui/material';

type Props = {
  title: string;
  chips?: string[];
  action?: React.ReactNode;
};

export const SortableField = ({ title, chips, action }: Props) => {
  return (
    <>
      <ListItemText primary={title} />
      <Box display='flex' gap={0.4} alignItems='center'>
        {chips && chips.filter(Boolean).map(label => <Chip label={label} size='small' />)}
        {action}
      </Box>
    </>
  );
};
