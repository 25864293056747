import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { getLeadCustomFields } from '../apis/leadGen';
import { LeadCustomFieldAPI } from '@/shared/types/api';

export const useLeadCustomFields = (accountId: string) => {
  const [leadCustomFields, setLeadCustomFields] = useState<Array<LeadCustomFieldAPI>>([]);
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getLeadCustomFields(getAccessTokenSilently, accountId);
        setLeadCustomFields(data);
      } catch {
        //
      }
      setLoading(false);
    };
    fetchData();
  }, [accountId, getAccessTokenSilently]);

  return { leadCustomFields, loading };
};
