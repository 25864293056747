import { StaticProfileConfig } from '@/shared/types/api';
import BioTextBox from './sections/BioTextBox';
import ContactDetailsBox from './sections/ContactDetailsBox';
import ContactFormBox from './sections/ContactFormBox';
import Footer from './sections/Footer';
import TryForFreeBanner from './sections/TryForFreeBanner';
import UrlsBox from './sections/UrlsBox';
import ProfileThemeProvider from '../ProfileThemeProvider';
import ResponsiveProfileWidthContainer from './components/ResponsiveProfileContainer';
import Banner from './sections/Banner';
import HeaderBox from './sections/HeaderBox';
import { Box, Card, ThemeProvider } from '@mui/material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ProfileEditProps, TrackHandlers } from './types';
import { Stack } from '@mui/system';
import FontWrapper from './components/FontWrapper';

// todo: integrate with /edit-bio

interface Props {
  profile: StaticProfileConfig;
  onContactFormSubmit?: (values: Record<string, string>) => Promise<boolean>;
  renderTryForFreeBanner?: boolean; // defaults to false
  renderFooter?: boolean; // defaults to true
  renderCookieSettings?: boolean; // defaults to true, only relevant if footer is rendered
  suppressLeadGen?: boolean; // defaults to false, only relevant if profile.leadGen is provided
  suppressLeadGenPopupFlow?: boolean; // defaults to false, only relevant if profile.leadGen is provided and suppressLeaGen is true
  editProps?: ProfileEditProps;
  trackHandlers?: TrackHandlers;
  renderBoxes?: Array<
    'header' | `urlsBox_${'links' | 'files'}` | StaticProfileConfig['body']['boxes'][number]['name']
  >; // override info about which boxes to render. Relevant when embedding the profile UI somehwere.
}

export default function StaticProfile({
  profile,
  onContactFormSubmit,
  renderTryForFreeBanner = false,
  renderFooter = true,
  renderCookieSettings = true,
  suppressLeadGen = false,
  suppressLeadGenPopupFlow = false,
  editProps,
  trackHandlers = {},
  renderBoxes,
}: Props) {
  const { theme, globalStyles } = useMuiTheme();
  const {
    contactSaveTrackHandler,
    linkClickTrackHandler,
    fileClickTrackHandler,
    contactSubmitTrackHandler,
    profileImageClickTrackHandler,
  } = trackHandlers;

  const shouldHide = (name: typeof renderBoxes[number]) =>
    renderBoxes && !renderBoxes?.includes(name);

  return (
    <ThemeProvider theme={theme}>
      {globalStyles}
      <ProfileThemeProvider metaTheme={profile.config.metaTheme}>
        <FontWrapper font={profile.config.metaTheme.font}>
          <ResponsiveProfileWidthContainer>
            {renderTryForFreeBanner && <TryForFreeBanner username={profile.meta.username} />}
            <Card
              sx={theme => ({
                pb: 2,
                px: 0,
                mb: 8,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                [theme.breakpoints.down('md')]: {
                  my: 0,
                  borderRadius: 0,
                  border: 'none',
                  pb: 8,
                },
              })}
            >
              {!shouldHide('header') && (
                <Banner
                  bannerImgUrl={profile.header.bannerImageUrl}
                  editProps={editProps?.bannerImageEditProps}
                  renderBottomInset
                />
              )}
              <Stack spacing={2} px={{ xs: 1, md: 2 }}>
                {!shouldHide('header') && (
                  <HeaderBox
                    config={profile.header}
                    profileImageEditProps={editProps?.profileImageEditProps}
                    logoImageEditProps={editProps?.logoImageEditProps}
                    trackHandlers={{ contactSaveTrackHandler, profileImageClickTrackHandler }}
                  />
                )}
                {shouldHide('header') && <Box height={4} />}
                {profile.body.boxes.map(boxConfig => {
                  switch (boxConfig.name) {
                    case 'contactDetailsBox':
                      if (shouldHide(boxConfig.name)) return null;
                      return (
                        <ContactDetailsBox
                          config={boxConfig}
                          editProps={editProps?.contactDetailsBoxEditProps}
                        />
                      );
                    case 'customBioTextBox':
                      if (shouldHide(boxConfig.name)) return null;
                      return (
                        <BioTextBox
                          config={boxConfig}
                          editProps={editProps?.customBioTextEditProps}
                        />
                      );
                    case 'urlsBox':
                      if (boxConfig.type === 'links' && shouldHide('urlsBox_links')) return null;
                      if (boxConfig.type === 'files' && shouldHide('urlsBox_files')) return null;
                      return (
                        <UrlsBox
                          config={boxConfig}
                          editProps={
                            boxConfig.type === 'links'
                              ? editProps?.linksBoxEditProps
                              : boxConfig.type === 'files'
                              ? editProps?.filesBoxEditProps
                              : undefined
                          }
                          trackHandlers={{ linkClickTrackHandler, fileClickTrackHandler }}
                        />
                      );
                    case 'contactFormBox':
                      if (shouldHide(boxConfig.name)) return null;
                      if (!profile.leadGen || suppressLeadGen) return null;
                      return (
                        <ContactFormBox
                          config={profile.leadGen}
                          font={profile.config.metaTheme.font}
                          onSubmit={onContactFormSubmit}
                          suppressPopupFlow={suppressLeadGenPopupFlow}
                          trackHandlers={{ contactSubmitTrackHandler }}
                          isEdit={!!editProps}
                        />
                      );
                    default:
                      return null;
                  }
                })}
                {renderFooter && (
                  <Footer
                    config={profile.footer}
                    supportedLangs={profile.meta.supportedLangs}
                    renderCookieSettings={renderCookieSettings}
                  />
                )}
              </Stack>
            </Card>
          </ResponsiveProfileWidthContainer>
        </FontWrapper>
      </ProfileThemeProvider>
    </ThemeProvider>
  );
}
