import { LtDialog } from '@/components';
import { ThemeLanguage } from '@/shared/types/api';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TitlesFormFields } from './TitlesFormFields';
import { TitlesForm } from './types';
import { getTitlesMapFromTitlesArr, getTranslatableInitialValues } from './utils';
import { Box, Typography } from '@mui/material';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { PROFILE_PAGE_LABELS } from '@/shared/constants/system-labels/profile-page';

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (values: Record<string, string>) => Promise<void>;
  languages: ThemeLanguage[];
  initialValues?: Record<string, string>;
  fieldKey: string;
};

export const EditSystemFieldDialog = ({
  open,
  onClose,
  onSave,
  initialValues,
  languages,
  fieldKey,
}: Props) => {
  const { t, activeLanguage } = useAppTranslation();

  const methods = useForm<TitlesForm>({
    mode: 'onSubmit',
    defaultValues: {
      titles: getTranslatableInitialValues(languages),
    },
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (initialValues || open) {
      reset({
        titles: languages.map(x => ({ lang: x.lang, value: initialValues?.[x.lang] || '' })),
      });
    }
  }, [initialValues, reset, open, languages]);

  const [saving, setSaving] = useState(false);

  const handleSave = async (values: TitlesForm) => {
    const payload = getTitlesMapFromTitlesArr(values.titles);

    setSaving(true);
    await onSave(payload);
    setSaving(false);
  };

  const defaultLabels = PROFILE_PAGE_LABELS.attributeLabels[fieldKey];
  const defaultLabelForActiveLanguage = defaultLabels?.[activeLanguage] || '';

  const handleReset = () => {
    reset({
      titles: languages.map(x => ({ lang: x.lang, value: defaultLabels?.[x.lang] || '' })),
    });
  };

  return (
    <LtDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      saveAction={{
        text: t('save'),
        onClick: handleSubmit(handleSave),
        loading: saving,
      }}
      title={t('contactFormEdit.internal.editSystemField')}
      size='md'
      withActionDivider
    >
      {defaultLabelForActiveLanguage && (
        <Box display='flex' gap={0.4}>
          <Typography sx={{ typography: 'h4' }}>
            {`${t('contactFormEdit.resetToDefaultLabel')}`}
          </Typography>
          <Typography
            onClick={handleReset}
            component='button'
            sx={{
              mb: 2,
              typography: 'h4',
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            {defaultLabelForActiveLanguage}
          </Typography>
        </Box>
      )}
      <FormProvider {...methods}>
        <TitlesFormFields languages={languages} />
      </FormProvider>
    </LtDialog>
  );
};
