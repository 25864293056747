import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { routePaths } from '../../../infrastructure/constants';
import { Redirect, useLocation } from 'react-router-dom';
import PageNotFound from '../page-not-found';
import config from '../../../config/config';
import useQuery from '../../../infrastructure/hooks/useQuery';
import { Tier, TIERS } from '../../../shared/constants';
import Loader from '@/components/Loader';

const Signup = () => {
  const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0();
  const { state } = useLocation<{ codeToConnect?: string; referralKey?: string }>();
  const query = useQuery();

  const [show404, setShow404] = useState(false);

  useEffect(() => {
    (async function signup() {
      // signup2theme
      if (!isLoading && !user && query.get('to')) {
        const themeName = query.get('to');
        const ltUnitName = query.get('unitName');
        const ltSignupToUnit = !!ltUnitName; // TODO: allow signup to theme
        const ltToken = query.get('token');
        await loginWithRedirect({
          screen_hint: query.get('sso') === 'true' ? 'login' : 'signup',
          redirectUri: window.location.origin + routePaths.HOME + '?lt-event=signup',
          appState: {
            signup: true,
            ltSignupToTheme: true,
            ltThemeName: themeName,
            ltSignupToUnit,
            ltUnitName,
            ltToken,
          },
          ltSignupToTheme: true, // will be forwarded to auth server
        });
      }
      // only allow normal signup via main domain
      else if (!isLoading && !user && window.location.origin === config.REACT_APP_URL) {
        const qtier = query.get('tier');
        const qtrial = query.get('trial') === 'true';
        const qplan = query.get('plan');
        const signupToTier: Tier = Object.values(TIERS).includes(qtier as Tier)
          ? (qtier as Tier)
          : 'starter';
        const referralKey = query.get('referralKey');
        const utmParams = Array.from(query.entries()).filter(([key]) => key.startsWith('utm_'));
        await loginWithRedirect({
          screen_hint: 'signup',
          redirectUri: window.location.origin + routePaths.HOME + '?lt-event=signup',
          appState: {
            signup: true,
            ltSignupCode: state?.codeToConnect || undefined,
            ltReferralKey: referralKey || undefined,
            signupToTier: {
              tier: signupToTier,
              trial: qtrial,
              plan: qplan,
            },
            utmParams,
          },
        });
      } else if (!isLoading && !user && window.location.origin !== config.REACT_APP_URL) {
        setShow404(true);
      }
    })();
  }, [isLoading, loginWithRedirect, query, state?.codeToConnect, state?.referralKey, user]);

  if (window.location.origin !== config.REACT_APP_URL && show404) {
    return <PageNotFound />;
  } else if (isAuthenticated) {
    return <Redirect exact to={routePaths.EDITPROFILE} />;
  } else {
    return <Loader />;
  }
};

export default Signup;
