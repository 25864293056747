import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActions,
  Button,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteSsoConnection, toggleSsoConnection } from '@/infrastructure/apis/md/idp';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SSOSetupPopup } from './SSOSetupPopup';
import { ThemeDomain, ThemeIdentityProviderDetailed } from '@/shared/types/api';
import LaunchIcon from '@mui/icons-material/Launch';
import { getTrySSOSetupLink } from './helpers/utils';
import { LtDialog, SettingsMenu } from '@/components';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { MenuItemType } from '@/components/SettingsMenu';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
const hidden = { opacity: 0, pointerEvents: 'none' };

type Props = {
  themeIdp: ThemeIdentityProviderDetailed;
  isSsoCreated: boolean;
  refetchIdpSettings: () => void;
  domains: ThemeDomain[];
  isScimActive: boolean;
  openScimSetupPopup: () => void;
};

export const SSOCard = ({
  themeIdp,
  isSsoCreated,
  refetchIdpSettings,
  domains,
  isScimActive,
  openScimSetupPopup,
}: Props) => {
  const [setupPopupOpened, setSetupPopupOpened] = useState(false);
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const history = useHistory();
  const location = useLocation();

  const { isFeatureAllowed } = useTierInfo();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteSsoConnection(getAccessTokenSilently, id);
      toast.success(t('idp.sso.deleteSuccess'));
      setDeletePopupOpened(false);
      refetchIdpSettings();
    } catch {
      toast.error(t('idp.sso.deleteError'));
    }
    setIsDeleting(false);
  };

  const handleSetupClose = () => {
    setSetupPopupOpened(false);
    refetchIdpSettings();
  };

  const handleToggle = async () => {
    try {
      await toggleSsoConnection(getAccessTokenSilently, id);
      toast.success(t('idp.sso.updateSuccess'));
      refetchIdpSettings();
    } catch {
      toast.error(t('idp.sso.updateError'));
    }
  };

  const handleTryClick = () => {
    window.open(getTrySSOSetupLink(themeIdp?.themeSSO?.connectionName), '_blank');
  };

  const isConnectionEnabled = themeIdp.themeSSO?.enabled || false;

  const settingsMenuItems: MenuItemType[] = [
    {
      icon: <EditIcon />,
      label: t('idp.edit'),
      onClick: () => history.push(location.pathname + '/sso'),
    },
    !isConnectionEnabled && {
      icon: <LaunchIcon />,
      label: t('idp.try'),
      onClick: handleTryClick,
    },
    {
      icon: <DeleteOutlineIcon />,
      label: t('idp.deleteConfiguration'),
      onClick: () => setDeletePopupOpened(true),
    },
  ].filter(Boolean);

  if (!isFeatureAllowed(FEATURE.SSO)) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader
          title={t('idp.sso.cardTitle')}
          titleTypographyProps={{ component: 'h4' }}
          sx={{ position: 'relative' }}
          action={
            isSsoCreated && (
              <Box sx={{ position: 'absolute', right: '1rem', top: '0.5rem' }}>
                <SettingsMenu
                  iconButtonProps={{
                    'aria-label': t('ariaOpenSsoSettings'),
                    'aria-haspopup': 'menu',
                  }}
                  menuItems={settingsMenuItems}
                />
              </Box>
            )
          }
        />
        <CardContent>
          <Typography variant='body1' color='text.secondary'>
            {t('idp.sso.cardDescription')}
          </Typography>
        </CardContent>
        <CardActions sx={{ px: 1.6, pb: 2 }}>
          {isSsoCreated && (
            <FormControlLabel
              control={<Switch checked={isConnectionEnabled} onChange={handleToggle} />}
              label={t('idp.sso.connectionEnabled')}
            />
          )}
          <Button
            endIcon={<EastIcon />}
            sx={{ ml: 'auto', ...(isSsoCreated ? hidden : {}) }}
            onClick={() => setSetupPopupOpened(true)}
          >
            {t('idp.sso.buttonText')}
          </Button>
        </CardActions>
      </Card>
      <SSOSetupPopup
        domains={domains}
        open={setupPopupOpened}
        onClose={handleSetupClose}
        themeIdp={themeIdp}
        isScimActive={isScimActive}
        openScimSetupPopup={openScimSetupPopup}
      />
      <LtDialog
        open={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        onCancel={() => setDeletePopupOpened(false)}
        title={t('requestDelete')}
        onDelete={handleDelete}
        loading={isDeleting}
      >
        <Typography>{t('idp.sso.deleteConfigurationConfirmation')}</Typography>
      </LtDialog>
    </>
  );
};
